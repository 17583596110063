import { Flavour } from '@/enums'
import Vue from 'vue'
import Vuex from 'vuex'

import { PIS_CONFIG, PIS_CONTENT, SHARE_MY_TRAIN_CONSTANTS } from '@/constants'
import { DEFAULT_THEME } from '@/themes/theme.default'
import { Status, IState } from '@/constants/interfaces'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

const state: IState = {
  journeyResponse: {
    status: Status.Init,
    data: {},
    errMessage: '',
  },
  trainPosition: {
    status: Status.Init,
    data: {},
    errMessage: '',
  },
  shareUrl: {
    status: Status.Init,
    data: {},
    errMessage: '',
  },
  weather: {
    status: Status.Init,
    data: {},
    errMessage: '',
  },
  journeyStations: null,
  showSplittedList: false,
  preferredFlavour: null,
  config: PIS_CONFIG,
  content: PIS_CONTENT,
  packageVersion: process.env.VUE_APP_VERSION || '0',
  language: 'en',
  router: {},
  poller: {},
  theme: DEFAULT_THEME,
}

export const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
})

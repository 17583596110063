














































import { IPISContent } from '@/constants/interfaces'
import { MODAL } from '@/enums'
import { copyToClipboard } from '@/helpers/copyToClipboard'
import { canRenderData, isInit, isRenderError } from '@/helpers/gettersLogic'
import { NotificationGroup } from '@/enums/notificationGroup'
import { LANG_QUERY_NAME, Language } from '@/enums/language'

import { store } from '@/store'
import { Component, Prop } from 'vue-property-decorator'
import Spinner from 'vue-simple-spinner'
import * as tsx from 'vue-tsx-support'

interface IShareProps {
  content: IPISContent
}

interface IShareEvents {
  onClose: () => void
}

@Component({ components: { Spinner } })
export default class Share extends tsx.Component<IShareProps, IShareEvents> {
  @Prop() public content: IPISContent

  readonly MODAL = MODAL

  public get modalClasses() {
    return {
      'share-modal': true,
      'share-modal__no-data': !this.canRenderData,
    }
  }

  public get isInit(): boolean {
    return isInit(store.getters.getShareUrlData)
  }

  public get canRenderData(): boolean {
    return canRenderData(store.getters.getShareUrlData)
  }

  public get isRenderError(): boolean {
    return isRenderError(store.getters.getShareUrlData)
  }

  public get url(): string {
    return store.getters.getShareUrl
  }

  public get urlLangParams(): string {
    const lang = store.getters.getLanguage
    const isDefaultLang = lang === Language.EN
    return isDefaultLang ? '' : `?${LANG_QUERY_NAME}=${lang}`
  }

  public get title(): string {
    return this.content.UPDATED_BAR.SHARE_POPUP.TITLE
  }

  public get description(): string {
    return this.content.UPDATED_BAR.SHARE_POPUP.DESCRIPTION
  }

  public get message(): string {
    return this.content.UPDATED_BAR.SHARE_POPUP.MESSAGE
  }

  public get copy(): string {
    return this.content.UPDATED_BAR.SHARE_POPUP.COPY
  }

  public get messageBody(): string {
    return `${this.message} ${this.url}${this.urlLangParams}`
  }

  public get serverUnavailableLabel(): string {
    return this.content.DATA_LOADER.SERVER_UNAVAILABLE
  }

  public closeModal() {
    this.$modal.hide(MODAL.SHARE)
  }

  public onCopy() {
    copyToClipboard(this.messageBody)
    this.closeModal()
    const notification = {
      group: NotificationGroup.COPY,
      type: 'success',
    }
    this.$emit('showNotification', notification)
  }

  public getShareUrl() {
    if (!this.canRenderData) {
      store.dispatch('fetchShareUrl')
    }
  }
}

import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class Airplane extends tsx.Component<{}> {
  private render(h) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
      >
        <path
          fill='#1E234F'
          fill-rule='evenodd'
          d='M13.606.394a1.345 1.345 0 0 0-1.903 0L8.85 3.248l-6.883-.891s-.505-.09-.725.13l-.802.801a.36.36 0 0 0-.103.377c.041.138.426.317.549.372.124.054 4.761 2.414 4.761 2.414L3.715 8.383l-.19.19s-2.188-.315-2.441-.35c-.255-.033-.395.142-.395.142l-.588.589c-.069.068-.239.262.118.428.357.165 3.115 1.284 3.115 1.284s1.12 2.758 1.284 3.114c.166.358.36.188.428.12l.589-.59s.176-.14.141-.394c-.034-.253-.35-2.44-.35-2.44l.19-.19L7.55 8.352s2.36 4.637 2.414 4.761c.056.123.234.508.372.549.136.04.267.007.377-.103l.801-.802c.22-.22.13-.725.13-.725l-.891-6.883 2.854-2.854a1.344 1.344 0 0 0 0-1.902'
        />
      </svg>
    )
  }
}

import { ITheme } from './theme'

const primary = '#002c66'
const secondary = '#eeb111'
const lightBlue = '#dce7f2'

export const CPA_THEME: ITheme = {
  footer: {
    backgroundColor: 'white',
  },
  feedbackForm: {
    title: {
      color: primary,
    },
    textArea: {
      color: primary,
      focusedBorderColor: secondary,
    },
    button: {
      backgroundColor: secondary,
      disabledBackgroundColor: primary,
      borderColor: secondary,
      disabledBorderColor: primary,
    },
  },
  shareModal: {
    color: primary,
    backgroundColor: 'white',
  },
  pisSplitSpanBorderColor: secondary,
  pisSplitSpanC: primary,
  pisJourneyListSplitterBg: secondary,
  pisJourneyListSplitterActiveBg: '#f9c94d',
  pisJourneyListBottomPadding: '34px',
  shareBodyBg: 'white',
  pisJourneyListBg: 'white',
  pisContainerBg: 'white',
  pisHeaderHeight: '5.8rem',
  pisBorderC: '#e0e0e0',
  pisDisabledPrimary: primary,
  pisEmptyListC: primary,
  pisJourneyListLineDisabledBg: lightBlue,
  pisJourneyListPointDisabledBg: lightBlue,
  pisJourneyListPointHeight: '0.6rem',
  pisJourneyListPointFirstLastBg: 'black',
  pisDisabledDelayC: '#f48120',
  pisDisabledDelayIconC: '#f48120',
  pisPrimary: primary,
  pisArrowStrokeWidth: '2',
  pisArrowC: secondary,
  pisTrain: {
    widthHeight: '30px', // $pis-train-w
    circleBg: secondary, // $pis-train-circle-bg
    circleC: secondary, // $pis-train-circle-c
    color: secondary, // $pis-train-c
  },
  copyIcon: {
    mainColor: secondary,
    stripColor: primary,
  },
  pisTrainW: '30px',
  pisTrainLeft: '5px',
  pisEmptyListFontWeight: 'bold',
  pisJourneyListStationC: primary,
  pisStationInfoIconW: '4rem',
  pisStationInfoIconH: '4rem',
  pisSharedMainIconC: secondary,
  pisSharedMessageFontSize: '1.4rem',
  pisUpdatedBarBg: lightBlue,
  pisCoachListNameFontWeight: 'bold',
  pisDeparturesHeaderC: primary,
}

export const humanizeTimeResponse = (
  timeInMinutes: string,
  hr: string,
  m: string,
): string => {
  if (!timeInMinutes) {
    return `0${m}`
  }

  let totalMin = Number.parseInt(timeInMinutes)
  const isNegative = totalMin < 0
  totalMin = isNegative ? totalMin * -1 : totalMin

  const hours = Math.floor(totalMin / 60)
  const min = totalMin % 60
  const visibleHours = hours ? `${hours}${hr}` : ''
  const visibleMinutes = min ? `${min}${m}` : ''

  const sign = isNegative ? '-' : ''
  const visibleTime = visibleHours
    ? `${visibleHours} ${visibleMinutes}`
    : visibleMinutes
    ? visibleMinutes
    : `0${m}`

  return `${sign}${visibleTime}`
}






















import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class Alert extends tsx.Component<{}> {}

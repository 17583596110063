






import { store } from '@/store'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BackToTheAppButton extends Vue {
  public get buttonText() {
    return store.getters.getContent.BACK_TO_APP?.BUTTON_TEXT
  }

  public backToTheApp(): void {
    this.$emit('backToApp')
  }
}




























































import * as tsx from 'vue-tsx-support'
import { Component, Prop } from 'vue-property-decorator'

import DepartureItem from '@/components/departureItem/index.vue'
import { IDeparturesOption, IPISContent } from '@/constants/interfaces'
import {
  GetAllDeparturesResponse,
  GetJourneyStationsResponse,
  Tiploc,
} from '@gomedia-apis-ts-pis/v1'
import Spinner from 'vue-simple-spinner'
import { getDepartures, EDepartureType } from '@/helpers/item'
import { store } from '@/store'
import { getNameI18n } from '@/helpers/item'
import { getStationInfo } from '@/helpers/item'

interface IDeparturesListProps {
  options: IDeparturesOption
}

interface IDeparturesListEvents {
  onChangeStationName?: string
}

@Component({ components: { Spinner, DepartureItem } })
export default class DeparturesList extends tsx.Component<
  IDeparturesListProps,
  IDeparturesListEvents
> {
  @Prop() public options: IDeparturesOption

  public departuresList = []
  public inProgress = false
  public isError = false

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get departuresLabelClasses() {
    return {
      'departures-label-container': true,
      'departures-label-container_am-pm': this.options.amPm,
    }
  }

  public get departureHeaderClasses() {
    return {
      'departures-header': true,
      'departures-header_vehicle-icons': this.showVehicleIcons,
    }
  }

  public get isEmpty(): boolean {
    return !this.departuresList || !this.departuresList.length
  }

  public get showVehicleIcons(): boolean {
    return this.options.showVehicleIcons
  }

  public get isAirport(): boolean {
    return this.options.departureType === EDepartureType.airport
  }

  public get isRail(): boolean {
    return this.options.departureType === EDepartureType.train
  }

  public get transportLabel(): string {
    return this.content.DEPARTURES.TRANSPORT
  }

  public get departLabel(): string {
    return this.options.amPm
      ? this.content.DEPARTURES.DEPARTURE
      : this.content.DEPARTURES.DEPART
  }

  public get destinationLabel(): string {
    return this.content.DEPARTURES.DESTINATION
  }

  public get flightLabel(): string {
    return this.content.DEPARTURES.FLIGHT
  }

  public get terminalLabel(): string {
    return this.content.DEPARTURES.TERMINAL
  }

  public get termLabel(): string {
    return this.content.DEPARTURES.TERM
  }

  public get platformLabel(): string {
    return this.options.showPlatforms ? this.content.DEPARTURES.PLATFORM : ''
  }

  public get platLabel(): string {
    return this.options.showPlatforms ? this.content.DEPARTURES.PLAT : ''
  }

  public get noDeparturesAvailableLabel(): string {
    return this.content.DEPARTURES.NO_DEPARTURES_AVAILABLE
  }

  public get language(): string {
    return store.getters.getLanguage
  }

  public pushTitleToPortal(stationInfo: Tiploc) {
    const stationTitle = getNameI18n(stationInfo.name, this.language)
    this.$emit('change-station-name', stationTitle)
  }

  private async requestDepartures(): Promise<void> {
    this.inProgress = true
    try {
      const journeyStations: GetJourneyStationsResponse =
        store.state.journeyStations ||
        (await store.dispatch('fetchJourneyStations', this.options.tiploc))

      if (journeyStations && journeyStations.items) {
        const stationInfo = getStationInfo(journeyStations, this.options.tiploc)
        this.pushTitleToPortal(stationInfo)
      }
      const stationDepartures: GetAllDeparturesResponse = await store.dispatch(
        'fetchStationDepartures',
        this.options.tiploc,
      )
      this.departuresList = getDepartures(
        stationDepartures.departures,
        this.options.tiploc,
        this.options.departureType,
      )
    } catch (error) {
      this.isError = true
    } finally {
      this.inProgress = false
    }
  }

  private created(): void {
    this.requestDepartures()
  }
}

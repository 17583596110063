












import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { store } from '@/store'

interface ILightSpeedometerProps {
  iconUrl: string
}

@Component
export default class LightSpeedometer extends tsx.Component<ILightSpeedometerProps> {
  @Prop({ required: true }) readonly iconUrl: string

  get speed() {
    return store.getters.getSpeed
  }
}

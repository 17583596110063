


























































































































































import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class Nre extends tsx.Component<{}> {}

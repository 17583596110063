import { Flavour } from '@/enums'
import { NotificationGroup } from '@/enums/notificationGroup'
import { EDepartureType } from '@/helpers/item'
import {
  GetJourneyResponse,
  TrainPosition,
  ShareMyTrainResponse,
  GetJourneyStationsResponse,
} from '@gomedia-apis-ts-pis/v1'
import Gallup from 'gallup'
import { ITheme } from '@/themes/theme'

export enum Status {
  Init = 'Init',
  Loading = 'Loading',
  Received = 'Received',
  Failed = 'Failed',
  FailedInit = 'FailedInit',
}

export interface IData<T> {
  status: Status
  data?: T
  errMessage?: string
}

export interface IDestinationInfoItem {
  name: string
  time: string
  tiploc: string
  arriveIn?: string
}

export interface IMapConfig {
  URL: string
  ZOOM: number
  MIN_ZOOM: number
  MAX_ZOOM: number
  ZOOM_CYCLE?: number[]
  ZOOM_CYCLE_INTERVAL?: number
  DEFAULT_COORDS: {
    LATITUDE: number
    LONGITUDE: number
  }
  DEFAULT_MAX_BOUNDS: [[number, number], [number, number]]
  MARKER: {
    ICON?: string
    SIZE?: number
  }
  ROUTE?: {
    ENABLED?: boolean
    COLOR?: string
    OPACITY?: number
    WIDTH?: number
  }
  MAP_STATION_STOPS?: {
    ENABLED?: boolean
    STATIONS_VISIBILITY_ZOOM_LEVEL?: number
    TOOLTIPS_VISIBILITY_ZOOM_LEVEL?: number
  }
  POINTS_OF_INTEREST?: {
    ENABLED?: boolean
    MARKER_ICON?: string
    MARKER_WIDTH?: number
    MARKER_HEIGHT?: number
  }
  LIGHT_SPEEDOMETER?: {
    ENABLED?: boolean
    ICON?: string
  }
  NEXT_STATION_INFO_BAR?: boolean
  SAVE_PREVIOUS_MAP_STATE?: boolean
  ZOOM_CONTROL_DISABLED: boolean
  CURRENT_POSITION_CONTROL_ENABLED: boolean
}

export interface IConfig {
  MAX_DELAY: number
  FALLBACK_TIMEZONE: string
  CACHED_JOURNEY_KEY: string
  DEFAULT_HIDE_ALERTS: boolean
  DEFAULT_SHOW_FEEDBACK_HREF: boolean
  DEFAULT_MAP_CONFIG: IMapConfig
  DEFAULT_SPEED: number
  ANALYTICS_EVENTS: {
    PIS_FEEDBACK: string
    PIS_LEAVE_FEEDBACK_CLICK: string
  }
}

export enum EPisType {
  departures,
  journey,
  map,
  stationInformation,
  vehicleInformation,
  disruptionMessage,
}

export interface IGoViewNotification {
  group: NotificationGroup
  type: 'success' | 'error'
}

export interface ITiplocMetadata {
  [key: string]: string
}

export interface IDeparturesOption {
  tiploc?: string // tiploc for rail departure
  departureType?: EDepartureType // switcher to load departure list
  amPm?: boolean
  showPlatforms?: boolean
  showVehicleIcons?: boolean
}

export interface IDestinationOption {
  content?: IPISContent
  language?: string
  flavour?: Flavour
  showWeather?: boolean
  amPm?: boolean
  showButtons?: boolean
}

export interface IJourneyOption {
  showUpdatedBar?: boolean // show Updated Bar
  showArrow?: boolean // shows arrow on journey item
  showShare?: boolean
  showPlatforms?: boolean
  hideAlerts?: boolean
  requestTimeout?: number
  skipStationInformation?: boolean
  setOrigin?: Function
  setSplit?: Function
  showSplittedList?: boolean
  token?: string
  actionName?: string
  flavour?: Flavour
  amPm?: boolean
  showFeedbackHref?: boolean
  showStationLabels?: boolean
}

export interface IPointOfInterest {
  id: string
  journey: string
  location: [number, number]
  channels?: any
  genre?: string
  min_zoom: number
  tag: string
  address: string
  contentType: string
  thumbImg: string
  articleImg: string
  publishStart: string
  publishEnd: string
  description: string
  lang: string
  title: string
}

export interface IMapOption {
  actionName?: string
  latitude?: number
  longitude?: number
  requestTimeout?: number
  flavour?: Flavour
  mapConfig?: IMapConfig
}

export interface ISbbTrafimageMapOptions {
  flavour?: Flavour
  mapConfig?: IMapConfig
  language?: string
}

export interface ISpeedometerOption {
  speed?: number
}

export interface IStationInformationOption {
  tiploc?: string
  alert?: string
  flavour?: Flavour
}

export interface ITrainInfoOption {
  tiploc?: string // tiploc for rail departure
  actionName?: string
  flavour?: Flavour
  alert?: string
}

export interface ILoadOption
  extends IDeparturesOption,
    IDestinationOption,
    IJourneyOption,
    IMapOption,
    IStationInformationOption,
    ITrainInfoOption {}

export interface IFacility {
  label: string
  icon: string
  url: string
  isVisible: boolean
  href?: string
}

export enum JourneySplitterEnum {
  Origin,
  Split,
}

export interface INetworkSignal {
  ICON: string
  BACKGROUND_COLOR: string
  TEXT_COLOR: string
  TEXT: string
}

export interface IStationFacility {
  CONDITION_TYPE?: string
  LABEL: string
  ICON: string
  URL: string
  HREF?: string
}

export interface IPISContent {
  DEPARTURES: {
    TRANSPORT: string
    DEPARTURE: string
    DEPART: string
    DESTINATION: string
    PLATFORM: string
    PLAT: string
    TERMINAL: string
    TERM: string
    FLIGHT: string
    DELAYED: string
    GATE: string
    CANCELLED: string
    NO_DEPARTURES_AVAILABLE: string
    TRAIN_ICON?: string
    VEHICLE_ICON?: {
      [key: string]: string
    }
  }
  STATION_LABELS: {
    [key: string]: string[]
  }
  STATION_FACILITIES: {
    NO_INFO: string
    ITEMS: IStationFacility[]
  }
  DATA_LOADER: {
    SERVER_UNAVAILABLE: string
  }
  JOURNEY_LIST: {
    NO_JOURNEY_AVAILABLE: string
    PLATFORM_NUM: string
    CANCELLED: string
    NO_TIME: string
    SPLITTING: string
    TOWARDS: string
  }
  FEEDBACK: {
    LABEL: string
    TITLE: string
    SUBMIT: string
    LOADING: string
    BUTTON_REDIRECT?: {
      LINK: string
      TARGET: string
    }
    ISSUE: {
      LABEL: string
      PLACEHOLDER: string
      ERROR_LABEL: string
    }
    NOTIFICATIONS: {
      SUCCESS?: {
        TITLE: string
        MESSAGE?: string
      }
      ERROR: {
        TITLE: string
      }
      LINK_COPIED_SUCCESS?: string
    }
  }
  BACK_TO_APP?: {
    BUTTON_TEXT?: string
  }
  DESTINATION?: {
    TRAIN_NAME?: string
    NEXT_STOP: string
    ARRIVAL: string
    DESTINATION_TITLE: string
    BUTTONS: {
      INFO: {
        ICON: string
        TEXT: string
        WIDTH: number
      }
      SHARE: {
        ICON: string
        TEXT: string
        WIDTH: number
      }
    }
    ECO_CALCULATOR?: {
      TITLE: string
      REDIRECT_LINK: string
      EXPLANATION_TEXT: string
      ICONS: {
        GREEN_LEAF: string
        CO2: string
        FUEL: string
        TIME: string
      }
    }
  }
  TRAIN_INFO?: {
    COACH: string
    INFO_UNAVAILABLE: string
  }
  UPDATED_BAR?: {
    SHARE: string
    SHARE_BUTTON_TITLE?: string
    SHARE_POPUP?: {
      TITLE: string
      DESCRIPTION: string
      MESSAGE: string
      WHATSAPP: string
      EMAIL: string
      COPY: string
      CLOSE_ICON: string
    }
  }
  NETWORK_SIGNAL?: {
    '-1': INetworkSignal
    '0': INetworkSignal
    '1': INetworkSignal
    '2': INetworkSignal
    '3': INetworkSignal
    '4': INetworkSignal
    '5': INetworkSignal
  }
  MAP?: {
    ATTRIBUTIONS: string[]
    IFRAME_TITLE?: string
  }
}

export interface ISMTContent {
  JOURNEY_LIST: Partial<IPISContent['JOURNEY_LIST']>
}

export interface IState {
  journeyResponse: IData<GetJourneyResponse>
  trainPosition: IData<TrainPosition>
  shareUrl: IData<ShareMyTrainResponse>
  weather: IData<any>
  journeyStations: GetJourneyStationsResponse
  showSplittedList: boolean
  preferredFlavour: Flavour | null
  packageVersion: string
  config: IConfig
  content: IPISContent
  language: string
  router: any
  poller: Gallup
  theme: ITheme
}

export interface IShareMyTrainPisConfig {
  PLATFORMS_DISABLED?: boolean
  AM_PM_TIME?: boolean
  LANGUAGE_SWITCHER?: boolean
  SHOW_FOOTER?: boolean
}

export interface IShareMyTrainPisConstants {
  CONFIG: IShareMyTrainPisConfig
}

export type IShareMyTrainPisConstantsMapToFlavour = {
  [key in Flavour]?: IShareMyTrainPisConstants
}

import { ITheme } from './theme'

const darkNavy = '#0b2144'
const white = '#ffffff'
const black = '#000000'
const downriver = '#0f2b5f'
const orange = '#ff981a'
const saffron = '#f5d83c'
const darkBlue = 'rgba(4, 19, 43, 0.45)'

export const ECTL_THEME: ITheme = {
  pisJourneyListBg: downriver,
  pisContainerBg: downriver,
  pisHeaderHeight: '5.8rem',
  pisBorderC: black,
  pisEmptyListC: white,
  pisSplitSpanBorderColor: saffron,
  pisJourneyListSplitterBg: saffron,
  pisJourneyListSplitterActiveBg: downriver,
  facilityItem: {
    backgroundColor: downriver,
  },
  footer: {
    backgroundColor: downriver,
    padding: '8px 10px',
    logosContainer: {
      padding: '35px 17px',
    },
  },
  alertIcon: {
    backgroundColor: '#FF8C00',
    exclamationMarkColor: downriver,
  },
  disruptionMessage: {
    color: white,
    fontWeight: 600,
    arrow: {
      color: darkNavy,
    },
  },
  feedbackButton: {
    height: '42px',
    backgroundColor: darkBlue,
    color: white,
    fontSize: '16px',
    fontWeight: 700,
    borderRadius: '0px',
    borderColor: white,
    borderWidth: '1px',
  },
  feedbackForm: {
    padding: '25px 15px',
    fontSize: '16px',
    alignItems: 'start',
    title: {
      color: white,
      marginBottom: 0,
      fontSize: '22px',
      fontWeight: 'bold',
    },
    textArea: {
      color: '#1e234f',
      borderColor: '#e0e0e0',
      focusedBorderColor: '#00a6e6',
      padding: '15px 10px',
      fontSize: '16px',
      borderRadius: 0,
    },
    button: {
      backgroundColor: darkBlue,
      borderColor: white,
      borderRadius: '0px',
      disabledBackgroundColor: 'rgba(4, 19, 43, 0.25)',
      disabledBorderColor: white,
      disabledColor: '#8a8b9b',
      color: white,
    },
    before: {
      content: '',
      marginRight: 0,
    },
    form: {
      maxWidth: '100%',
    },
  },
  departureItem: {
    details: {
      fontWeight: 'normal',
    },
  },
  pisJourneyListLineBg: white,
  pisJourneyListLineWidth: '4px',
  pisJourneyListPointBg: white,
  pisJourneyListPointDisabledBg: white,
  pisPrimary: white,
  pisArrowC: white,
  pisListsBg: downriver,
  pisJourneyListStationC: white,
  pisDelayC: orange,
  pisDisabledDelayC: orange,
  pisDisabledDelayIconC: orange,
  pisDelayFontWeight: 400,
  pisUpdatedBarBg: downriver,
  pisUpdatedBarBorderBottomC: black,
  pisUpdatedBarBorderTopC: black,
  pisUpdatedBarSharedC: white,
  pisShareC: white,
  pisAirplaneC: white,
  pisTrain: {
    widthHeight: '36px',
    circleC: downriver,
    circleBg: white,
    color: white,
  },
  pisStationInfoAlertTextAlign: 'left',
  pisStationInfoLabelTextAlign: 'left',
  pisSharedMainIconC: orange,
  pisDeparturesHeaderC: white,
  pisDeparturesHeaderBg: darkNavy,
  pisDeparturesHeaderBorderC: black,
  pisStationInfoIconW: '24px',
  pisStationInfoIconH: '3.9rem',
  pisJourneyListLineDisabledBg: white,
  pisDisabledPrimary: white,
  pisJourneyListPointFirstLastSize: '18px',
  pisJourneyListPointFirstLastBorderWidth: '4px',
  pisJourneyListPointFirstLastBg: downriver,
  pisJourneyListBottomPadding: '145px',
  pisTrainPulseAnimationLightPercent: '100%',
  pisTrainW: '36px',
  pisTrainLeft: '2px',
  pisTrainBottom: '15px',
  pisPlatformC: white,
  pisDisabledPlatformColor: white,
  pisPlatformNameFontSize: '14px',
  pisJourneyListItemTimeFontWeight: 400,
  pisJourneyListItemTimeFontSize: '16px',
  itemContent: {
    firstLine: {
      fontSize: '18px',
      fontWeight: 700,
    },
  },
  pisJourneyListInfoFontWeight: 300,
  pisJourneyListInfoMarginTop: '2px',
  pisJourneyListPointWidth: '11px',
  pisJourneyListPointHeight: '4px',
}















import {
  IMapConfig,
  IMapOption,
  IPISContent,
  IPointOfInterest,
} from '@/constants/interfaces'
import { store } from '@/store'
import {
  GetCurrentRouteResponse,
  GetJourneyStationsResponse,
  RoutePoint,
  Tiploc,
} from '@gomedia-apis-ts-pis/v1'
import 'leaflet/dist/leaflet.css'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MapComponent, { IMarkerPosition } from '@/components/map/index.vue'

const sortByLocation = (a: IPointOfInterest, b: IPointOfInterest): number =>
  a.location[0] + a.location[1] - (b.location[0] + b.location[1])
@Component({
  components: {
    MapComponent,
  },
})
export default class MapDataLoader extends Vue {
  @Prop({ required: true }) readonly options: IMapOption

  public pointsOfInterest: IPointOfInterest[] = []
  public stations: Tiploc[] = []
  public routePoints: RoutePoint[] = []

  get journeyName(): string {
    return store.getters.journeyName
  }

  get content(): IPISContent {
    return store.getters.getContent
  }

  get markerPosition(): IMarkerPosition {
    return { lat: this.options.latitude, lng: this.options.longitude }
  }

  get config(): IMapConfig {
    return this.options.mapConfig
  }

  get isMapStationStopsEnabled(): boolean {
    return Boolean(
      this.config.MAP_STATION_STOPS && this.config.MAP_STATION_STOPS.ENABLED,
    )
  }

  get isRouteEnabled(): boolean {
    return Boolean(this.config.ROUTE && this.config.ROUTE.ENABLED)
  }

  get isPointOfInterestEnabled(): boolean {
    return Boolean(
      this.config.POINTS_OF_INTEREST && this.config.POINTS_OF_INTEREST.ENABLED,
    )
  }

  public journeyChangeWatcher(): void {
    if (this.isRouteEnabled) {
      this.fetchJourneyRoute()
    }
    if (this.isMapStationStopsEnabled) {
      this.fetchMapStations()
    }
  }

  public async fetchMapStations(): Promise<void> {
    try {
      const stations: GetJourneyStationsResponse = await store.dispatch(
        'fetchJourneyStations',
      )
      if (stations && stations.items) {
        this.stations = stations.items
      }
    } catch (e) {
      console.error(e)
    }
  }

  public async fetchPointsOfInterest(): Promise<void> {
    try {
      const pointsOfInterest: IPointOfInterest[] = await store.dispatch(
        'requestPointsOfInterest',
      )
      this.pointsOfInterest = pointsOfInterest.sort(sortByLocation)
    } catch (e) {
      console.error(e)
    }
  }

  public async fetchJourneyRoute(): Promise<void> {
    try {
      const route: GetCurrentRouteResponse = await store.dispatch('fetchRoute')
      if (route && route.points) {
        this.routePoints = route.points
      }
    } catch (e) {
      console.error(e)
    }
  }

  public async init(): Promise<void> {
    const actionsOnInit: {
      condition: boolean
      action: () => void | Promise<any>
    }[] = [
      {
        condition: this.isMapStationStopsEnabled,
        action: this.fetchMapStations,
      },
      {
        condition: this.isPointOfInterestEnabled,
        action: this.fetchPointsOfInterest,
      },
      {
        condition: this.isRouteEnabled,
        action: this.fetchJourneyRoute,
      },
    ]
    const actionList = actionsOnInit.reduce(
      (acc, { action, condition }) => (condition ? [...acc, action] : acc),
      [],
    )

    await Promise.all(actionList.map((action) => action()))

    this.$watch(() => this.journeyName, this.journeyChangeWatcher)
  }

  created(): void {
    this.init()
  }
}

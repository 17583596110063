import Vue from 'vue'
import 'vue-tsx-support/enable-check'
import VModal from 'vue-js-modal'

import App from './App.vue'
import { store } from '@/store'

Vue.use(VModal)
Vue.config.productionTip = false
Object.defineProperties(Vue.prototype, {
  $theme: {
    get: () => store.getters.getTheme,
  },
})

export default new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app')

import { IFullTheme } from './theme'
import { calc } from '@/themes/utils/helpers'

const blue = '#1e234f'
const color1 = '#d2d3db'

const trainSize = '4rem'
const pisUpdatedBarHeight = '60px'

export const DEFAULT_THEME: IFullTheme = {
  pisUpdatedBarHeight,
  pisShareBarHeight: '5rem',
  feedbackButton: {
    color: 'black',
    height: 'auto',
    backgroundColor: 'transparent',
    padding: '10px',
    fontSize: '1.6rem',
    fontWeight: 'normal',
    borderRadius: 0,
    borderColor: 'transparent',
    borderWidth: '0px',
  },
  feedbackForm: {
    padding: '1.6rem',
    fontSize: '1.4rem',
    maxWidth: '375px',
    alignItems: 'center',
    title: {
      color: '#606266',
      fontWeight: 600,
      marginBottom: '2rem',
      fontSize: '15px',
    },
    textArea: {
      color: '#1e234f',
      borderColor: '#e0e0e0',
      focusedBorderColor: '#00a6e6',
      borderRadius: '0.8rem',
      padding: '.5rem 1.5rem',
      fontSize: '14px',
    },
    button: {
      backgroundColor: '#00a6e6',
      disabledBackgroundColor: color1,
      disabledColor: '#fff',
      borderColor: '#00a6e6',
      disabledBorderColor: color1,
      borderRadius: '0.8rem',
      color: 'white',
    },
    before: {
      content: '"*"',
      marginRight: '4px',
    },
    form: {
      maxWidth: '29rem',
    },
  },
  footer: {
    height: 'auto',
    padding: '8px',
    bottom: '60px',
    backgroundColor: '#f5f6f6',
    logosContainer: {
      padding: 0,
    },
  },
  alertIcon: {
    backgroundColor: '#FF8C00',
    exclamationMarkColor: 'white',
  },
  disruptionMessage: {
    color: 'inherit',
    arrow: {
      color: '',
    },
    fontWeight: 'inherit',
  },
  pisTrain: {
    widthHeight: '4rem', // $pis-train-w
    circleBg: 'white', // $pis-train-circle-bg
    circleC: '#00a6e6', // $pis-train-circle-c
    color: '#00a6e6', // $pis-train-c
  },
  copyIcon: {
    mainColor: '#00a6e6',
    stripColor: 'transparent',
  },
  facilityItem: {
    backgroundColor: 'transparent',
  },
  shareModal: {
    borderRadius: '0',
    color: 'inherit',
    backgroundColor: 'white',
    title: {
      fontWeight: 'bold',
    },
    divider: {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      margin: '32px auto',
    },
    container: {
      border: 'none',
    },
    message: {
      fontSize: '1.4rem',
      fontWeight: 'normal',
      padding: '0',
    },
    button: {
      background: 'transparent',
      padding: '0',
      fontSize: '1.4rem',
      color: 'inherit',
      cursor: 'auto',
    },
  },
  shareBodyBg: '#0A151E',
  pisLibBodyBg: 'transparent',
  pisJourneyListBg: 'white',
  pisContainerBg: 'transparent',
  pisSharedHeaderBg: '#1d3242',
  pisHeaderHeight: '60px',
  pisStationAlignFlex: 'stretch',
  pisBorderC: '#e0e0e0',
  pisItemHeight: '6.4rem',
  pisRouteWidth: '4rem',
  pisEmptyListC: '#1e234f',
  pisPrimary: 'white',
  pisMarginBottom: 'inherit',
  pisDisabledPrimary: color1, // $pis-disabled-primary
  pisDelayC: '#f48120', // $pis-delay-c
  pisDelayFontWeight: 400,
  pisDelayBg: 'transparent',
  pisDelayPadding: 'initial',
  pisJourneyListItemTimeFontWeight: 'bold', // $pis-journey-list-time-font-weight
  pisJourneyListItemTimeFontSize: '1.45rem',
  pisDisabledDelayC: '#ffd4b1', // $pis-disabled-delay-c
  pisDisabledDelayIconC: '#ffd4b1',
  stationPadding: '0.8rem', // $station-padding
  pisTrainLeft: 0, // $pis-train-left
  pisTrainBottom: '1.3rem', // $pis-train-bottom
  pisTrainPulseAnimationLightPercent: '0%',
  pisTrainW: trainSize, // $pis-train-w
  pisJourneyListMaxWidth: '450px', // pis-journey-list-max-width
  pisJourneyListBottomPadding: '65px',
  pisJourneyListPointBg: 'white', // $pis-journey-list-point-bg
  pisJourneyListStationFontWeight: 'bold', // $pis-journey-list-station-font-weight
  pisJourneyListInfoFontWeight: 'normal', // $pis-journey-list-info-font-weight
  pisJourneyListInfoMarginTop: '5px',
  pisJourneyListLineWidth: '0.6rem', // $pis-journey-list-line-width
  pisJourneyListPointFirstLastBorderWidth: '0.4rem',
  pisJourneyListLineBg: blue, // $pis-journey-list-line-bg
  pisJourneyListLineDisabledBg: color1, // $pis-journey-list-line-disabled-bg
  pisJourneyListPointHeight: '1.8rem',
  pisJourneyListPointWidth: '15px',
  pisJourneyListPointDisabledBg: 'white', // $pis-journey-list-point-disabled-bg
  pisCancelledC: '#ff3b3b', // $pis-cancelled-c
  pisDisabledCancelledC: '#ffb1b1', // $pis-disabled-cancelled-c
  pisJourneyListPointFirstLastSize: '2.4rem', // $pis-journey-list-point-first-last-width
  pisJourneyListPointFirstLastBg: 'white',
  pisJourneyListPointCurrentUpcomingTop: calc(`${trainSize} / 2 * -1`), // $pis-journey-list-point-current-upcomming-top
  pisJourneyListPointLastCurrentUpcomingTop: calc(
    `(${trainSize} + 0.4rem) / 2 * -1`,
  ), // $pis-journey-list-point-last-current-upcomming-top
  pisArrowC: '#00a6e6', // $pis-arrows-c
  pisArrowStrokeWidth: '3', // $pis-arrows-stroke-width
  pisAirplaneC: '#1e234f',
  pisListsBg: 'white',
  pisEmptyListFontWeight: 500,
  pisUpdatedBarBg: '#f8f8f8',
  pisUpdatedBarBorderBottomC: 'black',
  pisUpdatedBarBorderTopC: 'transparent',
  pisUpdatedBarSharedC: '#1e234f',
  pisUpdatedBarSharedFontWeight: 'bold',
  pisDeparturesHeaderHeight: '3rem',
  pisDeparturesHeaderC: '#1e234f',
  pisDeparturesHeaderBg: 'white',
  pisDeparturesHeaderBorderC: '#e0e0e0',
  departuresList: {
    header: {
      platformShortVisibility: 'flex',
      platformFullVisibility: 'none',
    },
    maxWidth: '450px',
  },
  departureItem: {
    minHeight: '55px',
    padding: '0 1rem',
    grid: {
      templateColumns: '5rem 1fr',
      vehicleIconsTemplateColumns: '85px 5rem 1fr',
      columnGap: '1rem',
    },
    time: {
      fontWeight: 'normal',
      fontSize: '1.4rem',
    },
    name: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
    },
    details: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      width: '5rem',
    },
    info: {
      fontSize: '1.2rem',
    },
  },
  pisDestinationInfoC: 'white',
  pisShareC: '#1e234f',
  pisCloseC: '#909399',
  pisJourneyListStationC: '#1e234f',
  pisJourneyListTimeFontWeight: 'bold',
  pisJourneyListItemTimeDelay: '#f48120',
  pisJourneyListLineHeigth: '2.5rem',
  pisJourneyListPointCurrentUpcommingTop: calc(`${trainSize} / 2 * -1`),
  pisJourneyListPointLastCurrentUpcommingTop: calc(
    `(${trainSize} + 0.4rem) / 2 * -1`,
  ),
  pisJourneyListPointFirstLastWidth: '2.4rem',
  pisSplitBg: 'transparent',
  pisSplitSpanBorderColor: '#954094',
  pisSplitSpanC: 'white',
  pisSplitPassedBg: '#f8f3f9',
  pisJourneyListSplitterBorderC: '#e0e0e0',
  pisJourneyListSplitterBg: 'transparent',
  pisJourneyListSplitterActiveBg: 'transparent',
  pisJourneyListSplitterFontSize: '11px',
  pisJourneyListSplitterFontWeight: 'normal',
  pisJourneyListMargin: 'unset',
  pisStationInfoLabelFontWeight: 'bold',
  pisStationInfoLabelTextAlign: 'center',
  pisStationInfoLabelFontSize: '1.6rem',
  pisStationItemContentBorderWidth: '0.1rem',
  pisStationItemContentMarginLeft: '1.6rem',
  pisFacilitiesListWidth: '100%',
  pisFacilitiesListMargin: '0 auto',
  pisFacilitiesListPadding: '0 1rem',
  pisFacilityItemContentPadding: '0 0.8rem',
  pisStationFacilityItemBorderWidth: '0',
  pisStationInfoIconW: '3.1rem',
  pisStationInfoIconH: '3.9rem',
  pisStationInfoAlertTextAlign: 'center',
  destination: {
    item: {
      firstBorderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      secondBorderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    },
    title: {
      fontWeight: 400,
      fontSize: {
        desktop: '14px',
        tablet: '14px',
        mobileL: '12px',
        mobileS: '12px',
      },
    },
    value: {
      fontWeight: 400,
      fontSize: {
        desktop: '22px',
        tablet: '22px',
        mobileL: '22px',
        mobileS: '18px',
      },
    },
    button: {
      color: 'inherit',
      border: 'none',
      fontWeight: 400,
      fontSize: {
        desktop: '14px',
        tablet: '14px',
        mobileL: '14px',
        mobileS: '14px',
      },
      background: 'rgba(0, 0, 0, 0.2)',
      backgroundHovered: 'rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
    },
  },
  pisSharedMainIconC: '#00a6e6',
  pisSharedMessageFontSize: '16px',
  pisCoachListImgWidth: '2.4rem',
  pisCoachListImgMarginR: '0.9rem',
  pisCoachListImgMarginL: '0.6rem',
  pisCoachListNameFontSize: '20px',
  pisCoachListNameFontWeight: 'normal',
  trainAnimationSpeed: '.6s',
  showAnimationSpeed: '.5s',
  hideAnimationSpeed: '.5s',
  pulseAnimationSpeed: '1.78s',
  passedAnimationSpeed: '.5s',
  pisStationNameFontSize: '1.6rem',
  pisPlatformNameFontSize: '12px',
  pisPlatformC: blue,
  pisJourneyListArrowMargin: '0 0 0 .7rem',
  pisJourneyListArrowHeight: 'initial',
  pisDisabledPlatformColor: blue,
  itemContent: {
    firstLine: {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
    label: {
      fontSize: '12px',
      fontWeight: 500,
      background: '#ffcc02',
    },
  },
}

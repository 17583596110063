import { LANG_QUERY_NAME } from '@/enums/language'

export const push = (route: string) => {
  const url = `${window.location.protocol}//${
    window.location.host
  }/#${route.replace('!', '')}`

  window.open(url, '_self')
}

export const getParamFromLocation = (key: string) => {
  const queryString = window.location.href.split('#')[1]
  if (!queryString) {
    return ''
  }
  const queryParams = queryString.split('?')[1] || ''
  const hashUrl = new Map(
    queryParams.split('&').map((el) => el.split('=')) as any,
  )
  const param = (hashUrl.get(key) as string) || ''
  return decodeURI(param)
}

export const getShareMyTrainToken = () => {
  /*
  In production SMT URL is generated with additional /g/ or /b/ query
  in the path and this value indicates green or blue live environment.

  URL example on dev/stg:
   - https://sharemytrain.stg.gomedia.io/akCjeH

  URL example on production green/blue:
   - https://sharemytrain.gomedia.io/g/akCjeH
   */
  const parts = window.location.pathname.split('/')
  try {
    return parts[parts.length - 1]
  } catch (error) {
    return ''
  }
}

export const getLanguageQuery = () => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(LANG_QUERY_NAME)
}

export const removeAllQueries = () => {
  const loc = window.location.href.split('?')[0]
  window.history.replaceState({}, '', loc)
}

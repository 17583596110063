import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { ICoachProps } from '../coach'

@Component
export default class CoachTail extends tsx.Component<ICoachProps> {
  @Prop() isSbb: boolean
  @Prop() isSpa: boolean
  @Prop() isCpa: boolean

  get isDefault(): boolean {
    return !this.isSbb && !this.isSpa && !this.isCpa
  }

  render(h) {
    return (
      <div>
        {this.isSbb || this.isDefault ? (
          <svg
            id='pis-coach-tail'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='99'
            viewBox='0 0 24 99'
          >
            <defs>
              <path
                id='pis-coach-tail-a'
                d='M12.09 0c1.208 0 2.315.437 3.17 1.161 1.506.334 2.67 1.372 3.74 4.516 2.71 7.968 3.955 21.583 4.524 33.9.297.348.476.8.476 1.294V96.99a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V40.87c0-.458.154-.88.413-1.217C.95 27.273 2.18 13.59 5 5.677 6.072 2.668 7.294 1.6 8.833 1.224A4.926 4.926 0 0 1 12.089 0z'
              />
            </defs>
            <g
              fill='none'
              fill-rule='evenodd'
              transform='matrix(1 0 0 -1 0 99)'
            >
              <mask id='pis-coach-tail-b' fill='#fff'>
                <use xlinkHref='#pis-coach-tail-a' />
              </mask>
              <use fill='#F2F2F2' xlinkHref='#pis-coach-tail-a' />
              <path
                fill='#EB0000'
                d='M4 24.24h16v74.74H4z'
                mask='url(#pis-coach-tail-b)'
              />
              <path
                fill='#EB0000'
                d='M12 30.27c5 0 8 1.192 8-11.331C20 6.415 16.418-9.09 12-9.09S4 5.223 4 18.939c0 12.523 3 11.33 8 11.33z'
                mask='url(#pis-coach-tail-b)'
              />
              <path
                fill='#1E1E1E'
                d='M9 24.24a2 2 0 0 1-2-2v-2.06c0-.13.012-.256.036-.378a5.05 5.05 0 0 1-.028-.527v-.052l.066-6.206a4.99 4.99 0 0 1 4.989-4.937A4.937 4.937 0 0 1 17 13.017v6.196c0 .202-.012.4-.035.595.023.12.035.245.035.372v2.06a2 2 0 0 1-2 2H9z'
                mask='url(#pis-coach-tail-b)'
              />
              <path
                fill='#696969'
                d='M12 11.11c1.657 0 3 2.035 3 4.545 0 .232-.011.46-.034.683.023.101.034.205.034.312v2.56a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2.56c0-.107.011-.211.032-.311A7.34 7.34 0 0 1 9 15.655c0-2.51 1.343-4.545 3-4.545z'
                mask='url(#pis-coach-tail-b)'
              />
              <rect
                width='8'
                height='62.62'
                x='8'
                y='32.32'
                fill='#1E1E1E'
                mask='url(#pis-coach-tail-b)'
                rx='2'
              />
              <path
                stroke='#000'
                stroke-opacity='.5'
                d='M12.09.5c-1.092 0-2.121.394-2.925 1.097l-.093.08-.119.03c-1.515.366-2.54 1.473-3.483 4.092C2.946 12.81 1.5 24.872.868 40.337l-.006.148-.086.121a1.49 1.49 0 0 0-.276.868V96.98a1.5 1.5 0 0 0 1.5 1.5h20a1.5 1.5 0 0 0 1.5-1.5V41.474c0-.341-.114-.664-.32-.925l-.1-.127-.008-.161c-.671-15.318-2.113-27.392-4.545-34.47-.94-2.736-1.91-3.822-3.374-4.142l-.122-.027-.095-.08A4.39 4.39 0 0 0 12.09.5z'
                mask='url(#pis-coach-tail-b)'
              />
            </g>
          </svg>
        ) : null}
        {this.isSpa ? (
          <svg
            id='pis-coach-tail'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='99'
            viewBox='0 0 24 99'
          >
            <g fill='none' fill-rule='evenodd'>
              <path
                fill='#1FB1D2'
                d='M12 0c6.091 0 11.123 10.322 11.897 23.694.067.2.103.413.103.635V96a2 2 0 01-2 2H2a2 2 0 01-2-2V24.33c0-.223.036-.436.103-.636C.877 10.322 5.909 0 12 0z'
                transform='matrix(1 0 0 -1 0 98)'
              />
              <path
                stroke='#EBEBEB'
                stroke-linecap='round'
                stroke-width='3'
                d='M6 13.835c3-3.459 10-3.459 12 0'
                transform='matrix(1 0 0 -1 0 98)'
              />
              <path
                fill='#000'
                d='M13 19.6a6.002 6.002 0 015.913 4.977H19V98H5V24.578h.087A6.002 6.002 0 0111 19.6h2z'
                transform='matrix(1 0 0 -1 0 98)'
              />
            </g>
          </svg>
        ) : null}
        {this.isCpa ? (
          <svg
            id='pis-coach-tail'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='99'
            viewBox='0 0 24 99'
          >
            <g fill='none' fill-rule='evenodd'>
              <path
                fill='#002C66'
                d='M12 0c6.091 0 11.123 10.322 11.897 23.694.067.2.103.413.103.635V96a2 2 0 01-2 2H2a2 2 0 01-2-2V24.33c0-.223.036-.436.103-.636C.877 10.322 5.909 0 12 0z'
                transform='matrix(1 0 0 -1 0 98)'
              />
              <path
                stroke='#EBEBEB'
                stroke-linecap='round'
                stroke-width='3'
                d='M6 13.835c3-3.459 10-3.459 12 0'
                transform='matrix(1 0 0 -1 0 98)'
              />
              <path
                fill='#D8D8D8'
                d='M13 19.6a6.002 6.002 0 015.913 4.977H19V98H5V24.578h.087A6.002 6.002 0 0111 19.6h2z'
                opacity='.318'
                transform='matrix(1 0 0 -1 0 98)'
              />
            </g>
          </svg>
        ) : null}
      </div>
    )
  }
}

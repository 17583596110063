





















import { IPointOfInterest } from '@/constants/interfaces'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

interface Props {
  pointOfInterest: IPointOfInterest
}

interface Events {
  onClick: IPointOfInterest
  onHide: string
}

@Component
export default class PointOfInterestBar extends tsx.Component<Props, Events> {
  @Prop({ required: true }) public pointOfInterest: IPointOfInterest

  public get redirectUrl(): string {
    return `/#/point-of-interest/${this.pointOfInterest.id}`
  }

  public navigateToPOIPage(): void {
    this.$emit('click', this.pointOfInterest)
  }

  public hideBar(): void {
    this.$emit('hide')
  }
}

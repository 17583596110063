






















import { IGoViewNotification, IPISContent } from '@/constants/interfaces'
import { NotificationGroup } from '@/enums/notificationGroup'
import { getDateAsISOString } from '@/helpers/item'

import { store } from '@/store'
import {
  Feedback,
  GetJourneyResponse,
  PisSubscriber,
} from '@gomedia-apis-ts-pis/v1'

import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

interface IFeedbackFormProps {
  analytics: any
}

export interface IFeedbackFormEvents {
  onShowNotification?: IGoViewNotification
}

const SUCCESS_NOTIFICATION: IGoViewNotification = {
  group: NotificationGroup.FEEDBACK,
  type: 'success',
}

const ERROR_NOTIFICATION: IGoViewNotification = {
  group: NotificationGroup.FEEDBACK,
  type: 'error',
}

@Component
export default class FeedbackForm extends tsx.Component<
  IFeedbackFormProps,
  IFeedbackFormEvents
> {
  @Prop() public analytics: any
  message = ''
  isLoading = false

  public get packageVersion(): string {
    return store.state.packageVersion
  }

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get journeyResponse(): GetJourneyResponse {
    return store.getters.getCopyJourneyResponse
  }

  public get btnLabel(): string {
    return this.isLoading
      ? this.content.FEEDBACK.LOADING
      : this.content.FEEDBACK.SUBMIT
  }

  public get title(): string {
    return this.content.FEEDBACK.TITLE
  }

  public get questionLabel(): string {
    return this.content.FEEDBACK.ISSUE.LABEL
  }

  public get questionPlaceholder(): string {
    return this.content.FEEDBACK.ISSUE.PLACEHOLDER
  }

  public get isValid(): boolean {
    return this.message && this.message.length > 0
  }

  public get userAgent(): string {
    return navigator.userAgent
  }

  public get disabled(): boolean {
    return !this.isValid || this.isLoading
  }

  public async requestSubscriber(): Promise<PisSubscriber> {
    try {
      return await store.dispatch('fetchSubscriber')
    } catch (error) {
      return { name: '' }
    }
  }

  public emitShowNotification(notification: IGoViewNotification) {
    this.$emit('showNotification', notification)
  }

  public async action(): Promise<void> {
    this.isLoading = true
    try {
      const subscriber: PisSubscriber = await this.requestSubscriber()
      this.journeyResponse.frontend_version = this.packageVersion
      const feedback: Feedback = {
        subscriber,
        create_time: getDateAsISOString(new Date()),
        journey_response: JSON.stringify(this.journeyResponse),
        message: this.message,
        user_agent: this.userAgent,
      }

      await store.dispatch('submitFeedback', {
        feedback,
        analytics: this.analytics,
      })
      this.emitShowNotification(SUCCESS_NOTIFICATION)
      store.dispatch('clearCachedJourneyData')
      store.dispatch('pushRouter', { link: '/pis' })
    } catch (e) {
      this.emitShowNotification(ERROR_NOTIFICATION)
    } finally {
      this.isLoading = false
    }
  }
}

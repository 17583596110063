import { setCSSPropertiesByFlavour } from '@/themes/theme'
import {
  Feedback,
  GetJourneyResponse,
  TrainPosition,
  ShareMyTrainResponse,
  GetJourneyStationsResponse,
} from '@gomedia-apis-ts-pis/v1'
import { stateMerge } from 'vue-object-merge'
import Gallup from 'gallup'
import { store } from '@/store'

import types from './types'

import {
  IData,
  Status,
  JourneySplitterEnum,
  IPISContent,
  IState,
  IShareMyTrainPisConstants,
} from '@/constants/interfaces'
import { PIS_CONFIG } from '@/constants'

export default {
  [types.REQUEST_JOURNEY](state: IState) {
    const stateData: IData<GetJourneyResponse> = {
      ...(state.journeyResponse.status !== Status.Init &&
        state.journeyResponse.status !== Status.FailedInit && {
          status: Status.Loading,
        }),
    }
    stateMerge(state, { journeyResponse: stateData })
  },
  [types.RECEIVE_JOURNEY](state: IState, journeyResponse: GetJourneyResponse) {
    const stateData: IData<GetJourneyResponse> = {
      status: Status.Received,
      data: journeyResponse as GetJourneyResponse,
      errMessage: '',
    }
    state.journeyResponse = stateData
  },
  [types.FAIL_JOURNEY](state: IState, error) {
    const stateData: IData<GetJourneyResponse> = {
      ...(state.journeyResponse.status === Status.Init ||
      state.journeyResponse.status === Status.FailedInit
        ? { status: Status.FailedInit }
        : { status: Status.Failed }),
      errMessage: error,
      data: { ...state.journeyResponse.data },
    }
    stateMerge(state, { journeyResponse: stateData })
  },
  [types.CLEAR_CACHED_JOURNEY]() {
    localStorage.removeItem(PIS_CONFIG.CACHED_JOURNEY_KEY)
  },
  [types.CACHE_JOURNEY](
    state: IState,
    cachedJourneyResponse: GetJourneyResponse,
  ) {
    localStorage.setItem(
      PIS_CONFIG.CACHED_JOURNEY_KEY,
      JSON.stringify(cachedJourneyResponse),
    )
  },
  [types.REQUEST_SHARE_URL](state: IState) {
    const stateData: IData<ShareMyTrainResponse> = {
      ...(state.shareUrl.status !== Status.Init &&
        state.shareUrl.status !== Status.FailedInit && {
          status: Status.Loading,
        }),
    }
    stateMerge(state, { shareUrl: stateData })
  },
  [types.RECEIVE_SHARE_URL](state: IState, shareUrl: ShareMyTrainResponse) {
    const stateData: IData<ShareMyTrainResponse> = {
      status: Status.Received,
      data: shareUrl,
      errMessage: '',
    }
    state.shareUrl = stateData
  },
  [types.FAIL_SHARE_URL](state: IState, error) {
    const stateData: IData<ShareMyTrainResponse> = {
      ...(state.shareUrl.status === Status.Init ||
      state.shareUrl.status === Status.FailedInit
        ? { status: Status.FailedInit }
        : { status: Status.Failed }),
      errMessage: error,
      data: {
        url: null,
      },
    }
    stateMerge(state, { shareUrl: stateData })
  },
  [types.REQUEST_TRAIN_POSITION](state) {
    const stateData: IData<TrainPosition> = {
      ...(state.trainPosition.status !== Status.Init &&
        state.trainPosition.status !== Status.FailedInit && {
          status: Status.Loading,
        }),
    }
    stateMerge(state, { trainPosition: stateData })
  },
  [types.RECEIVE_TRAIN_POSITION](state: IState, trainPosition: TrainPosition) {
    const stateData: IData<TrainPosition> = {
      status: Status.Received,
      data: trainPosition,
      errMessage: '',
    }
    state.trainPosition = stateData
  },
  [types.FAIL_TRAIN_POSITION](state: IState, error) {
    const stateData: IData<TrainPosition> = {
      ...(state.trainPosition.status === Status.Init ||
      state.trainPosition.status === Status.FailedInit
        ? { status: Status.FailedInit }
        : { status: Status.Failed }),
      errMessage: error,
      data: {
        latitude: state.trainPosition.data.latitude,
        longitude: state.trainPosition.data.longitude,
        speed: PIS_CONFIG.DEFAULT_SPEED,
      },
    }
    stateMerge(state, { trainPosition: stateData })
  },
  [types.REQUEST_WEATHER](state: IState) {
    const stateData: IData<any> = {
      ...(state.weather.status !== Status.Init &&
        state.weather.status !== Status.FailedInit && {
          status: Status.Loading,
        }),
    }
    stateMerge(state, { weather: stateData })
  },
  [types.RECEIVE_WEATHER](
    state: IState,
    data: { weather: any; tiploc: string },
  ) {
    const dataToAdd = {}
    dataToAdd[data.tiploc] = data.weather
    const stateData: IData<any> = {
      status: Status.Received,
      errMessage: '',
      data: {
        ...state.weather.data,
        ...dataToAdd,
      },
    }
    stateMerge(state, { weather: stateData })
  },
  [types.FAIL_WEATHER](state: IState, error) {
    const stateData: IData<any> = {
      ...(state.weather.status === Status.Init ||
      state.weather.status === Status.FailedInit
        ? { status: Status.FailedInit }
        : { status: Status.Failed }),
      errMessage: error,
      data: { ...state.weather.data },
    }
    stateMerge(state, { weather: stateData })
  },
  [types.SET_THEME_BY_FLAVOUR](state: IState, { flavour, element }) {
    state.theme = setCSSPropertiesByFlavour(flavour, element)
  },
  [types.SET_PREFERRED_FLAVOUR](state: IState, flavour) {
    state.preferredFlavour = flavour
  },
  [types.TOGGLE_SPLITTER](state: IState, payload: JourneySplitterEnum) {
    stateMerge(state, { showSplittedList: !!payload })
  },
  [types.CHANGE_LANGUAGE](state: IState, language: string) {
    stateMerge(state, { language })
  },
  [types.CHANGE_CONTENT](state: IState, content: IPISContent) {
    stateMerge(state, { content })
  },
  [types.CHANGE_ROUTER](state: IState, router: any) {
    if (!state.router.go) {
      stateMerge(state, { router })
    }
  },
  [types.PUSH_ROUTER](
    state: IState,
    { link, target }: { link: string; target: string },
  ) {
    link.startsWith('http') || target === '_blank'
      ? window.open(link, target)
      : state.router.push(link)
  },
  [types.BACK_ROUTER](state: IState) {
    state.router.go ? state.router.go(-1) : window.history.back()
  },
  [types.POLLING_START](state: IState, payload) {
    if (
      !state.poller ||
      !state.poller[payload.action] ||
      !state.poller[payload.action].poller
    ) {
      state.poller[payload.action] = new Gallup(() => {
        store.dispatch(payload.action, payload.token)
      }, payload.requestTimeout)
      state.poller[payload.action].start()
    }
  },
  [types.POLLING_STOP](state: IState, actionName = 'fetchJourney') {
    state.poller && state.poller[actionName] && state.poller[actionName].stop()
  },
  [types.SET_JOURNEY_STATIONS](
    state: IState,
    journeyStations: GetJourneyStationsResponse,
  ) {
    stateMerge(state, { journeyStations })
  },
}

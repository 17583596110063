




































































import {
  IMapConfig,
  IPISContent,
  IPointOfInterest,
} from '@/constants/interfaces'
import { Flavour } from '@/enums'
import { getNameI18n } from '@/helpers/item'
import { RoutePoint, Tiploc } from '@gomedia-apis-ts-pis/v1'
import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  LControl,
  LControlAttribution,
  LControlZoom,
  LMap,
  LMarker,
  LTileLayer,
} from 'vue2-leaflet'
import CurrentPosition from '@/components/svgs/map/currentPosition/index.vue'
import LightSpeedometer from './lightSpeedometer/index.vue'
import NextStationInfoBar from './nextStationInfoBar/index.vue'
import PointOfInterestBar from './pointOfInterestBar/index.vue'
import ToggleAnimationButton from './toggleAnimationButton/index.vue'

const PREVIOUS_MAP_STATE_KEY = 'PREVIOUS_MAP_STATE'
const TIME_TO_LIVE = 10 * 60 * 1000

interface IPreviousMapState {
  expiry: number
  recenter: boolean
  zoom: number
  center: { lat: number; lng: number }
}

export interface IMarkerPosition {
  lat: number
  lng: number
}

const isEnterKey = (event: KeyboardEvent): boolean =>
  event.keyCode === 13 || event.key === 'Enter'

@Component({
  inheritAttrs: false,
  components: {
    NextStationInfoBar,
    PointOfInterestBar,
    ToggleAnimationButton,
    LightSpeedometer,
    CurrentPosition,
    LMap,
    LControlZoom,
    LMarker,
    LTileLayer,
    LControlAttribution,
    LControl,
  },
})
export default class Map extends Vue {
  @Prop({ required: true }) readonly flavour: Flavour
  @Prop({ required: true }) readonly config: IMapConfig
  @Prop({ required: true }) readonly content: IPISContent
  @Prop({ type: Object }) readonly markerPosition: IMarkerPosition
  @Prop({ type: Array }) readonly stations: Tiploc[]
  @Prop({ type: Array }) readonly pointsOfInterest: IPointOfInterest[]
  @Prop({ type: Array }) readonly routePoints: RoutePoint[]
  @Prop({ type: Array }) readonly rawGeoJSONData: L.GeoJSON[]

  public recenter = true
  public isPOIBarVisible = false
  public selectedPOI: IPointOfInterest | null = null
  public isZoomCycleAnimationInProgress = false
  public stationsLayer: L.GeoJSON | null = null
  public pointsLayer: L.GeoJSON | null = null
  public routeLayer: L.Polyline | null = null
  public mapObject = null
  public zoomCycleIntervalId = 0
  public mapStateRecorderIntervalId = 0
  public isZoomCycleActive = false
  public isZooming = false
  public savedZomLevel: number | null = null
  public mapRef = 'pisMap'
  public selectedPOIElement: any = null
  public POI_BAR_REF = 'poi-bar'

  get attributions(): string[] {
    try {
      return this.content.MAP.ATTRIBUTIONS
    } catch (error) {
      return []
    }
  }

  get initialZoomLevel(): number {
    return this.savedZomLevel || this.config.ZOOM
  }
  get mapConfig() {
    return {
      url: this.config.URL,
      zoom: this.initialZoomLevel,
      minZoom: this.config.MIN_ZOOM,
      maxZoom: this.config.MAX_ZOOM,
      center: {
        lat: this.config.DEFAULT_COORDS.LATITUDE,
        lng: this.config.DEFAULT_COORDS.LONGITUDE,
      },
      maxBounds: this.config.DEFAULT_MAX_BOUNDS,
      options: {
        zoomControl: false,
      },
    }
  }

  get isLightSpeedometerEnabled(): boolean {
    return Boolean(
      this.config.LIGHT_SPEEDOMETER && this.config.LIGHT_SPEEDOMETER.ENABLED,
    )
  }

  get trainIcon(): L.Icon {
    const iconSize = this.config.MARKER.SIZE || 29
    return L.icon({
      iconUrl: this.config.MARKER.ICON,
      iconSize: [iconSize, iconSize],
    })
  }

  get trainMarkerOptions(): L.MarkerOptions {
    return {
      keyboard: false,
    }
  }

  get pointOfInterestIcon(): L.DivIcon {
    const html = `<button class="poi-button">
        <img
          src="${this.config.POINTS_OF_INTEREST.MARKER_ICON}"
          class="poi-icon"
          alt=""
        />
      </button>`

    return L.divIcon({
      html,
      className: 'pis-container',
      iconSize: [
        this.config.POINTS_OF_INTEREST.MARKER_WIDTH,
        this.config.POINTS_OF_INTEREST.MARKER_HEIGHT,
      ],
      iconAnchor: [
        this.config.POINTS_OF_INTEREST.MARKER_WIDTH / 2,
        this.config.POINTS_OF_INTEREST.MARKER_HEIGHT - 2,
      ],
    })
  }

  get lightSpeedometerStyles() {
    return {
      padding: `${this.isPOIBarVisible ? 80 : 20}px`,
    }
  }

  get mapAriaLabel(): string {
    if (!this.stations) {
      return ''
    }
    const stationList = this.stations
      .map((station) => getNameI18n(station.name))
      .join(',')
    return `Stops: ${stationList}`
  }

  public disableRecentering(): void {
    this.recenter = false
    this.isZoomCycleActive = false
  }

  public enableRecentering(): void {
    this.recenter = true
    this.markerPositionChangeWatcher()
    this.deactivateItemsOutside()
  }

  public hidePointOfInterestBar(): void {
    this.isPOIBarVisible = false
    this.selectedPOI = null
  }

  public hidePOIBarAndFocusBack(): void {
    this.hidePointOfInterestBar()
    this.$nextTick(() => {
      if (this.selectedPOIElement) {
        this.selectedPOIElement.focus()
        this.selectedPOIElement = null
      }
    })
  }

  public toggleZoomCycle() {
    this.isZoomCycleActive = !this.isZoomCycleActive
    if (this.isZoomCycleActive) {
      this.enableRecentering()
    } else {
      this.disableRecentering()
    }
  }

  public initZoomCycle(zoomCycle: number[], interval = 10000): void {
    const durationInSeconds = 1
    const animationFadeTime = 100
    const timeout = durationInSeconds * 1000 + animationFadeTime
    let i = 0
    this.isZoomCycleActive = this.recenter
    this.zoomCycleIntervalId = setInterval(() => {
      if (this.isZoomCycleActive && this.markerPosition) {
        this.mapObject.flyTo(this.markerPosition, zoomCycle[i], {
          duration: durationInSeconds,
          animate: true,
        })
        this.isZoomCycleAnimationInProgress = true
        setTimeout(() => (this.isZoomCycleAnimationInProgress = false), timeout)
        i = (i + 1) % zoomCycle.length
      }
    }, interval)
  }

  public onZoomEnd(): void {
    const zoomLevel = this.mapObject.getZoom()
    if (this.routeLayer) {
      this.mapObject.addLayer(this.routeLayer)
    }
    if (this.stationsLayer) {
      zoomLevel > this.config.MAP_STATION_STOPS.STATIONS_VISIBILITY_ZOOM_LEVEL
        ? this.mapObject.addLayer(this.stationsLayer)
        : this.mapObject.removeLayer(this.stationsLayer)
      this.stationsLayer.eachLayer((layer) => {
        zoomLevel > this.config.MAP_STATION_STOPS.TOOLTIPS_VISIBILITY_ZOOM_LEVEL
          ? layer.openTooltip()
          : layer.closeTooltip()
      })
    }
    if (this.pointsLayer) {
      this.pointsLayer.eachLayer((layer) => {
        layer.removeFrom(this.mapObject)
        const { feature } = layer as any
        const properties = feature.properties as IPointOfInterest
        zoomLevel >= properties.min_zoom
          ? layer.addTo(this.mapObject)
          : layer.remove()
      })
    }
    this.isZooming = false
    this.deactivateItemsOutside()
  }

  public onZoomStart(): void {
    this.isZooming = true
    if (this.stationsLayer) {
      this.mapObject.removeLayer(this.stationsLayer)
    }
    if (this.pointsLayer) {
      this.mapObject.removeLayer(this.pointsLayer)
    }
    if (this.routeLayer) {
      this.mapObject.removeLayer(this.routeLayer)
    }
  }

  public navigateToPointOfInterest(point: IPointOfInterest) {
    this.$emit('openPointDetails', point)
  }

  public saveCurrentActivePOIElement(): void {
    this.selectedPOIElement = document.activeElement
  }

  public showPOI(event: any): void {
    const {
      propagatedFrom: { feature },
    } = event
    this.isPOIBarVisible = true
    this.selectedPOI = feature.properties
    this.disableRecentering()
    this.$nextTick(() => {
      const poi = this.$refs[this.POI_BAR_REF] as PointOfInterestBar
      const element = poi.$el as HTMLElement
      element.focus()
    })
  }

  public onPointsLayerClick(event: any): void {
    this.saveCurrentActivePOIElement()
    this.showPOI(event)
  }

  public onPointsLayerKeypress(event: any): void {
    const wasSelected = document.activeElement === this.selectedPOIElement
    if (!wasSelected && isEnterKey(event.originalEvent)) {
      this.saveCurrentActivePOIElement()
      this.showPOI(event)
    }
  }

  public createStationsGeoJSON(stations: Tiploc[]) {
    const features = stations.reduce((acc, station) => {
      const { latitude, longitude } = station
      if (typeof latitude === 'number' && typeof longitude === 'number') {
        acc.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [longitude, latitude],
          },
          properties: { name: getNameI18n(station.name) },
        })
      }
      return acc
    }, [])

    return {
      features,
      type: 'FeatureCollection',
    }
  }

  public createPOIGeoJSON(pointList: IPointOfInterest[]) {
    const features = pointList.map((properties) => {
      const {
        location: [lat, lng],
      } = properties
      return {
        properties,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
      }
    })
    return {
      features,
      type: 'FeatureCollection',
    }
  }

  public getPolylineLayer(latlngs: L.LatLngExpression[]): L.Polyline {
    return L.polyline(latlngs, {
      color: this.config.ROUTE.COLOR,
      opacity: this.config.ROUTE.OPACITY,
      weight: this.config.ROUTE.WIDTH,
    })
  }

  public createMapStateRecorder(): void {
    this.mapStateRecorderIntervalId = setInterval(() => {
      const expiry = new Date().getTime() + TIME_TO_LIVE
      const mapStateToSave: IPreviousMapState = {
        expiry,
        recenter: this.recenter,
        zoom: this.mapObject.getZoom(),
        center: this.mapObject.getCenter(),
      }
      localStorage.setItem(
        PREVIOUS_MAP_STATE_KEY,
        JSON.stringify(mapStateToSave),
      )
    }, 1000)
  }

  public restoreMapState(): void {
    const savedState = localStorage.getItem(PREVIOUS_MAP_STATE_KEY)
    if (!savedState) {
      return
    }
    try {
      const previousMapState: IPreviousMapState = JSON.parse(savedState)
      const now = new Date().getTime()
      if (previousMapState && previousMapState.expiry > now) {
        const {
          zoom,
          recenter,
          center: { lng, lat },
        } = previousMapState
        this.recenter = recenter
        this.savedZomLevel = zoom
        this.mapObject.panTo({ lng, lat })
      }
    } catch (err) {
      console.error('Parsing JSON error', err)
    }
  }

  public getStationsLayer(geoJson): L.GeoJSON {
    const tooltipOptions = {
      className: 'station-tooltip',
      direction: 'bottom',
      permanent: true,
      opacity: 1,
    } as L.TooltipOptions
    const markerOptions = {
      radius: 8,
      fillColor: '#ffffff',
      color: this.config.ROUTE.COLOR,
      weight: this.config.ROUTE.WIDTH,
      opacity: 1,
      fillOpacity: 1,
    }
    return L.geoJSON(geoJson, {
      pointToLayer: (geoJsonPoint, latlng) => {
        const station = L.circleMarker(latlng, markerOptions)
        station.bindTooltip(geoJsonPoint.properties.name, tooltipOptions)
        return station
      },
    })
  }

  public getPointsOfInterestLayer(geoJSON): L.GeoJSON {
    return L.geoJSON(geoJSON, {
      pointToLayer: (geoJsonPoint, latlng) => {
        return L.marker(latlng, {
          draggable: false,
          keyboard: false,
          icon: this.pointOfInterestIcon,
          riseOnHover: true,
          zIndexOffset: 10000,
        })
      },
    })
  }

  public deactivateItemsOutside(): void {
    if (!this.pointsLayer) return
    const mapBounds = this.mapObject.getBounds()
    this.pointsLayer.eachLayer((layer: L.Marker) => {
      const isInView: boolean = mapBounds.contains(layer.getLatLng())
      const layerElement: HTMLElement = layer.getElement()
      if (!layerElement) return

      const button: HTMLButtonElement = layerElement.querySelector('button')
      if (isInView) {
        const props: IPointOfInterest = layer.feature.properties
        button.setAttribute('tabindex', '0')
        button.setAttribute('aria-label', `Point of interest: ${props.title}`)
        button.removeAttribute('aria-hidden')
      } else {
        button.setAttribute('tabindex', '-1')
        button.setAttribute('aria-hidden', 'true')
        button.removeAttribute('aria-label')
      }
    })
  }

  public stationsChangeWatcher(): void {
    if (this.stationsLayer) {
      this.mapObject.removeLayer(this.stationsLayer)
      this.stationsLayer = null
    }
    if (this.stations) {
      this.createMapStations(this.stations)
      this.resetMapLayersZIndex()
    }
  }

  public routeChangeWatcher(): void {
    if (this.routeLayer) {
      this.mapObject.removeLayer(this.routeLayer)
      this.routeLayer = null
    }
    if (this.routePoints) {
      this.createJourneyRoute(this.routePoints)
      this.resetMapLayersZIndex()
    }
  }

  public poiChangeWatcher(): void {
    if (this.pointsLayer) {
      this.mapObject.removeLayer(this.pointsLayer)
      this.pointsLayer = null
    }
    if (this.pointsOfInterest) {
      this.createPointsOfInterest(this.pointsOfInterest)
      this.resetMapLayersZIndex()
    }
  }

  public markerPositionChangeWatcher(): void {
    if (
      this.recenter &&
      !this.isZoomCycleAnimationInProgress &&
      this.markerPosition
    ) {
      this.mapObject.panTo(this.markerPosition)
    }
  }

  public geoJSONChangeWatcher(
    newGeoJSONs: L.GeoJSON[],
    oldGeoJSONs: L.GeoJSON[],
  ): void {
    if (oldGeoJSONs && oldGeoJSONs.length) {
      oldGeoJSONs.forEach((el) => this.mapObject.removeLayer(el))
    }
    if (newGeoJSONs && newGeoJSONs.length) {
      newGeoJSONs.forEach((el) => this.mapObject.addLayer(el))
    }
  }

  public createMapStations(stations: Tiploc[]) {
    this.stationsLayer = this.getStationsLayer(
      this.createStationsGeoJSON(stations),
    )
    this.mapObject.addLayer(this.stationsLayer)
  }

  public createMapEventListeners(): void {
    this.mapObject.on('dragstart', this.disableRecentering)
    this.mapObject.on('resize', this.deactivateItemsOutside)
    this.mapObject.on('moveend', this.deactivateItemsOutside)
    this.mapObject.on('zoomstart', this.onZoomStart)
    this.mapObject.on('zoomend', this.onZoomEnd)
    this.mapObject.on('click', this.hidePointOfInterestBar)
  }

  public createPointsOfInterest(pointsOfInterest: IPointOfInterest[]): void {
    const geoJSON = this.createPOIGeoJSON(pointsOfInterest)
    this.pointsLayer = this.getPointsOfInterestLayer(geoJSON)
    this.pointsLayer.on('click', this.onPointsLayerClick)
    this.pointsLayer.on('keypress', this.onPointsLayerKeypress)
    this.mapObject.addLayer(this.pointsLayer)
  }

  public createJourneyRoute(routePoints: RoutePoint[]): void {
    const latlang: L.LatLngExpression[] = routePoints.map((point): [
      number,
      number,
    ] => [point.latitude, point.longitude])
    this.routeLayer = this.getPolylineLayer(latlang)
    this.mapObject.addLayer(this.routeLayer)
  }

  public resetMapLayersZIndex() {
    this.onZoomStart()
    this.onZoomEnd()
  }

  public init(): void {
    this.restoreMapState()

    if (this.config.ZOOM_CYCLE) {
      this.initZoomCycle(
        this.config.ZOOM_CYCLE,
        this.config.ZOOM_CYCLE_INTERVAL,
      )
    }

    if (this.config.SAVE_PREVIOUS_MAP_STATE) {
      this.createMapStateRecorder()
    }

    this.createMapEventListeners()

    this.$watch(() => this.stations, this.stationsChangeWatcher, {
      immediate: true,
    })
    this.$watch(() => this.routePoints, this.routeChangeWatcher, {
      immediate: true,
    })
    this.$watch(() => this.pointsOfInterest, this.poiChangeWatcher, {
      immediate: true,
    })
    this.$watch(() => this.markerPosition, this.markerPositionChangeWatcher, {
      immediate: true,
    })
    this.$watch(() => this.rawGeoJSONData, this.geoJSONChangeWatcher, {
      immediate: true,
    })
    this.resetMapLayersZIndex()
  }

  public removeNativeMapAttribution(): void {
    this.mapObject.attributionControl.setPrefix('')
  }

  mounted(): void {
    this.mapObject = this.$refs[this.mapRef]['mapObject']
    this.removeNativeMapAttribution()
    this.init()
  }

  beforeDestroy(): void {
    clearInterval(this.zoomCycleIntervalId)
    clearInterval(this.mapStateRecorderIntervalId)
  }
}

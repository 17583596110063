




















import CoachList from '@/components/coachList/index.vue'

import DataLoader, {
  IDataLoaderEvents,
} from '@/components/dataLoader/index.vue'
import DeparturesList from '@/components/departuresList/index.vue'
import FacilitiesList from '@/components/facilitiesList/index.vue'
import FooterBar, { IFooterEvents } from '@/components/footerBar/index.vue'
import JourneyList from '@/components/journeyList/index.vue'
import MapComponent from '@/components/mapDataLoader/index.vue'
import SbbTrafimageMap from '@/components/sbbTrafimageMap/index.vue'
import DisruptionMessage from '@/components/disruptionMessage/index.vue'
import { PIS_CONFIG } from '@/constants'
import {
  EPisType,
  IDeparturesOption,
  IJourneyOption,
  ILoadOption,
  IMapConfig,
  IMapOption,
  IPISContent,
  ISbbTrafimageMapOptions,
  IStationInformationOption,
  ITrainInfoOption,
  JourneySplitterEnum,
} from '@/constants/interfaces'
import { Flavour } from '@/enums'
import { EDepartureType } from '@/helpers/item'
import { getParamFromLocation } from '@/helpers/locationHelpers'
import { store } from '@/store'
import { VueConstructor } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

const shared = process.env.VUE_APP_SHAREMYTRAIN === 'true'

export interface IPisContainerProps {
  componentType: EPisType
  content?: IPISContent
  language?: string
  attributionDisabled?: boolean
  platformsDisabled?: boolean
  footerDisabled?: boolean
  router: any
  navigationDisabled?: boolean
  skipStationInformation?: boolean
  flavour?: Flavour
  token?: string
  mapConfig?: IMapConfig
  amPm?: boolean
}

type IPisContainerEvents = IDataLoaderEvents & IFooterEvents

@Component({ components: { DataLoader, FooterBar } })
export default class Pis extends tsx.Component<
  IPisContainerProps,
  IPisContainerEvents
> {
  @Prop() public content: IPISContent
  @Prop() public language: string
  @Prop() public attributionDisabled: boolean
  @Prop() public platformsDisabled: boolean
  @Prop() public hideAlerts: boolean
  @Prop() public footerDisabled: boolean
  @Prop() public router: any
  @Prop() public analytics: any
  @Prop() public navigationDisabled: boolean
  @Prop() public skipStationInformation: boolean
  @Prop() public showShare: boolean
  @Prop() public showVehicleIcons: boolean
  @Prop() public showBackToAppButton: boolean
  @Prop() public componentType: EPisType
  @Prop() public flavour: Flavour
  @Prop() public token: string
  @Prop() public mapConfig: IMapConfig
  @Prop() public amPm: boolean
  @Prop() public showStationLabels: boolean
  @Prop({ default: PIS_CONFIG.DEFAULT_SHOW_FEEDBACK_HREF })
  public showFeedbackHref: boolean

  get components() {
    const map =
      this.flavour === Flavour.SBB
        ? {
            component: SbbTrafimageMap,
            options: {
              flavour: this.flavour,
              mapConfig: this.mapConfig || PIS_CONFIG.DEFAULT_MAP_CONFIG,
              language: this.language,
            } as ISbbTrafimageMapOptions,
          }
        : {
            component: MapComponent,
            options: {
              actionName: 'fetchTrainPosition',
              requestTimeout: 1000,
              latitude: store.getters.getLatitude,
              longitude: store.getters.getLongitude,
              flavour: this.flavour,
              mapConfig: this.mapConfig || PIS_CONFIG.DEFAULT_MAP_CONFIG,
            } as IMapOption,
          }
    return {
      map,
      departures: {
        component: DeparturesList,
        options: {
          tiploc: getParamFromLocation('TIPLOC'),
          departureType:
            EDepartureType[getParamFromLocation('type') || 'train'],
          amPm: this.amPm,
          showPlatforms: !this.platformsDisabled,
          showVehicleIcons: this.showVehicleIcons,
        } as IDeparturesOption,
      },
      journey: {
        component: JourneyList,
        options: {
          showShare: this.showShare,
          showArrow: !this.navigationDisabled,
          showPlatforms: !this.platformsDisabled,
          skipStationInformation: this.skipStationInformation,
          setOrigin: () =>
            store.dispatch('toggleSplitter', JourneySplitterEnum.Origin),
          setSplit: () =>
            store.dispatch('toggleSplitter', JourneySplitterEnum.Split),
          showSplittedList: store.getters.showSplittedList,
          token: this.token,
          actionName: shared ? 'fetchJourneyByToken' : 'fetchJourney',
          requestTimeout: 10000,
          amPm: this.amPm,
          hideAlerts: this.hideAlerts || PIS_CONFIG.DEFAULT_HIDE_ALERTS,
          flavour: this.flavour,
          showStationLabels: this.showStationLabels,
        } as IJourneyOption,
      },
      stationInformation: {
        component: FacilitiesList,
        options: {
          tiploc: getParamFromLocation('TIPLOC'),
          alert: localStorage.getItem('PIS_ALERT_MESSAGE') || '',
          flavour: this.flavour,
        } as IStationInformationOption,
      },
      vehicleInformation: {
        component: CoachList,
        options: {
          flavour: this.flavour,
        } as ITrainInfoOption,
      },
      disruptionMessage: {
        component: DisruptionMessage,
        options: {},
      },
    }
  }

  @Watch('language') public languageChanged(newVal, oldVal) {
    store.dispatch('changeLanguage', newVal || oldVal)
  }

  @Watch('content') public contentChanged(newVal, oldVal) {
    store.dispatch('changeContent', newVal || oldVal)
  }

  @Watch('router') public routerChanged(newVal, oldVal) {
    store.dispatch('changeRouter', newVal || oldVal)
  }

  public get packageVersion(): string {
    return store.state.packageVersion
  }

  mounted() {
    store.dispatch('setThemeByFlavour', {
      flavour: this.flavour,
      element: this.$el,
    })

    if (this.language) {
      store.dispatch('changeLanguage', this.language)
    }
    if (this.content) {
      store.dispatch('changeContent', this.content)
    }
    if (this.router) {
      store.dispatch('changeRouter', this.router)
    }
  }

  public get pisType(): string {
    return EPisType[this.componentType]
  }

  public get options(): ILoadOption {
    return this.components[this.pisType].options
  }

  public get component(): VueConstructor<Vue> {
    return this.components[this.pisType].component
  }
}

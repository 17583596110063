






import * as tsx from 'vue-tsx-support'
import { Component, Prop } from 'vue-property-decorator'
import { Journey } from '@gomedia-apis-ts-pis/v1'
import { store } from '@/store'
import { IPISContent } from '@/constants/interfaces'
import { getOriginCallingPoints } from '@/helpers/item'
import { isNoJourneyAvailable } from '@/helpers/noJourneyAvailable'

interface INoJourneyAvailableProps {
  journey: Journey
}

@Component
export default class NoJourneyAvailable extends tsx.Component<INoJourneyAvailableProps> {
  @Prop() public journey: Journey

  public get list() {
    return getOriginCallingPoints(this.journey)
  }

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get isEmpty(): boolean {
    return isNoJourneyAvailable(this.list)
  }
}

import { ITypography } from '@/themes/theme'

export const applyTypography = async (typography: ITypography) => {
  try {
    const fonts = await Promise.allSettled(
      typography.assets.map((el) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const f = new FontFace(el.fontFamily, el.src, {
          weight: el.fontWeight,
          style: el.fontStyle,
        })
        return f.load()
      }),
    )
    fonts.forEach((promise) => {
      if (promise.status === 'fulfilled') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.fonts.add(promise.value)
      }
    })
  } catch (e) {
    console.error(e)
  }
}

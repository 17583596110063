import * as tsx from 'vue-tsx-support'
import { Component, Prop, Watch } from 'vue-property-decorator'
import Spinner from 'vue-simple-spinner'

import Weather from '@/components/svgs/weather'

import { store } from '@/store'
import { canRenderData, isRenderError } from '@/helpers/gettersLogic'

interface IWeatherLoaderProps {
  tiploc: string
  flavour: string
}

@Component
export default class WeatherLoader extends tsx.Component<IWeatherLoaderProps> {
  @Prop({
    required: true,
  })
  public tiploc: string
  @Prop({
    required: true,
  })
  public flavour: string

  @Watch('tiploc') public tiplocChanged(newTiploc: string) {
    this.action(newTiploc, this.flavour)
  }

  public get canRenderData(): boolean {
    return canRenderData(store.getters.getWeatherStatus)
  }

  public get isRenderError(): boolean {
    return isRenderError(store.getters.getWeatherStatus)
  }

  public get weather(): number {
    return store.getters.getWeather
  }

  public get weatherIconNumber(): number {
    return this.weather && this.weather[this.tiploc]
      ? this.weather[this.tiploc].WeatherIcon
      : null
  }

  private mounted(): void {
    this.action(this.tiploc, this.flavour)
  }

  private action(tiploc: string, flavour: string) {
    store.dispatch('fetchWeather', {
      tiploc,
      flavour,
    })
  }

  render(h) {
    return this.canRenderData ? (
      <Weather weatherIconNumber={this.weatherIconNumber} />
    ) : this.isRenderError ? null : (
      <Spinner size='large' speed={0.6} />
    )
  }
}

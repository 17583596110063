


















import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { LControl } from 'vue2-leaflet'

interface IComponentProps {
  isZoomCycleActive: boolean
}

interface IComponentEvents {
  onClick: void
}

@Component({ components: { LControl } })
export default class AnimationButton extends tsx.Component<
  IComponentProps,
  IComponentEvents
> {
  @Prop({ required: true, type: Boolean })
  readonly isZoomCycleActive: boolean

  private get iconClassName(): string {
    return this.isZoomCycleActive ? 'pause-icon' : 'play-icon'
  }

  private onClick() {
    this.$emit('click')
  }
}






import { Flavour } from '@/enums'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { store } from '@/store'

const LOGO_IMAGE_NAME = {
  [Flavour.AVT]: 'avt.png',
  [Flavour.CPA]: 'cpa.png',
  [Flavour.QR]: 'qr.png',
  [Flavour.SBB]: 'sbb.png',
  [Flavour.TFW]: 'tfw.png',
  [Flavour.SPA]: 'spa.png',
  [Flavour.TPE]: 'tpe.png',
  [Flavour.GV]: 'gv.svg',
}

@Component
export default class ShareMyTrainLogo extends tsx.Component<{}> {
  public get flavour(): Flavour {
    return store.getters.getFlavour
  }

  private get logoSrc() {
    const logo = LOGO_IMAGE_NAME[this.flavour] || LOGO_IMAGE_NAME[Flavour.GV]
    return `/images/${logo}`
  }
}

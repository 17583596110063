import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

interface IWeatherProps {
  weatherIconNumber: number
}

@Component
export default class Weather extends tsx.Component<IWeatherProps> {
  @Prop({
    required: true,
  })
  weatherIconNumber: number

  render(h) {
    return (
      <div>
        {this.weatherIconNumber === 1 ? (
          <svg
            id='pis-weather-1'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M22.007 15.005c0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7 7 3.134 7 7z'
                transform='translate(5 5)'
              />
              <path
                stroke-linecap='round'
                d='M15 0L15 3.999M15 26.001L15 30M30 14.999L26.001 14.999M3.999 14.999L0 14.999M4.394 4.393L7.221 7.221M22.778 22.778L25.606 25.606M25.606 4.393L22.778 7.221M7.221 22.778L4.393 25.606'
                transform='translate(5 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 2 ? (
          <svg
            id='pis-weather-2'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M22.007 15.005c0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7 7 3.134 7 7z'
                transform='translate(3 5)'
              />
              <path
                stroke-linecap='round'
                d='M15 0L15 3.999M15 26.001L15 30M30 14.999L26.001 14.999M3.999 14.999L0 14.999M4.393 4.393L7.22 7.221M25.606 4.393L22.778 7.221M7.221 22.778L4.393 25.606'
                transform='translate(3 5)'
              />
              <path
                d='M34.537 25.485c0-1.77-1.435-3.205-3.204-3.205-.105 0-.207.006-.309.016-.63-1.573-2.167-2.686-3.964-2.686-2.18 0-3.975 1.634-4.238 3.743-.012 0-.023-.004-.035-.004-1.475 0-2.67 1.196-2.67 2.671 0 1.475 1.195 2.671 2.67 2.671l8.574-.002c1.761-.01 3.176-1.44 3.176-3.204z'
                transform='translate(3 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 3 ? (
          <svg
            id='pis-weather-3'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M12.562 15.752c-.49.162-1.014.248-1.558.248-2.761 0-5-2.239-5-5s2.239-5 5-5c2.673 0 4.857 2.098 4.994 4.738.004.085.005.175.005.263'
                transform='translate(5 8)'
              />
              <path
                stroke-linecap='round'
                d='M11.005 0L11.005 3.01M3.01 11.005L0 11.005M3.224 3.224L5.352 5.352M18.786 3.224L16.658 5.351M5.352 16.658L3.223 18.786'
                transform='translate(5 8)'
              />
              <path
                d='M25.01 13.994c-.978-2.348-3.295-3.998-5.998-3.998-3.44 0-6.247 2.672-6.48 6.052-.172-.024-.345-.054-.527-.054-2.21 0-4 1.792-4 4 0 2.21 1.79 4 4 4h13c2.76 0 4.999-2.239 4.999-5 0-2.76-2.235-4.997-4.993-5z'
                transform='translate(5 8)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 4 ? (
          <svg
            id='pis-weather-4'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M16.934 16.743c-.49.16-1.014.248-1.558.248-2.761 0-5-2.24-5-5 0-2.762 2.239-5 5-5 2.673 0 4.857 2.097 4.994 4.737.004.086.006.175.006.263'
                transform='translate(5 9)'
              />
              <path
                stroke-linecap='round'
                d='M15.377.99L15.377 4M7.382 11.995L4.372 11.995M23.159 4.214L21.031 6.341M9.724 17.648L7.595 19.776'
                transform='translate(5 9)'
              />
              <path
                d='M25.662 15.631c-.688-1.65-2.316-2.81-4.216-2.81-2.417 0-4.39 1.878-4.553 4.254-.122-.017-.244-.038-.372-.038-1.552 0-2.811 1.259-2.811 2.811 0 1.554 1.259 2.811 2.811 2.811h9.137c1.94 0 3.513-1.573 3.513-3.514 0-1.939-1.571-3.512-3.509-3.514zM10.502 4.278c0-1.289-1.045-2.334-2.333-2.334-.076 0-.151.004-.225.012C7.484.81 6.366 0 5.057 0 3.469 0 2.162 1.19 1.97 2.725c-.01 0-.017-.003-.026-.003C.872 2.722 0 3.593 0 4.668c0 1.073.872 1.944 1.945 1.944l6.237-.001c1.282-.007 2.32-1.049 2.32-2.333z'
                transform='translate(5 9)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 5 ? (
          <svg
            id='pis-weather-5'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                d='M3.002 13L26.998 13'
                transform='translate(5 9)'
              />
              <g>
                <path
                  stroke-linecap='round'
                  d='M0 17L29.999 17M8.004 21L22.996 21'
                  transform='translate(5 9)'
                />
                <path
                  d='M6.936 13C6.338 11.792 6 10.434 6 9c0-4.971 4.03-9 9-9 4.971 0 9 4.029 9 9 0 1.435-.335 2.793-.934 4'
                  transform='translate(5 9)'
                />
              </g>
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 6 ? (
          <svg
            id='pis-weather-6'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M9.556 15.787C7.5 15.167 6.004 13.258 6.004 11c0-2.76 2.238-5 5-5 2.673 0 4.857 2.098 4.993 4.738.005.086.007.174.007.262'
                transform='translate(4 5)'
              />
              <path
                stroke-linecap='round'
                d='M11.005 0L11.005 3.01M3.01 11.005L0 11.005M3.223 3.224L5.351 5.352M18.786 3.224L16.658 5.351M5.352 16.658L3.223 18.786'
                transform='translate(4 5)'
              />
              <path
                d='M28.695 21.642c2.221-.509 3.878-2.499 3.878-4.875 0-2.76-2.235-4.997-4.994-5C26.6 9.42 24.283 7.77 21.58 7.77c-3.44 0-6.247 2.62-6.478 6'
                transform='translate(4 5)'
              />
              <path
                d='M29.573 24.768c0-3.313-2.686-6-5.999-6-.194 0-.387.01-.577.029-1.181-2.946-4.057-5.03-7.423-5.03-4.081 0-7.442 3.06-7.933 7.008-.022-.001-.044-.007-.066-.007-2.762 0-5.001 2.24-5.001 5s2.239 5 5.001 5h16.034c3.297-.02 5.964-2.698 5.964-6z'
                transform='translate(4 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 7 ? (
          <svg
            id='pis-weather-7'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M26.121 13.873C28.342 13.364 30 11.374 30 8.998c0-2.76-2.235-4.997-4.994-5C24.025 1.65 21.71 0 19.006 0c-3.44 0-6.247 2.62-6.478 6'
                transform='translate(5 9)'
              />
              <path
                d='M27 16.998c0-3.313-2.687-6-6-6-.194 0-.387.011-.577.03-1.18-2.947-4.057-5.03-7.423-5.03-4.08 0-7.442 3.06-7.933 7.007-.022 0-.044-.007-.066-.007-2.762 0-5 2.24-5 5 0 2.761 2.238 5 5 5h16.034c3.297-.02 5.964-2.698 5.964-6z'
                transform='translate(5 9)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 8 ? (
          <svg
            id='pis-weather-8'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M33.018 12.01c-.777-1.863-2.616-3.173-4.76-3.173-2.73 0-4.959 2.12-5.143 4.803-.138-.02-.275-.043-.419-.043-1.754 0-3.175 1.423-3.175 3.175 0 1.754 1.421 3.175 3.175 3.175h10.318c2.191 0 3.967-1.777 3.967-3.97 0-2.19-1.774-3.965-3.963-3.967z'
                transform='translate(2 9)'
              />
              <path
                d='M29.999 8.998c0-2.76-2.235-4.997-4.994-5-.98-2.348-3.296-3.998-6-3.998-3.44 0-6.246 2.62-6.477 6'
                transform='translate(2 9)'
              />
              <path
                d='M23.43 11.51C22.685 11.183 21.864 11 21 11c-.195 0-.387.01-.577.029-1.18-2.946-4.057-5.03-7.423-5.03-4.08 0-7.443 3.06-7.933 7.008-.022-.001-.044-.007-.066-.007-2.762 0-5 2.24-5 5s2.238 5 5 5l16.034-.001c2.223-.013 4.16-1.234 5.185-3.041'
                transform='translate(2 9)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 11 ? (
          <svg
            id='pis-weather-11'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g
              fill='none'
              fill-rule='evenodd'
              stroke='#FFF'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
            >
              <g>
                <path
                  d='M0 9L30 9M2.001 5L25.998 5M4.002 17L27.999 17M1.001 1.001L11.991 1.001M16.001 1.001L21.989 1.001M28.001 1.001L29.987 1.001'
                  transform='translate(5 11) translate(0 -.001)'
                />
              </g>
              <path
                d='M25.985 12.999L14.995 12.999M10.985 12.999L4.997 12.999'
                transform='translate(5 11)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 12 ? (
          <svg
            id='pis-weather-12'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M23.5 5.008c-.084 0-.166.009-.249.013C21.921 2.063 18.955 0 15.502 0c-4.267 0-7.788 3.147-8.398 7.244-.507-.155-1.045-.24-1.603-.24C2.463 7.005 0 9.469 0 12.505c0 3.038 2.463 5.5 5.501 5.5h17.94c.02 0 .039.004.059.004 3.589 0 6.499-2.91 6.499-6.501 0-3.59-2.91-6.5-6.499-6.5z'
                transform='translate(5 5)'
              />
              <path
                stroke-linecap='round'
                d='M21.999 22L21.999 29.999M17 22L17 26.998M11.999 22L11.999 29.999M7 22L7 26.998'
                transform='translate(5 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 13 ? (
          <svg
            id='pis-weather-13'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M7.863 14.52c-1.69-.802-2.859-2.525-2.859-4.52 0-2.762 2.238-5 5-5 2.041 0 3.796 1.223 4.574 2.977'
                transform='translate(6 5)'
              />
              <path
                stroke-linecap='round'
                d='M10.005 0L10.005 2.009M2.01 10.004L0 10.004M3.224 3.224L4.352 4.351M16.785 3.224L15.658 4.351M4.351 15.657L3.224 16.785'
                transform='translate(6 5)'
              />
              <path
                d='M24.01 10.993c-.978-2.348-3.295-4-5.998-4-3.44 0-6.247 2.674-6.48 6.054-.172-.024-.345-.054-.527-.054-2.21 0-4 1.792-4 4 0 2.209 1.79 4 4 4h13c2.76 0 4.999-2.24 4.999-5s-2.235-4.997-4.993-5z'
                transform='translate(6 5)'
              />
              <path
                stroke-linecap='round'
                d='M24.004 23.993L24.004 29.991M20.005 23.993L20.005 26.99M16.005 23.993L16.005 29.991M12.006 23.993L12.006 26.99'
                transform='translate(6 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 14 ? (
          <svg
            id='pis-weather-14'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M15.034 10.233c0-.082-.002-.164-.006-.244-.13-2.456-2.18-4.408-4.69-4.408-2.595 0-4.698 2.083-4.698 4.652 0 2.248 1.61 4.122 3.75 4.556'
                transform='translate(5)'
              />
              <path
                stroke-linecap='round'
                d='M10.338 0L10.338 2.8M2.828 10.237L0 10.237M3.028 2.999L5.027 4.978M17.647 2.999L15.649 4.977M5.027 15.496L3.027 17.475'
                transform='translate(5)'
              />
              <path
                d='M26.956 20.132c2.086-.473 3.643-2.324 3.643-4.535 0-2.567-2.1-4.648-4.691-4.65-.921-2.185-3.097-3.72-5.636-3.72-3.231 0-5.868 2.437-6.085 5.582'
                transform='translate(5)'
              />
              <path
                d='M27.78 23.04c0-3.082-2.523-5.582-5.635-5.582-.182 0-.363.01-.542.027-1.11-2.74-3.81-4.678-6.973-4.678-3.834 0-6.99 2.846-7.452 6.518-.02 0-.041-.006-.062-.006-2.595 0-4.698 2.084-4.698 4.651 0 2.568 2.103 4.651 4.698 4.651h15.062c3.097-.018 5.603-2.51 5.603-5.581z'
                transform='translate(5)'
              />
              <path
                stroke-linecap='round'
                d='M21.973 32.089L21.973 39.53M17.277 32.089L17.277 36.738M12.579 32.089L12.579 39.53M7.883 32.089L7.883 36.738'
                transform='translate(5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 15 ? (
          <svg
            id='pis-weather-15'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g
              fill='none'
              fill-rule='evenodd'
              stroke='#FFF'
              stroke-linecap='round'
              stroke-width='2'
            >
              <path
                d='M20 18.005h3.441c.02 0 .039.003.059.003 3.589 0 6.499-2.91 6.499-6.5s-2.91-6.5-6.499-6.5c-.084 0-.166.009-.249.013C21.921 2.064 18.955 0 15.502 0c-4.267 0-7.788 3.148-8.398 7.244-.507-.154-1.045-.24-1.603-.24C2.463 7.005 0 9.469 0 12.505c0 3.038 2.463 5.5 5.501 5.5h4.498M1.996 28.246L.593 29.999M6.997 22L4.049 25.683'
                transform='translate(5 5)'
              />
              <path
                d='M17 15.75L12 21.999 15.999 22 11.219 27.971M19.985 25.769L16.598 29.999M23.001 22L22.201 23'
                transform='translate(5 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 16 ? (
          <svg
            id='pis-weather-16'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                d='M5.764 30.215L5.157 30.967M10 24.994L7.817 27.651M21.988 28.763L20.218 30.968M25.004 24.994L24.204 25.994'
                transform='translate(6 4)'
              />
              <g>
                <path
                  stroke-linecap='round'
                  d='M19.788 17.779L14.788 24.028 18.787 24.028 14.007 30'
                  transform='translate(6 4) translate(0 .968)'
                />
                <path
                  d='M7.863 14.552c-1.69-.802-2.859-2.525-2.859-4.521 0-2.761 2.238-5 5-5 2.041 0 3.796 1.224 4.574 2.978'
                  transform='translate(6 4) translate(0 .968)'
                />
                <path
                  stroke-linecap='round'
                  d='M10.005.032L10.005 2.042M2.01 10.036L0 10.036M3.224 3.255L4.352 4.383M16.785 3.255L15.658 4.383M4.351 15.69L3.224 16.817M22.002 21.025h2.003c2.76 0 4.999-2.239 4.999-5 0-2.76-2.235-4.998-4.993-5-.98-2.349-3.296-3.999-6-3.999-3.44 0-6.246 2.673-6.478 6.052-.174-.023-.346-.053-.528-.053-2.21 0-4 1.791-4 4 0 2.21 1.79 4 4 4H13'
                  transform='translate(6 4) translate(0 .968)'
                />
              </g>
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 17 ? (
          <svg
            id='pis-weather-17'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                d='M19.788 17.747L14.788 23.997 18.787 23.997 14.007 29.968'
                transform='translate(6 5)'
              />
              <path
                d='M7.863 14.52c-1.69-.803-2.859-2.525-2.859-4.521 0-2.762 2.238-5 5-5 2.041 0 3.796 1.224 4.574 2.978'
                transform='translate(6 5)'
              />
              <path
                stroke-linecap='round'
                d='M10.005 0L10.005 2.01M2.01 10.004L0 10.004M3.224 3.223L4.352 4.351M16.785 3.223L15.658 4.35M4.352 15.657L3.224 16.785M22.002 20.993h2.003c2.761 0 4.999-2.239 4.999-5 0-2.76-2.235-4.998-4.993-5-.98-2.349-3.296-3.999-5.999-3.999-3.44 0-6.247 2.673-6.479 6.052-.174-.023-.346-.053-.528-.053-2.209 0-4 1.792-4 4 0 2.21 1.791 4 4 4h1.996'
                transform='translate(6 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 18 ? (
          <svg
            id='pis-weather-18'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M23.499 5.008c-.084 0-.165.01-.248.013C21.92 2.063 18.954 0 15.502 0c-4.268 0-7.789 3.147-8.398 7.244-.507-.155-1.045-.24-1.604-.24-3.037 0-5.5 2.464-5.5 5.5 0 3.038 2.463 5.5 5.5 5.5h17.941c.02 0 .038.004.058.004 3.59 0 6.5-2.91 6.5-6.501 0-3.59-2.91-6.5-6.5-6.5z'
                transform='translate(5 5)'
              />
              <path
                stroke-linecap='round'
                d='M4.998 22L1.796 26.001M10.998 22L4.594 29.999M16.999 22L13.797 26.001M22.999 22L16.595 29.999'
                transform='translate(5 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 19 ? (
          <svg
            id='pis-weather-19'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M23.498 5.008c-.084 0-.165.01-.248.013C21.92 2.063 18.953 0 15.502 0c-4.269 0-7.79 3.147-8.399 7.244-.506-.155-1.044-.24-1.603-.24-3.038 0-5.5 2.464-5.5 5.5 0 3.038 2.462 5.5 5.5 5.5h17.94c.02 0 .038.004.058.004 3.59 0 6.5-2.91 6.5-6.501 0-3.59-2.91-6.5-6.5-6.5z'
                transform='translate(5 5)'
              />
              <path
                stroke-linecap='round'
                d='M.999 26.996L0 28.244M4.998 22L3.226 24.215M5.394 29L4.594 29.999M10.998 22L7.813 25.977M13.831 25.958L12.001 28.244M16.998 22L16.198 23M18.673 27.402L16.594 29.999M22.998 22L21.05 24.434'
                transform='translate(5 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 20 ? (
          <svg
            id='pis-weather-20'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                d='M5 22.002L3.733 23.583M9.999 22.002L5.46 27.671M15 22.002L13.771 23.538'
                transform='translate(8 5)'
              />
              <g stroke-linecap='round'>
                <path
                  d='M18.999.001L14.46 5.67M.798 4.001L.798 4.001M2.595 8L2.595 8'
                  transform='translate(8 5) translate(1 22.001)'
                />
              </g>
              <path
                stroke-linecap='round'
                d='M11.798 26.002L11.798 26.002'
                transform='translate(8 5)'
              />
              <g>
                <path
                  stroke-linecap='round'
                  d='M13.595 30L13.595 30'
                  transform='translate(8 5) translate(0 .001)'
                />
                <path
                  d='M19.918 17.998c2.81-.015 5.082-2.222 5.082-4.94 0-2.729-2.288-4.942-5.11-4.942-.166 0-.33.008-.493.024C18.392 5.714 15.941 4 13.074 4c-3.477 0-6.34 2.52-6.758 5.77-.019 0-.037-.006-.056-.006-2.353 0-4.26 1.845-4.26 4.118C2 16.157 3.907 18 6.26 18l13.658-.002z'
                  transform='translate(8 5) translate(0 .001)'
                />
                <path
                  d='M4.845 9.997C2.155 9.915 0 7.709 0 4.999c0-2.761 2.239-5 5-5 2.666 0 4.844 2.086 4.992 4.713'
                  transform='translate(8 5) translate(0 .001)'
                />
              </g>
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 21 ? (
          <svg
            id='pis-weather-21'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M7.863 14.52c-1.69-.801-2.859-2.525-2.859-4.52 0-2.762 2.238-5 5-5 2.041 0 3.796 1.224 4.574 2.977'
                transform='translate(6 5)'
              />
              <path
                stroke-linecap='round'
                d='M10.005 0L10.005 2.009M2.01 10.004L0 10.004M3.224 3.223L4.352 4.351M16.785 3.223L15.658 4.35M4.352 15.657L3.224 16.785'
                transform='translate(6 5)'
              />
              <path
                d='M24.01 10.993c-.978-2.348-3.295-3.998-5.998-3.998-3.44 0-6.247 2.672-6.48 6.052-.172-.024-.345-.054-.527-.054-2.21 0-4 1.792-4 4 0 2.21 1.79 4 4 4h13c2.76 0 4.999-2.24 4.999-5s-2.235-4.997-4.993-5z'
                transform='translate(6 5)'
              />
              <path
                stroke-linecap='round'
                d='M9.004 23.999L8.477 24.654M14.004 23.999L11.048 27.69M19.004 23.999L18.481 24.654M24.004 23.999L21.049 27.69M6.732 26.836L6.732 26.836M9.2 29.998L9.2 29.998M16.733 26.836L16.733 26.836M19.201 29.998L19.201 29.998'
                transform='translate(6 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 22 ? (
          <svg
            id='pis-weather-22'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g
              fill='none'
              fill-rule='evenodd'
              stroke='#FFF'
              stroke-linecap='round'
              stroke-width='2'
            >
              <path
                d='M10.7.701L14.999 5.001 19.299.701M10.7 29.3L14.999 25.001 19.299 29.3M.702 19.3L5.001 15.001.702 10.701M29.3 19.3L25.001 15.001 29.3 10.701M1.774 8.004L7.853 8.004 7.853 1.923M21.996 28.225L21.996 22.147 28.075 22.146M7.854 28.224L7.854 22.146 1.776 22.146M28.076 8.002L21.997 8.002 21.997 1.922M0 15L30 15M15 0L15 30M4.393 4.394L25.607 25.607M25.607 4.394L4.394 25.607'
                transform='translate(5 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 23 ? (
          <svg
            id='pis-weather-23'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                d='M5.999 21.999L5.999 21.998M9.998 24L9.998 24M15.999 22L15.999 21.999M20.998 23L20.998 23M3.998 26L3.998 25.999M10.998 28.002L10.998 28M14.998 26L14.998 26'
                transform='translate(8 5)'
              />
              <g>
                <path
                  stroke-linecap='round'
                  d='M19.998 28L19.998 27.999M5.999 29.999L5.999 29.999M15.999 30L15.999 29.999'
                  transform='translate(8 5) translate(0 .002)'
                />
                <path
                  d='M19.918 17.998c2.809-.017 5.081-2.222 5.081-4.941 0-2.728-2.288-4.942-5.11-4.942-.166 0-.33.009-.492.025-1.006-2.426-3.456-4.141-6.324-4.141-3.476 0-6.34 2.52-6.757 5.77-.019 0-.038-.006-.057-.006-2.353 0-4.26 1.844-4.26 4.118 0 2.275 1.907 4.118 4.26 4.118l13.659-.001z'
                  transform='translate(8 5) translate(0 .002)'
                />
                <path
                  d='M4.844 9.996C2.154 9.914 0 7.708 0 4.998c0-2.76 2.239-5 5-5 2.665 0 4.843 2.086 4.99 4.713'
                  transform='translate(8 5) translate(0 .002)'
                />
              </g>
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 24 ? (
          <svg
            id='pis-weather-24'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g
              fill='none'
              fill-rule='evenodd'
              stroke='#FFF'
              stroke-linecap='round'
              stroke-width='2'
            >
              <path
                d='M0 0h23.999c1.105 0 2 .896 2 2s-.895 2-2 2H4.002c-1.105 0-2 .896-2 2s.895 2 2 2H26c1.104 0 2 .896 2 2s-.896 2-2 2H10.005c-1.105 0-2 .896-2 2s.895 2 2 2l8.992.001c1.104 0 2 .895 2 2 0 1.104-.896 2-2 2l-9.993.001c-1.104 0-2 .894-2 2 0 1.103.896 2 2 2l3.99.001c1.104 0 2 .895 2 2 0 1.104-.896 2-2 2'
                transform='translate(6 5)'
              />
              <path
                stroke-linejoin='round'
                d='M11.006 30h0c0-1.104.896-2 2-2'
                transform='translate(6 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 25 ? (
          <svg
            id='pis-weather-25'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M23.5 5.008c-.084 0-.165.009-.248.013C21.92 2.063 18.955 0 15.502 0c-4.267 0-7.788 3.147-8.397 7.244-.507-.155-1.045-.24-1.604-.24C2.464 7.005 0 9.469 0 12.505c0 3.038 2.464 5.5 5.5 5.5h17.942c.02 0 .038.004.058.004 3.59 0 6.5-2.91 6.5-6.501 0-3.59-2.91-6.5-6.5-6.5z'
                transform='translate(5 5)'
              />
              <path
                stroke-linecap='round'
                d='M4.999 22L3.733 23.582M10.999 22L6.46 27.669M17 22L15.771 23.536M23 22L18.461 27.669M1.796 26.001L1.796 26.001M4.594 29.999L4.594 29.999M13.797 26.001L13.797 26.001M16.595 29.999L16.595 29.999'
                transform='translate(5 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 26 ? (
          <svg
            id='pis-weather-26'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M21.934 4.728c-.078 0-.154.009-.231.011C20.46 1.947 17.692 0 14.47 0 10.487 0 7.2 2.97 6.632 6.84c-.474-.147-.976-.226-1.498-.226C2.299 6.614 0 8.939 0 11.806S2.299 17 5.134 17H21.934C25.284 17 28 14.254 28 10.865c0-3.39-2.715-6.137-6.066-6.137z'
                transform='translate(6 6)'
              />
              <path
                stroke-linecap='round'
                d='M7.001 20.998L7.001 22.998M8 21.998L6.001 21.998M15.001 21.998L13.001 21.998M14.002 22.998L14.001 20.998M11.708 26.999L9.707 26.999M10.708 27.998L10.707 25.998M17.293 25.999L17.293 27.998M18.293 26.998L16.293 26.999M21.001 20.998L21.001 22.998M22 21.997L20.001 21.998'
                transform='translate(6 6)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 29 ? (
          <svg
            id='pis-weather-29'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                d='M6.5 22L3.298 26M12.5 22L6.096 29.999M18.5 23.054L16.5 23.054M17.501 24.054L17.5 22.054'
                transform='translate(5 5)'
              />
              <g stroke-linecap='round'>
                <path
                  d='M2.207 1.057L.206 1.057M1.207 2.056L1.206.056M7.792.057L7.792 2.057M8.792 1.056L6.792 1.056'
                  transform='translate(5 5) translate(13 26.999)'
                />
              </g>
              <path
                stroke-linecap='round'
                d='M24.5 22.054L24.5 24.054M25.499 23.053L23.5 23.054'
                transform='translate(5 5)'
              />
              <path
                d='M23.499 5.007c-.084 0-.165.01-.248.013C21.92 2.063 18.954 0 15.502 0c-4.268 0-7.789 3.147-8.398 7.243-.507-.154-1.045-.239-1.604-.239-3.037 0-5.5 2.463-5.5 5.5 0 3.038 2.463 5.5 5.5 5.5h17.941c.02 0 .038.003.058.003 3.59 0 6.5-2.91 6.5-6.5s-2.91-6.5-6.5-6.5z'
                transform='translate(5 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 30 ? (
          <svg
            id='pis-weather-30'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                d='M7 3.999L7 19.998'
                transform='translate(13 5)'
              />
              <g>
                <path
                  d='M11 17.26V4c0-2.209-1.791-4-4-4S3 1.791 3 4v13.26C1.188 18.524 0 20.62 0 23c0 3.866 3.134 7 7 7s7-3.134 7-7c0-2.379-1.188-4.475-3-5.74z'
                  transform='translate(13 5)'
                />
                <path
                  d='M10 23c0 1.655-1.343 3-3 3s-3-1.345-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z'
                  transform='translate(13 5)'
                />
              </g>
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 31 ? (
          <svg
            id='pis-weather-31'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                d='M6.999 16.004L6.999 19.998'
                transform='translate(13 5)'
              />
              <g>
                <path
                  d='M11 17.26V4c0-2.209-1.791-4-4-4-2.21 0-4 1.791-4 4v13.26C1.187 18.524 0 20.62 0 23c0 3.866 3.134 7 7 7s7-3.134 7-7c0-2.379-1.188-4.475-3-5.74z'
                  transform='translate(13 5)'
                />
                <path
                  d='M10 23c0 1.655-1.344 3-3 3-1.657 0-3-1.345-3-3 0-1.657 1.343-3 3-3 1.656 0 3 1.343 3 3z'
                  transform='translate(13 5)'
                />
              </g>
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 32 ? (
          <svg
            id='pis-weather-32'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g
              fill='none'
              fill-rule='evenodd'
              stroke='#FFF'
              stroke-linecap='round'
              stroke-width='2'
            >
              <path
                d='M26.998 9.997c1.104 0 2 .896 2 2s-.896 2-2 2h-3.995M27.999 21.998c1.104 0 2-.895 2-2 0-1.104-.896-2-2-2h-6.998'
                transform='translate(5 8)'
              />
              <path
                d='M27.002 6.031c-1.01-.648-2.213-1.024-3.503-1.024-.084 0-.165.008-.248.012C21.921 2.062 18.954 0 15.502 0c-4.268 0-7.789 3.145-8.398 7.243-.508-.154-1.046-.239-1.604-.239-3.038 0-5.5 2.463-5.5 5.5 0 3.038 2.462 5.5 5.5 5.5L16.001 18c1.657 0 3 1.343 3 3s-1.343 3-3 3'
                transform='translate(5 8)'
              />
              <path
                d='M11.003 13.995h7c1.656 0 3-1.343 3-3 0-1.656-1.344-3-3-3M8 14L8.001 14'
                transform='translate(5 8)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 33 ? (
          <svg
            id='pis-weather-33'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <path
              fill='none'
              fill-rule='evenodd'
              stroke='#FFF'
              stroke-linecap='round'
              stroke-width='2'
              d='M24.983 20c0-5.131 2.579-9.655 6.509-12.359C29.076 5.978 26.153 5 23 5 14.715 5 8 11.716 8 20c0 8.284 6.715 15 15 15 3.153 0 6.076-.979 8.492-2.641-3.93-2.704-6.509-7.229-6.509-12.359z'
            />
          </svg>
        ) : null}
        {this.weatherIconNumber === 34 ? (
          <svg
            id='pis-weather-34'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M29.999 16.998c0-3.313-2.686-6-5.999-6-.194 0-.387.011-.577.03-1.181-2.947-4.057-5.03-7.423-5.03-4.081 0-7.442 3.06-7.933 7.007-.022 0-.044-.007-.066-.007-2.762 0-5.001 2.24-5.001 5 0 2.762 2.239 5 5.001 5h16.034c3.297-.02 5.964-2.698 5.964-6z'
                transform='translate(5 9)'
              />
              <path
                d='M13.963 6.284C13.606 2.754 10.625 0 7 0c-.14 0-.274.013-.411.02.263.608.41 1.276.41 1.98 0 2.762-2.237 5-5 5-.703 0-1.371-.149-1.978-.41C.013 6.726 0 6.861 0 7c0 3.28 2.256 6.033 5.3 6.792'
                transform='translate(5 9)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 35 ? (
          <svg
            id='pis-weather-35'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M29.999 16.998c0-3.313-2.686-6-5.999-6-.194 0-.387.011-.577.03-1.181-2.947-4.057-5.03-7.423-5.03-4.081 0-7.442 3.06-7.933 7.007-.022 0-.044-.007-.066-.007-2.762 0-5.001 2.24-5.001 5 0 2.762 2.239 5 5.001 5h16.034c3.297-.02 5.964-2.698 5.964-6z'
                transform='translate(5 9)'
              />
              <path
                d='M13.963 6.284C13.606 2.754 10.625 0 7 0c-.14 0-.274.013-.411.02.263.608.41 1.276.41 1.98 0 2.762-2.237 5-5 5-.703 0-1.371-.149-1.978-.41C.013 6.726 0 6.861 0 7c0 3.28 2.256 6.033 5.3 6.792'
                transform='translate(5 9)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 36 ? (
          <svg
            id='pis-weather-36'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M28.749 14.128c.513-1.208.797-2.537.797-3.932 0-5.216-3.97-9.506-9.053-10.014.637 1.184 1 2.537 1 3.975 0 4.633-3.755 8.388-8.388 8.388-1.274 0-2.478-.292-3.56-.8.746 4.821 4.904 8.518 9.935 8.518'
                transform='translate(1 7)'
              />
              <path
                d='M37.128 21.645c0-2.248-1.82-4.07-4.069-4.07-.132 0-.262.008-.39.02-.802-1.998-2.753-3.411-5.036-3.411-2.768 0-5.048 2.074-5.38 4.753-.016-.001-.03-.005-.046-.005-1.873 0-3.392 1.519-3.392 3.39 0 1.875 1.52 3.393 3.392 3.393h10.876v-.001c2.236-.013 4.045-1.83 4.045-4.07zM10.502 22.424c0-1.289-1.045-2.333-2.333-2.333-.076 0-.151.004-.225.011-.46-1.146-1.578-1.956-2.887-1.956-1.588 0-2.895 1.19-3.086 2.726-.01-.001-.016-.003-.026-.003C.872 20.869 0 21.74 0 22.814s.872 1.944 1.945 1.944h6.237c1.282-.008 2.32-1.049 2.32-2.334z'
                transform='translate(1 7)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 37 ? (
          <svg
            id='pis-weather-37'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M20.756 18.476c2.622-1.817 4.338-4.848 4.338-8.28 0-5.216-3.969-9.506-9.053-10.013.638 1.183 1.001 2.536 1.001 3.974 0 4.633-3.756 8.388-8.388 8.388-1.274 0-2.478-.292-3.56-.8.433 2.795 2.012 5.212 4.243 6.75'
                transform='translate(5 6)'
              />
              <path
                stroke-linecap='round'
                d='M3.002 18.572L26.998 18.572M0 22.573L29.999 22.573M8.004 26.573L22.996 26.573'
                transform='translate(5 6)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 38 ? (
          <svg
            id='pis-weather-38'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M30.742 21.998c0-3.313-2.686-6-6-6-.193 0-.386.011-.576.03-1.181-2.946-4.057-5.03-7.423-5.03-4.081 0-7.442 3.06-7.933 7.008-.022-.002-.044-.008-.066-.008-2.762 0-5.001 2.24-5.001 5 0 2.762 2.239 5 5 5h16.035c3.297-.02 5.964-2.698 5.964-6z'
                transform='translate(5 6)'
              />
              <path
                d='M14.706 11.284C14.349 7.754 11.368 5 7.743 5c-.14 0-.274.013-.411.02.263.608.41 1.276.41 1.98 0 2.762-2.237 5-5 5-.703 0-1.371-.149-1.978-.41-.008.136-.021.271-.021.41 0 3.28 2.256 6.033 5.3 6.792M27.501 4.278c0-1.289-1.045-2.333-2.333-2.333-.076 0-.151.004-.225.011C24.484.81 23.365 0 22.056 0c-1.588 0-2.894 1.19-3.086 2.726-.009-.001-.016-.004-.026-.004-1.073 0-1.944.871-1.944 1.946 0 1.074.871 1.944 1.944 1.944l6.237-.001c1.282-.007 2.32-1.048 2.32-2.333z'
                transform='translate(5 6)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 39 ? (
          <svg
            id='pis-weather-39'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M19.918 17.999c2.81-.015 5.082-2.221 5.082-4.94 0-2.728-2.288-4.942-5.11-4.942-.166 0-.33.009-.493.024C18.392 5.715 15.941 4 13.074 4c-3.477 0-6.34 2.52-6.758 5.77-.019 0-.037-.006-.056-.006C3.907 9.764 2 11.61 2 13.883 2 16.157 3.907 18 6.26 18l13.658-.001z'
                transform='translate(8 5)'
              />
              <path
                stroke-linecap='round'
                d='M18.998 22.002L18.998 30M14.999 22.002L14.999 27M10.999 22.002L10.999 30M7 22.002L7 27'
                transform='translate(8 5)'
              />
              <path
                d='M9.992 4.713C9.844 2.086 7.666 0 5 0c-.128 0-.248.028-.374.038.202.442.32.928.32 1.446 0 1.932-1.566 3.499-3.5 3.499-.504 0-.98-.111-1.414-.303C.025 4.788 0 4.89 0 5c0 2.71 2.155 4.915 4.845 4.998'
                transform='translate(8 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 40 ? (
          <svg
            id='pis-weather-40'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <g stroke-linecap='round'>
                <path
                  d='M4.102.002L.9 4.002M9.102.002L2.698 8.001'
                  transform='translate(8 5) translate(1 22)'
                />
              </g>
              <path
                stroke-linecap='round'
                d='M15.102 22.001L11.9 26.001'
                transform='translate(8 5)'
              />
              <g>
                <path
                  stroke-linecap='round'
                  d='M20.102 22.002L13.698 30'
                  transform='translate(8 5)'
                />
                <path
                  d='M19.918 18c2.81-.016 5.082-2.222 5.082-4.941 0-2.728-2.288-4.942-5.11-4.942-.166 0-.33.009-.493.025-1.005-2.426-3.456-4.141-6.323-4.141-3.477 0-6.34 2.519-6.758 5.77-.019 0-.037-.006-.056-.006C3.907 9.765 2 11.61 2 13.883c0 2.275 1.907 4.118 4.26 4.118L19.918 18z'
                  transform='translate(8 5)'
                />
                <path
                  d='M9.992 4.713C9.844 2.086 7.666 0 5 0c-.128 0-.248.03-.374.038.202.442.32.928.32 1.446 0 1.932-1.566 3.5-3.5 3.5-.504 0-.98-.112-1.414-.304C.025 4.788 0 4.891 0 5c0 2.71 2.155 4.916 4.845 4.998'
                  transform='translate(8 5)'
                />
              </g>
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 41 ? (
          <svg
            id='pis-weather-41'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                d='M16.398 15.75L11.399 22 15.398 22 10.617 27.971'
                transform='translate(7 5)'
              />
              <path
                stroke-linecap='round'
                d='M19.41 17.99l1.917-.002c2.81-.016 5.081-2.22 5.081-4.94 0-2.728-2.288-4.942-5.11-4.942-.166 0-.33.01-.493.025C19.8 5.704 17.35 3.99 14.482 3.99c-3.476 0-6.34 2.52-6.757 5.771-.019 0-.038-.007-.057-.007-2.353 0-4.26 1.845-4.26 4.12 0 2.273 1.907 4.116 4.26 4.116h2.74M1.403 28.237L0 29.989M6.404 21.991L3.456 25.673M19.393 25.759L16.006 29.989M22.408 21.99L21.608 22.99'
                transform='translate(7 5)'
              />
              <path
                d='M11.4 4.713C11.252 2.086 9.074 0 6.41 0c-.13 0-.25.029-.375.038.201.442.32.928.32 1.446 0 1.932-1.567 3.499-3.5 3.499-.505 0-.98-.111-1.414-.303-.007.108-.032.21-.032.32 0 2.71 2.154 4.916 4.844 4.998'
                transform='translate(7 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 42 ? (
          <svg
            id='pis-weather-42'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                d='M23.391 15.75L18.392 22 22.391 22 17.61 27.971'
                transform='translate(3 5)'
              />
              <path
                stroke-linecap='round'
                d='M26.403 17.99l1.918-.002c2.81-.016 5.081-2.22 5.081-4.94 0-2.728-2.288-4.942-5.11-4.942-.166 0-.33.01-.493.025-1.005-2.427-3.455-4.142-6.323-4.142-3.476 0-6.34 2.52-6.757 5.771-.019 0-.038-.007-.057-.007-2.353 0-4.26 1.845-4.26 4.12 0 2.273 1.907 4.116 4.26 4.116h2.74M8.397 28.237L6.994 29.989M13.398 21.991L10.45 25.673M26.387 25.759L23 29.989M29.402 21.99L28.602 22.99'
                transform='translate(3 5)'
              />
              <path
                d='M18.394 4.713C18.246 2.086 16.068 0 13.404 0c-.13 0-.25.029-.375.038.201.442.32.928.32 1.446 0 1.932-1.567 3.499-3.5 3.499-.505 0-.98-.111-1.414-.303-.007.108-.032.211-.032.32 0 2.71 2.154 4.916 4.844 4.998M10.502 14.165c0-1.29-1.045-2.334-2.333-2.334-.076 0-.151.004-.225.012-.46-1.146-1.578-1.956-2.887-1.956-1.588 0-2.895 1.189-3.086 2.725-.01 0-.016-.003-.026-.003-1.073 0-1.945.87-1.945 1.945 0 1.074.872 1.945 1.945 1.945l6.237-.001c1.282-.007 2.32-1.05 2.32-2.333z'
                transform='translate(3 5)'
              />
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 43 ? (
          <svg
            id='pis-weather-43'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                stroke-linecap='round'
                d='M4.996 22.002L3.729 23.583M9.995 22.002L5.456 27.671M14.996 22.002L13.767 23.539'
                transform='translate(8 5)'
              />
              <g stroke-linecap='round'>
                <path
                  d='M18.995.001L14.456 5.67M.794 4.001L.794 4.001M2.591 8L2.591 8'
                  transform='translate(8 5) translate(1 22.002)'
                />
              </g>
              <path
                stroke-linecap='round'
                d='M11.794 26.003L11.794 26.003'
                transform='translate(8 5)'
              />
              <g>
                <path
                  stroke-linecap='round'
                  d='M13.591 30L13.591 30'
                  transform='translate(8 5) translate(0 .002)'
                />
                <path
                  d='M19.914 17.998c2.81-.015 5.082-2.222 5.082-4.94 0-2.729-2.288-4.942-5.11-4.942-.166 0-.33.008-.493.024C18.388 5.714 15.937 4 13.07 4 9.593 4 6.73 6.518 6.312 9.77c-.019 0-.037-.006-.056-.006-2.353 0-4.26 1.845-4.26 4.118 0 2.275 1.907 4.118 4.26 4.118l13.658-.002z'
                  transform='translate(8 5) translate(0 .002)'
                />
                <path
                  d='M9.992 4.712C9.844 2.084 7.666-.002 5-.002c-.128 0-.248.03-.374.038.202.442.32.93.32 1.446 0 1.932-1.566 3.5-3.5 3.5-.504 0-.98-.11-1.414-.303C.025 4.787 0 4.89 0 5c0 2.71 2.155 4.915 4.845 4.998'
                  transform='translate(8 5) translate(0 .002)'
                />
              </g>
            </g>
          </svg>
        ) : null}
        {this.weatherIconNumber === 44 ? (
          <svg
            id='pis-weather-44'
            xmlns='http://www.w3.org/2000/svg'
            width='40'
            height='40'
            viewBox='0 0 40 40'
          >
            <g fill='none' fill-rule='evenodd' stroke='#FFF' stroke-width='2'>
              <path
                d='M19.918 18C22.727 17.984 25 15.778 25 13.059c0-2.728-2.289-4.942-5.111-4.942-.166 0-.33.009-.492.025-1.006-2.426-3.456-4.141-6.323-4.141-3.477 0-6.34 2.52-6.758 5.77-.019 0-.037-.006-.057-.006C3.907 9.765 2 11.61 2 13.883c0 2.275 1.907 4.118 4.259 4.118L19.918 18z'
                transform='translate(8 6)'
              />
              <path
                stroke-linecap='round'
                d='M6 22.508c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zM10 26.51c0 .275-.224.5-.5.5s-.5-.225-.5-.5c0-.277.224-.5.5-.5s.5.223.5.5zM14 22.508c0 .276-.223.5-.5.5-.275 0-.5-.224-.5-.5s.225-.5.5-.5c.277 0 .5.224.5.5zM22 22.508c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zM18 26.51c0 .275-.224.5-.5.5s-.5-.225-.5-.5c0-.277.224-.5.5-.5s.5.223.5.5z'
                transform='translate(8 6)'
              />
              <path
                d='M9.992 4.714C9.844 2.086 7.666 0 5 0c-.128 0-.248.029-.374.038.202.442.32.929.32 1.446 0 1.933-1.566 3.5-3.5 3.5-.504 0-.98-.111-1.413-.303C.026 4.789 0 4.89 0 5c0 2.71 2.155 4.916 4.845 4.999'
                transform='translate(8 6)'
              />
            </g>
          </svg>
        ) : null}
      </div>
    )
  }
}

import Axios, { AxiosResponse } from 'axios'
import getDomain from '@/helpers/getDomain'

export class WeatherService {
  private domain: string = getDomain()

  public async getWeather(tiploc: string, flavour: string): Promise<any> {
    try {
      const number = await this.getTiplocNumberMap(tiploc, flavour)
      const weatherResponse: AxiosResponse = await Axios.get(
        `${this.domain}/weather/currentconditions/v1/${number}`,
      )
      if (weatherResponse.status === 200) {
        return weatherResponse.data[0]
      } else {
        return weatherResponse
      }
    } catch (error) {
      return error
    }
  }
  private async getTiplocNumberMap(
    tiploc: string,
    flavour: string,
  ): Promise<any> {
    try {
      const mapResponse: AxiosResponse = await Axios.get(
        `${this.domain}/portal/${flavour}.weather.json`,
      )
      if (mapResponse.status === 200) {
        return mapResponse.data[tiploc]
      } else {
        return mapResponse
      }
    } catch (error) {
      return error
    }
  }
}

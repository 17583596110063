import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { ICoachProps } from '../coach'

@Component
export default class CoachHead extends tsx.Component<ICoachProps> {
  @Prop() isSbb: boolean
  @Prop() isSpa: boolean
  @Prop() isCpa: boolean

  get isDefault(): boolean {
    return !this.isSbb && !this.isSpa && !this.isCpa
  }

  render(h) {
    return (
      <div>
        {this.isSbb || this.isDefault ? (
          <svg
            id='pis-coach-head'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='100'
            viewBox='0 0 24 100'
          >
            <defs>
              <path
                id='pis-coach-head-a'
                d='M8.851 1.207C9.72.455 10.851 0 12.09 0c1.201 0 2.301.431 3.155 1.147 1.514.326 2.683 1.35 3.756 4.474 2.788 8.117 4.025 22.153 4.572 34.618.268.34.428.77.428 1.237V97a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V41.476c0-.432.137-.831.37-1.158C.88 27.788 2.097 13.683 5 5.62 6.076 2.63 7.303 1.577 8.851 1.207z'
              />
            </defs>
            <g fill='none' fill-rule='evenodd'>
              <mask id='pis-coach-head-b' fill='#fff'>
                <use xlinkHref='#pis-coach-head-a' />
              </mask>
              <use fill='#F2F2F2' xlinkHref='#pis-coach-head-a' />
              <path
                fill='#EB0000'
                d='M4 24h16v74H4z'
                mask='url(#pis-coach-head-b)'
              />
              <path
                fill='#EB0000'
                d='M12 29.97c5 0 8 1.18 8-11.219C20 6.351 16.418-9 12-9S4 5.17 4 18.751c0 12.4 3 11.219 8 11.219z'
                mask='url(#pis-coach-head-b)'
              />
              <path
                fill='#1E1E1E'
                d='M16.96 19.604c.027.128.04.26.04.396v2a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-2c0-.137.014-.272.04-.401a5.018 5.018 0 0 1-.031-.616l.064-6.046A4.99 4.99 0 0 1 12.063 8 4.937 4.937 0 0 1 17 12.937v6.036c0 .214-.013.424-.04.63z'
                mask='url(#pis-coach-head-b)'
              />
              <path
                fill='#696969'
                d='M14.966 16.18c.022.104.034.21.034.32V19a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-2.5c0-.11.012-.216.034-.32A6.766 6.766 0 0 1 9 15.5c0-2.485 1.343-4.5 3-4.5s3 2.015 3 4.5c0 .231-.012.459-.034.68z'
                mask='url(#pis-coach-head-b)'
              />
              <rect
                width='8'
                height='62'
                x='8'
                y='32'
                fill='#1E1E1E'
                mask='url(#pis-coach-head-b)'
                rx='2'
              />
              <path
                stroke='#000'
                stroke-opacity='.5'
                d='M9.182 1.582l-.093.08-.12.029c-1.526.36-2.554 1.453-3.5 4.053C2.946 12.688 1.5 24.633.87 39.946l-.007.149-.086.12a1.49 1.49 0 0 0-.276.868V96A1.5 1.5 0 0 0 2 97.5h20a1.5 1.5 0 0 0 1.5-1.5V41.083c0-.341-.114-.664-.32-.927l-.1-.126-.007-.16C22.4 24.7 20.959 12.743 18.528 5.736c-.943-2.717-1.914-3.789-3.39-4.103l-.122-.026-.096-.08A4.39 4.39 0 0 0 12.09.5a4.426 4.426 0 0 0-2.908 1.082z'
                mask='url(#pis-coach-head-b)'
              />
            </g>
          </svg>
        ) : null}
        {this.isSpa ? (
          <svg
            id='pis-coach-head'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='99'
            viewBox='0 0 24 99'
          >
            <g fill='none' fill-rule='evenodd'>
              <path
                fill='#1FB1D2'
                d='M12 0c6.091 0 11.123 10.322 11.897 23.694.067.2.103.413.103.635V96a2 2 0 01-2 2H2a2 2 0 01-2-2V24.33c0-.223.036-.436.103-.636C.877 10.322 5.909 0 12 0z'
              />
              <path
                stroke='#EBEBEB'
                stroke-linecap='round'
                stroke-width='3'
                d='M6 13.814c3-3.454 10-3.454 12 0'
              />
              <path
                fill='#000'
                d='M13 17a6.001 6.001 0 015.94 5.142H19V98H5V22.143h.06A6.001 6.001 0 0111 17h2z'
              />
            </g>
          </svg>
        ) : null}
        {this.isCpa ? (
          <svg
            id='pis-coach-head'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='99'
            viewBox='0 0 24 99'
          >
            <g fill='none' fill-rule='evenodd'>
              <path
                fill='#002C66'
                d='M12 0c6.091 0 11.123 10.322 11.897 23.694.067.2.103.413.103.635V96a2 2 0 01-2 2H2a2 2 0 01-2-2V24.33c0-.223.036-.436.103-.636C.877 10.322 5.909 0 12 0z'
              />
              <path
                stroke='#EBEBEB'
                stroke-linecap='round'
                stroke-width='3'
                d='M6 13.814c3-3.454 10-3.454 12 0'
              />
              <path
                fill='#D8D8D8'
                d='M13 17a6.001 6.001 0 015.94 5.142H19V98H5V22.143h.06A6.001 6.001 0 0111 17h2z'
                opacity='.318'
              />
            </g>
          </svg>
        ) : null}
      </div>
    )
  }
}

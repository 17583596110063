


















import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import Spinner from 'vue-simple-spinner'

import {
  IFacility,
  IPISContent,
  IStationInformationOption,
} from '@/constants/interfaces'
import FacilityItem from './facilityItem/index.vue'
import { store } from '@/store'
import { generateFacilities } from '@/helpers/stationInfo'
import { getNameI18n } from '@/helpers/item'
import { GetJourneyStationsResponse, Tiploc } from '@gomedia-apis-ts-pis/v1'
import { getStationInfo } from '@/helpers/item'

interface IFacilitiesListEvents {
  onChangeStationName?: string
}

@Component({ components: { FacilityItem, Spinner } })
export default class FacilitiesList extends tsx.Component<IFacilitiesListEvents> {
  @Prop() public options: IStationInformationOption

  public facilities: IFacility[] | null = null
  public inProgress = false
  public isError = false

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get visibleFacilities(): IFacility[] {
    if (!this.facilities) {
      return []
    }
    return this.facilities.filter((item) => item.isVisible)
  }

  public get hasFacilitiesInfo(): boolean {
    return Boolean(this.visibleFacilities.length) && !this.isError
  }

  public get language(): string {
    return store.getters.getLanguage
  }

  public pushTitleToPortal(stationInfo: Tiploc) {
    const stationTitle = getNameI18n(stationInfo.name, this.language)
    this.$emit('change-station-name', stationTitle)
  }

  public async requestStationDepartures(): Promise<void> {
    this.inProgress = true
    try {
      const journeyStations: GetJourneyStationsResponse =
        store.state.journeyStations ||
        (await store.dispatch('fetchJourneyStations', this.options.tiploc))
      if (journeyStations && journeyStations.items) {
        const stationInfo = getStationInfo(journeyStations, this.options.tiploc)
        this.pushTitleToPortal(stationInfo)
        this.facilities = generateFacilities(
          this.content,
          stationInfo,
          this.options.alert,
          this.language,
          this.options.flavour,
        )
      }
    } catch (e) {
      this.isError = true
    } finally {
      this.inProgress = false
    }
  }

  public created(): void {
    this.requestStationDepartures()
  }
}

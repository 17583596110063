import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

export interface ICoachProps {
  isSbb?: boolean
  isSpa?: boolean
  isCpa?: boolean
}

@Component
export default class Coach extends tsx.Component<ICoachProps> {
  @Prop() isSbb: boolean
  @Prop() isSpa: boolean
  @Prop() isCpa: boolean

  get isDefault(): boolean {
    return !this.isSbb && !this.isSpa && !this.isCpa
  }

  render(h) {
    return (
      <div>
        {this.isSbb || this.isDefault ? (
          <svg
            id='pis-coach'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='100'
            viewBox='0 0 24 100'
          >
            <g fill='none' fill-rule='evenodd'>
              <rect width='24' height='98' fill='#F2F2F2' rx='2' />
              <path fill='#EB0000' d='M4 0h16v98H4z' />
              <rect width='8' height='90' x='8' y='4' fill='#1E1E1E' rx='2' />
              <rect
                width='23'
                height='97'
                x='.5'
                y='.5'
                stroke='#000'
                stroke-opacity='.5'
                rx='2'
              />
            </g>
          </svg>
        ) : null}
        {this.isSpa ? (
          <svg
            id='pis-coach'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='99'
            viewBox='0 0 24 99'
          >
            <g fill='none' fill-rule='evenodd'>
              <rect width='24' height='98' fill='#1FB1D2' rx='2' />
              <path fill='#000' d='M19 0L19 97.849 5 97.849 5 0z' />
            </g>
          </svg>
        ) : null}
        {this.isCpa ? (
          <svg
            id='pis-coach'
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='99'
            viewBox='0 0 24 99'
          >
            <g fill='none' fill-rule='evenodd'>
              <rect width='24' height='98' fill='#002C66' rx='2' />
              <path
                fill='#D8D8D8'
                d='M19 0L19 97.849 5 97.849 5 0z'
                opacity='.318'
              />
            </g>
          </svg>
        ) : null}
      </div>
    )
  }
}

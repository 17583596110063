


















import * as tsx from 'vue-tsx-support'
import { Component, Prop } from 'vue-property-decorator'
import { store } from '@/store'

import { IFacility } from '@/constants/interfaces'
import Arrow from '@/components/svgs/common/arrow/index.vue'

interface IFacilityItemProps {
  data: IFacility
}

@Component({ components: { Arrow } })
export default class FacilityItem extends tsx.Component<IFacilityItemProps> {
  @Prop() public data: IFacility

  public goTo = () => {
    store.dispatch('pushRouter', {
      link: encodeURI(this.data.url),
      target: this.data.href || '_self',
    })
  }

  public onItemClick(event: any) {
    event.target.blur()
    this.goTo()
  }
}

import { ITheme } from './theme'

export const GV_THEME: ITheme = {
  footer: {
    backgroundColor: 'white',
  },
  pisJourneyListBg: 'white',
  pisSharedHeaderBg: '#2e3947',
  pisHeaderHeight: '5.8rem',
  pisBorderC: '#e0e0e0',
  pisEmptyListC: '#19212b',
  pisArrowC: '#2e3947',
  pisTrain: {
    color: '#0aa5f2',
  },
  pisJourneyListStationC: '#2e3947',
}

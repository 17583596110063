import {
  DisplayCallingPoint,
  Journey,
  JourneyPhase,
} from '@gomedia-apis-ts-pis/v1'

const nextStationPhaseList = [JourneyPhase.CURRENT, JourneyPhase.UPCOMING]
/**
 * Returns a current train position index
 */
export const getTrainPositionIndex = (data: Journey): number => {
  const currentStationIndex = data.calling_points.findIndex(
    (point: DisplayCallingPoint) =>
      nextStationPhaseList.includes(point.journey_phase),
  )

  if (currentStationIndex !== -1) {
    return currentStationIndex
  }
  return data.calling_points.length - 1
}




















import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class Arrow extends tsx.Component<{}> {}

import { Flavour } from '@/enums'
import { mergeDeep } from '@/helpers/mergeDeep'
import { setCustomPropertiesOnElement } from '@/helpers/themeProperties'
import {
  ColorProperty,
  BackgroundProperty,
  FontWeightProperty,
  SizeProperty,
  TextAlignProperty,
  Percent,
  DeepPartial,
} from '@/themes/utils/types'
import { AVT_THEME } from './theme.avt'
import { CPA_THEME } from './theme.cpa'
import { DEFAULT_THEME } from './theme.default'
import { ECTL_THEME } from './theme.ectl'
import { FLIX_THEME } from './theme.flix'
import { GV_THEME } from './theme.gv'
import { QR_THEME } from './theme.qr'
import { SBB_THEME } from './theme.sbb'
import { SPA_THEME } from './theme.spa'
import { TFW_THEME } from './theme.tfw'
import { TPE_THEME } from './theme.tpe'

export interface IFontFace {
  fontFamily: string
  src: string
  fontWeight: string
  fontStyle: string
}

export interface ITypography {
  globalFont: string
  assets: IFontFace[]
}

interface IResponsive<T> {
  desktop?: T
  tablet?: T
  mobileL?: T
  mobileS?: T
}

export const BASE_TYPOGRAPHY: ITypography = {
  globalFont: 'GothamBook',
  assets: [
    {
      fontFamily: 'GothamBook',
      src:
        "local('Gotham Book Regular'), local('Gotham-Book-Regular'), url('/fonts/cpa/Gotham_book_regular.otf') format('opentype')",
      fontWeight: '400',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'GothamBook',
      src:
        "local('GothamBold Regular'), local('Gotham-Bold-Regular'), url('/fonts/cpa/Gotham_bold_regular.otf') format('opentype')",
      fontWeight: '500',
      fontStyle: 'normal',
    },
  ],
}

export interface IFullTheme {
  feedbackButton: {
    height: SizeProperty
    backgroundColor: ColorProperty
    color: ColorProperty
    padding: SizeProperty
    fontSize: SizeProperty
    fontWeight: FontWeightProperty
    borderRadius: SizeProperty
    borderColor: string
    borderWidth: string
  }
  feedbackForm: {
    padding: SizeProperty
    fontSize: SizeProperty
    maxWidth: SizeProperty
    alignItems: string
    title: {
      color: ColorProperty
      fontWeight: FontWeightProperty
      marginBottom: SizeProperty
      fontSize: SizeProperty
    }
    textArea: {
      color: ColorProperty
      borderColor: ColorProperty
      focusedBorderColor: ColorProperty
      borderRadius: SizeProperty
      padding: SizeProperty
      fontSize: SizeProperty
    }
    button: {
      backgroundColor: ColorProperty
      disabledBackgroundColor: ColorProperty
      disabledColor: ColorProperty
      borderColor: ColorProperty
      disabledBorderColor: ColorProperty
      borderRadius: SizeProperty
      color: ColorProperty
    }
    before: {
      content: string
      marginRight: SizeProperty
    }
    form: {
      maxWidth: SizeProperty
    }
  }
  footer: {
    height: SizeProperty
    padding: SizeProperty
    bottom: SizeProperty
    backgroundColor: ColorProperty
    logosContainer: {
      padding: SizeProperty
    }
  }
  alertIcon: {
    backgroundColor: ColorProperty
    exclamationMarkColor: ColorProperty
  }
  disruptionMessage: {
    color: string
    fontWeight: FontWeightProperty
    arrow: {
      color: string
    }
  }
  copyIcon: {
    mainColor: ColorProperty
    stripColor: ColorProperty
  }
  facilityItem: {
    backgroundColor: string
  }
  shareBodyBg: ColorProperty
  pisContainerBg: string
  pisLibBodyBg: string
  stationPadding: SizeProperty // $station-padding
  pisJourneyListBottomPadding: SizeProperty
  pisJourneyListBg: BackgroundProperty // $pis-journey-list-bg
  pisJourneyListStationFontWeight: FontWeightProperty // $pis-journey-list-station-font-weight
  pisJourneyListItemTimeFontWeight: FontWeightProperty // $pis-journey-list-time-font-weight
  pisDelayC: ColorProperty // $pis-delay-c
  pisDelayBg: ColorProperty
  pisDelayPadding: string
  pisDelayFontWeight: FontWeightProperty
  pisJourneyListMaxWidth: SizeProperty // pis-journey-list-max-width
  pisJourneyListInfoFontWeight: FontWeightProperty // $pis-journey-list-info-font-weight
  pisJourneyListInfoMarginTop: SizeProperty
  pisJourneyListLineWidth: SizeProperty // $pis-journey-list-line-width
  pisJourneyListLineBg: ColorProperty // $pis-journey-list-line-bg
  pisJourneyListPointFirstLastBorderWidth: SizeProperty
  pisJourneyListLineDisabledBg: ColorProperty // $pis-journey-list-line-disabled-bg
  pisJourneyListPointBg: BackgroundProperty // $pis-journey-list-point-bg
  pisJourneyListPointDisabledBg: ColorProperty // $pis-journey-list-point-disabled-bg
  pisJourneyListPointFirstLastSize: SizeProperty // $pis-journey-list-point-first-last-width
  pisJourneyListPointFirstLastBg: string
  pisJourneyListPointCurrentUpcomingTop: SizeProperty // $pis-journey-list-point-current-upcomming-top
  pisJourneyListPointLastCurrentUpcomingTop: SizeProperty // $pis-journey-list-point-last-current-upcomming-top
  pisTrainPulseAnimationLightPercent: Percent
  pisTrainW: SizeProperty // $pis-train-w
  pisTrainBottom: SizeProperty // $pis-train-bottom
  pisTrainLeft: SizeProperty // $pis-train-left
  pisSplitBg: BackgroundProperty // $pis-split-bg
  pisSplitSpanBorderColor: ColorProperty // pis-split-span-border-color
  pisSplitSpanC: ColorProperty // $pis-split-span-c
  shareModal: {
    color: ColorProperty
    backgroundColor: ColorProperty
    borderRadius: string
    title: {
      fontWeight: FontWeightProperty
    }
    divider: {
      backgroundColor: ColorProperty
      margin: SizeProperty
    }
    container: {
      border: string
    }
    message: {
      fontSize: SizeProperty
      fontWeight: FontWeightProperty
      padding: string
    }
    button: {
      background: ColorProperty
      padding: string
      fontSize: SizeProperty
      color: ColorProperty
      cursor: string
    }
  }
  pisSharedHeaderBg: string
  pisHeaderHeight: string
  pisStationAlignFlex: string
  pisArrowC: string // $pis-arrows-c
  pisArrowStrokeWidth: string // $pis-arrows-stroke-width
  pisBorderC: string // $pis-border-c
  pisPrimary: ColorProperty // $pis-primary
  pisMarginBottom: string // $pis-margin-bottom
  pisDisabledPrimary: ColorProperty // $pis-disabled-primary
  pisDisabledDelayC: ColorProperty // $pis-disabled-delay-c
  pisDisabledDelayIconC: ColorProperty
  pisCancelledC: ColorProperty // $pis-cancelled-c
  pisDisabledCancelledC: ColorProperty // $pis-disabled-cancelled-c
  pisItemHeight: string
  pisRouteWidth: string
  pisEmptyListC: string
  pisTrain: {
    widthHeight: SizeProperty // $pis-train-w
    circleBg: string // $pis-train-circle-bg
    circleC: string // $pis-train-circle-c
    color: ColorProperty // $pis-train-c
  }
  pisAirplaneC: ColorProperty // $pis-airplane-c
  pisListsBg: string // $pis-lists-bg
  pisSplitPassedBg: string // $pis-split-passed-bg
  pisEmptyListFontWeight: FontWeightProperty // $pis-empty-list-font-weight
  pisUpdatedBarHeight: SizeProperty // $pis-updated-bar-height
  pisUpdatedBarBg: string // $pis-updated-bar-bg
  pisUpdatedBarBorderBottomC: ColorProperty // $pis-updated-bar-border-c
  pisUpdatedBarBorderTopC: ColorProperty // $pis-updated-bar-border-c
  pisUpdatedBarSharedC: ColorProperty // $pis-updated-bar-shared-c
  pisUpdatedBarSharedFontWeight: FontWeightProperty // $pis-updated-bar-shared-font-weight
  pisDeparturesHeaderHeight: string // $pis-departures-header-height
  pisDeparturesHeaderC: ColorProperty // $pis-departures-header-c
  pisDeparturesHeaderBg: string // $pis-departures-header-bg
  pisDeparturesHeaderBorderC: ColorProperty // $pis-departures-header-border-c
  pisShareBarHeight: SizeProperty
  departuresList: {
    header: {
      platformShortVisibility: string
      platformFullVisibility: string
    }
    maxWidth: SizeProperty
  }
  departureItem: {
    minHeight: SizeProperty
    padding: SizeProperty
    grid: {
      templateColumns: string
      vehicleIconsTemplateColumns: string
      columnGap: SizeProperty
    }
    time: {
      fontSize: SizeProperty
      fontWeight: FontWeightProperty
    }
    name: {
      fontSize: SizeProperty
      fontWeight: FontWeightProperty
    }
    details: {
      fontSize: SizeProperty
      fontWeight: FontWeightProperty
      width: SizeProperty
    }
    info: {
      fontSize: SizeProperty
    }
  }
  pisDestinationInfoC: string // $pis-destination-info-c
  pisShareC: ColorProperty // $pis-share-c
  pisCloseC: ColorProperty // $pis-close-c
  pisJourneyListStationC: ColorProperty // $pis-journey-list-station-c
  pisJourneyListTimeFontWeight: FontWeightProperty // $pis-journey-list-time-font-weight
  pisJourneyListItemTimeDelay: string // $pis-journey-list-item-time-delay
  pisJourneyListLineHeigth: SizeProperty // $pis-journey-list-line-heigth
  pisJourneyListPointWidth: SizeProperty // $pis-journey-list-point-width
  pisJourneyListPointHeight: SizeProperty
  pisJourneyListPointCurrentUpcommingTop: SizeProperty // $pis-journey-list-point-current-upcomming-top
  pisJourneyListPointLastCurrentUpcommingTop: SizeProperty // $pis-journey-list-point-last-current-upcomming-top
  pisJourneyListPointFirstLastWidth: string // $pis-journey-list-point-first-last-width
  pisJourneyListSplitterBorderC: ColorProperty // $pis-journey-list-splitter-border-c
  pisJourneyListSplitterBg: string // $pis-journey-list-splitter-bg
  pisJourneyListSplitterActiveBg: string // $pis-journey-list-splitter-active-bg
  pisJourneyListSplitterFontSize: string // $pis-journey-list-splitter-font-size
  pisJourneyListSplitterFontWeight: FontWeightProperty // $pis-journey-list-splitter-font-weight
  pisJourneyListMargin: string
  pisStationInfoLabelFontWeight: FontWeightProperty // $pis-station-info-label-font-weight
  pisStationInfoLabelTextAlign: TextAlignProperty
  pisStationInfoLabelFontSize: string
  pisStationItemContentBorderWidth: string
  pisStationItemContentMarginLeft: string
  pisStationFacilityItemBorderWidth: string
  pisFacilitiesListWidth: string
  pisFacilitiesListMargin: string
  pisFacilitiesListPadding: string
  pisFacilityItemContentPadding: string
  pisStationInfoIconW: string // $pis-station-info-icon-w
  pisStationInfoIconH: string // $pis-station-info-icon-h
  pisStationInfoAlertTextAlign: TextAlignProperty
  destination: {
    item: {
      firstBorderBottom: ColorProperty
      secondBorderBottom: ColorProperty
    }
    title: {
      fontWeight: FontWeightProperty
      fontSize: IResponsive<SizeProperty>
    }
    value: {
      fontWeight: FontWeightProperty
      fontSize: IResponsive<SizeProperty>
    }
    button: {
      color: ColorProperty
      border: string
      fontWeight: FontWeightProperty
      fontSize: IResponsive<SizeProperty>
      background: ColorProperty
      borderRadius: string
      backgroundHovered: ColorProperty
    }
  }
  pisSharedMainIconC: ColorProperty // $pis-shared-main-icon-c
  pisSharedMessageFontSize: string // $pis-shared-message-font-size
  pisCoachListImgWidth: string // $pis-coach-list-img-width
  pisCoachListImgMarginR: string // $pis-coach-list-img-margin-r
  pisCoachListImgMarginL: string // $pis-coach-list-img-margin-l
  pisCoachListNameFontSize: string // $pis-coach-list-name-font-size
  pisCoachListNameFontWeight: FontWeightProperty // $pis-coach-list-name-font-weight
  trainAnimationSpeed: string // $train-animation-speed
  showAnimationSpeed: string // $show-animation-speed
  hideAnimationSpeed: string // $hide-animation-speed
  pulseAnimationSpeed: string // $pulse-animation-speed
  passedAnimationSpeed: string // $passed-animation-speed
  pisStationNameFontSize: SizeProperty
  pisPlatformNameFontSize: SizeProperty
  pisPlatformC: ColorProperty
  pisJourneyListArrowMargin: SizeProperty
  pisJourneyListArrowHeight: SizeProperty
  pisDisabledPlatformColor: ColorProperty
  pisJourneyListItemTimeFontSize: SizeProperty
  itemContent: {
    firstLine: {
      fontWeight: FontWeightProperty
      fontSize: SizeProperty
    }
    label: {
      fontSize: SizeProperty
      fontWeight: FontWeightProperty
      background: ColorProperty
    }
  }
}

export type ITheme = DeepPartial<IFullTheme>

function prepareTheme(flavourTheme: ITheme, element: HTMLElement): ITheme {
  const theme = mergeDeep(DEFAULT_THEME, flavourTheme)
  setCustomPropertiesOnElement(element, theme)
  return theme
}

const THEMES: { [key in Flavour]?: ITheme } = {
  [Flavour.GV]: GV_THEME,
  [Flavour.TPE]: TPE_THEME,
  [Flavour.ECTL]: ECTL_THEME,
  [Flavour.SBB]: SBB_THEME,
  [Flavour.AVT]: AVT_THEME,
  [Flavour.FTEU]: FLIX_THEME,
  [Flavour.FBEU]: FLIX_THEME,
  [Flavour.FBUS]: FLIX_THEME,
  [Flavour.VY]: FLIX_THEME,
  [Flavour.TFW]: TFW_THEME,
  [Flavour.QR]: QR_THEME,
  [Flavour.CPA]: CPA_THEME,
  [Flavour.SPA]: SPA_THEME,
}

export const setCSSPropertiesByFlavour = (
  flavour: Flavour,
  element: HTMLElement,
): ITheme => prepareTheme(THEMES[flavour] || {}, element)

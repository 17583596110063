




























import { Journey } from '@gomedia-apis-ts-pis/v1'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IPISContent } from '@/constants/interfaces'
import { humanizeTimeResponse } from '@/helpers/time'

import PisLink from '@/components/pisLink/index.vue'

interface IEcoCalculatorData {
  icon: string
  saving: string
}

@Component({ components: { PisLink } })
export default class EcoCalculator extends Vue {
  @Prop({ required: true }) readonly journey: Journey
  @Prop({ required: true }) readonly content: IPISContent

  public get ecoCalculatorData(): IEcoCalculatorData[] {
    const icons = this.content.DESTINATION?.ECO_CALCULATOR?.ICONS
    if (!icons) {
      return []
    }
    const { co2Savings, fuelSavings, timeSavings } = this.journey.metadata
    return [
      {
        icon: icons.CO2,
        saving: co2Savings ? `${co2Savings}KG` : '0KG',
      },
      {
        icon: icons.FUEL,
        saving: fuelSavings ? `${fuelSavings}L` : '0L',
      },
      {
        icon: icons.TIME,
        saving: humanizeTimeResponse(timeSavings, 'hr', 'm'),
      },
    ]
  }

  public get ecoCalculatorLogoIcon(): string {
    return this.content.DESTINATION?.ECO_CALCULATOR?.ICONS.GREEN_LEAF
  }

  public get explanationText(): string {
    return this.content.DESTINATION?.ECO_CALCULATOR.EXPLANATION_TEXT
  }

  public get title(): string {
    return this.content.DESTINATION?.ECO_CALCULATOR.TITLE
  }

  public get redirectLink(): string {
    return this.content.DESTINATION?.ECO_CALCULATOR.REDIRECT_LINK
  }
}

import { ITheme } from './theme'

const darkNavy = '#060710'
const white = '#ffffff'
const black = '#000000'
const midNavy = '#151837'
const orange = '#f09235'
const lightBlue = '#02a6e6'
const midBlue = '#017bb6'

export const TPE_THEME: ITheme = {
  shareBodyBg: midNavy,
  pisJourneyListBg: midNavy,
  pisContainerBg: midNavy,
  pisHeaderHeight: '5.8rem',
  pisBorderC: midNavy,
  pisEmptyListC: white,
  pisSplitSpanBorderColor: midBlue,
  pisJourneyListSplitterBg: midBlue,
  pisJourneyListSplitterActiveBg: lightBlue,
  footer: {
    backgroundColor: midNavy,
    padding: '8px 10px',
    logosContainer: {
      padding: '35px 0',
    },
  },
  alertIcon: {
    backgroundColor: '#FF8C00',
    exclamationMarkColor: midNavy,
  },
  disruptionMessage: {
    color: white,
    fontWeight: 600,
    arrow: {
      color: darkNavy,
    },
  },
  feedbackButton: {
    height: '42px',
    backgroundColor: midBlue,
    color: white,
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: 0,
  },
  feedbackForm: {
    padding: '25px 10px',
    fontSize: '16px',
    alignItems: 'start',
    title: {
      color: white,
      marginBottom: '14px',
      fontSize: '18px',
    },
    textArea: {
      color: '#1e234f',
      borderColor: '#e0e0e0',
      focusedBorderColor: '#00a6e6',
      padding: '15px 10px',
      fontSize: '16px',
      borderRadius: 0,
    },
    button: {
      backgroundColor: midBlue,
      borderColor: midBlue,
      borderRadius: 0,
      disabledBackgroundColor: '#0b4977',
      disabledBorderColor: '#0b4977',
      disabledColor: '#8a8b9b',
    },
    before: {
      content: '',
      marginRight: 0,
    },
    form: {
      maxWidth: '100%',
    },
  },
  pisJourneyListLineBg: white,
  pisJourneyListLineWidth: '4px',
  pisJourneyListPointBg: white,
  pisJourneyListPointDisabledBg: white,
  pisPrimary: white,
  pisArrowStrokeWidth: '2',
  pisArrowC: white,
  pisListsBg: midNavy,
  pisJourneyListStationC: white,
  pisDelayC: orange,
  pisDisabledDelayC: orange,
  pisDisabledDelayIconC: orange,
  pisDelayFontWeight: 600,
  pisUpdatedBarBg: midNavy,
  pisUpdatedBarBorderBottomC: black,
  pisUpdatedBarBorderTopC: black,
  pisUpdatedBarSharedC: white,
  pisShareC: white,
  pisAirplaneC: white,
  pisTrain: {
    widthHeight: '36px',
    circleC: 'transparent',
    circleBg: midBlue,
    color: white,
  },
  pisStationInfoAlertTextAlign: 'left',
  pisStationInfoLabelTextAlign: 'left',
  pisSharedMainIconC: orange,
  pisDeparturesHeaderC: white,
  pisDeparturesHeaderBg: midNavy,
  pisDeparturesHeaderBorderC: black,
  pisStationInfoIconW: '24px',
  pisStationInfoIconH: '3.9rem',
  pisJourneyListLineDisabledBg: white,
  pisDisabledPrimary: white,
  pisJourneyListPointFirstLastSize: '18px',
  pisJourneyListPointFirstLastBorderWidth: '4px',
  pisJourneyListPointFirstLastBg: midNavy,
  pisJourneyListBottomPadding: '145px',
  pisTrainPulseAnimationLightPercent: '100%',
  pisTrainW: '36px',
  pisTrainLeft: '2px',
  pisTrainBottom: '15px',
  pisPlatformC: white,
  pisDisabledPlatformColor: white,
  pisPlatformNameFontSize: '14px',
  pisJourneyListItemTimeFontWeight: 600,
  pisJourneyListItemTimeFontSize: '14px',
  itemContent: {
    firstLine: {
      fontSize: '18px',
      fontWeight: 600,
    },
  },
  pisJourneyListInfoFontWeight: 300,
  pisJourneyListInfoMarginTop: '2px',
  pisJourneyListPointWidth: '11px',
  pisJourneyListPointHeight: '4px',
}































import * as tsx from 'vue-tsx-support'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { Journey, DisplayCallingPoint } from '@gomedia-apis-ts-pis/v1'

import JourneyItem from '@/components/journeyItem/index.vue'
import NoJourneyAvailable from '@/components/noJourneyAvailable/index.vue'
import { getTrainPositionIndex } from '@/helpers/getTrainPositionIndex'
import { IJourneyOption } from '@/constants/interfaces'

import {
  getDestinationStationName,
  getIndexOfSplitCallingPoint,
  getIndexOfJoinCallingPoint,
  getSplitDestinationStationName,
  isPhasePassed,
  isSplitCallingPointJourneyPhaseIsPassed,
  getCallingPoints,
} from '@/helpers/item'

interface IJourneyListProps {
  data: Journey
  options: IJourneyOption
}

@Component({
  components: { JourneyItem, NoJourneyAvailable },
})
export default class JourneyList extends tsx.Component<IJourneyListProps> {
  @Prop() public data: Journey
  @Prop() public options: IJourneyOption

  @Watch('data') public dataChanged(newData: Journey) {
    if (
      isSplitCallingPointJourneyPhaseIsPassed(newData) &&
      this.showSplittedList
    ) {
      this.options.setOrigin()
    }
  }

  public get list(): DisplayCallingPoint[] {
    return getCallingPoints(this.data, this.showSplittedList)
  }

  public get showSplittedList(): boolean {
    return this.options.showSplittedList
  }

  public hasSplitOnIndex(index: number): boolean {
    return (
      getIndexOfSplitCallingPoint(this.data) === index &&
      !isPhasePassed(this.data.calling_points[index].journey_phase)
    )
  }

  public firstSplitStation(index: number): boolean {
    if (!index) {
      return false
    }
    return this.hasSplitOnIndex(index - 1)
  }

  public hasJoinOnIndex(index: number): boolean {
    return getIndexOfJoinCallingPoint(this.data) === index
  }

  public get trainPositionIndex(): number {
    return getTrainPositionIndex(this.data)
  }

  public isFirst(index: number): boolean {
    return index === 0
  }

  public isLast(index: number): boolean {
    return index === this.list.length - 1
  }

  public isCurrent(index: number): boolean {
    return index === this.trainPositionIndex
  }

  public get originDestinationLabel(): string {
    return getDestinationStationName(this.data)
  }

  public get splitDestinationLabel(): string {
    return getSplitDestinationStationName(this.data)
  }
}

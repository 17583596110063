import * as tsx from 'vue-tsx-support'
import { Component } from 'vue-property-decorator'
import { store } from '@/store'

import Speedometer from '@/components/speedometer/index.vue'

@Component
export default class PisSpeedometer extends tsx.Component<{}, {}, {}> {
  public get speed(): number {
    return store.getters.getSpeed
  }

  private mounted(): void {
    store.dispatch('pollingStart', {
      action: 'fetchTrainPosition',
      requestTimeout: 2000,
    })
  }

  private beforeDestroy(): void {
    store.dispatch('pollingStop', 'fetchTrainPosition')
  }

  public render(h) {
    return <Speedometer speed={this.speed} />
  }
}


























import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class CurrentPosition extends tsx.Component<{
  isActive: boolean
}> {
  @Prop({ required: true, type: Boolean }) public isActive

  get iconClasses() {
    return {
      'current-position-marker': true,
      'current-position-marker_active': this.isActive,
    }
  }
}

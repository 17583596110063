






















































































import JourneySplitter from '@/components/journeySplitter/index.vue'
import Alert from '@/components/svgs/common/alert/index.vue'
import Arrow from '@/components/svgs/common/arrow/index.vue'
import Airplane from '@/components/svgs/journeyItem/airplane/index'
import Join from '@/components/svgs/journeyItem/join/index.vue'
import NoTime from '@/components/svgs/journeyItem/noTime/index.vue'
import Split from '@/components/svgs/journeyItem/split/index.vue'

import Train from '@/components/svgs/journeyItem/train/index.vue'
import AvtTrain from '@/components/svgs/journeyItem/avtTrain/index.vue'
import TpeTrain from '@/components/svgs/journeyItem/tpeTrain/index.vue'
import TfwTrain from '@/components/svgs/journeyItem/tfwTrain/index.vue'

import { IConfig, IPISContent } from '@/constants/interfaces'
import { VueConstructor } from 'vue'
import { formatTimeToAmPm } from '@/helpers/destination'
import { Flavour } from '@/enums'
import {
  getAlert,
  getDelay,
  getNameI18n,
  getPhase,
  getTime,
  isActiveStation,
  isAirport,
  isAlert,
  isCancelled,
  isDelay,
} from '@/helpers/item'
import { store } from '@/store'

import { DisplayCallingPoint, JourneyPhase } from '@gomedia-apis-ts-pis/v1'
import { Component, Prop, Vue } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

interface IJourneyItemProps {
  station: DisplayCallingPoint
  showArrow: boolean
  showPlatforms?: boolean
  hideAlerts?: boolean
  skipStationInformation?: boolean
  isFirstStation: boolean
  isLastStation: boolean
  isCurrentStation?: boolean
  isSplit?: boolean
  isJoin?: boolean
  showSplittedList?: boolean
  originDestinationName?: string
  splitDestinationName?: string
  amPm?: boolean
  flavour?: string
  showStationLabels?: boolean
}

interface IJourneyItemEvents {
  onChangeToOrigin: Function
  onChangeToSplit: Function
}

const FLAVOUR_TRAIN_ICONS_DICTIONARY: { [key: string]: VueConstructor<Vue> } = {
  [Flavour.AVT]: AvtTrain,
  [Flavour.TPE]: TpeTrain,
  [Flavour.TFW]: TfwTrain,
  DEFAULT: Train,
}

@Component({
  components: {
    JourneySplitter,
    Alert,
    Arrow,
    Airplane,
    Join,
    NoTime,
    Split,
  },
})
export default class JourneyItem extends tsx.Component<
  IJourneyItemProps,
  IJourneyItemEvents
> {
  @Prop() public station: DisplayCallingPoint
  @Prop() public isCurrentStation: boolean
  @Prop() public showArrow: boolean
  @Prop() public showPlatforms: boolean
  @Prop() public hideAlerts: boolean
  @Prop() public skipStationInformation: boolean
  @Prop() public isFirstStation: boolean
  @Prop() public isLastStation: boolean
  @Prop() public isSplit: boolean
  @Prop() public isFirstSplitStation: boolean
  @Prop() public isJoin: boolean
  @Prop() public showSplittedList: boolean
  @Prop() public originDestinationName: string
  @Prop() public splitDestinationName: string
  @Prop() public amPm: boolean
  @Prop() public flavour: string
  @Prop() public showStationLabels: boolean

  public get config(): IConfig {
    return store.getters.getConfig
  }

  public get showLabel(): boolean {
    return Boolean(this.showStationLabels && this.stationLabels)
  }

  public get stationLabels() {
    return this.content.STATION_LABELS?.[this.stationName]
  }

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get language(): string {
    return store.getters.getLanguage
  }

  public get getCursoreStyle() {
    return !this.showArrow ? { cursor: 'initial' } : null
  }

  public get trainIconByFlavour() {
    const component: VueConstructor<Vue> =
      FLAVOUR_TRAIN_ICONS_DICTIONARY[this.flavour] ||
      FLAVOUR_TRAIN_ICONS_DICTIONARY.DEFAULT
    return component
  }

  public get noTimeClass() {
    return { disabled: !isActiveStation(this.station) }
  }

  public get time() {
    const time = getTime(this.station, this.isFirstStation, this.isLastStation)
    return formatTimeToAmPm(time, this.amPm)
  }

  public get delay() {
    const delay = getDelay(
      this.station,
      this.isFirstStation,
      this.isLastStation,
    )
    return formatTimeToAmPm(delay, this.amPm)
  }

  public get isDelay() {
    return (
      isDelay(this.station, this.isFirstStation, this.isLastStation) &&
      !this.isCancelled
    )
  }

  public get isAlert(): boolean {
    return isAlert(this.station) && !this.hideAlerts
  }

  public get getAlert(): string {
    return getAlert(this.station, this.language)
  }

  public get isAirport(): boolean {
    return isAirport(this.station)
  }

  public get id(): string {
    return this.station.calling_point.id.id
  }

  public get currentPhase(): JourneyPhase {
    return getPhase(this.station)
  }

  public get isPassed(): boolean {
    const passedPhases = [JourneyPhase.PASSED, JourneyPhase.PASSEDINFERRED]
    return passedPhases.includes(this.currentPhase)
  }

  public get isUpcoming(): boolean {
    return this.currentPhase === JourneyPhase.UPCOMING
  }

  public get stationClasses() {
    return {
      station: true,
      station_split: this.isSplit,
      current: this.isCurrentStation,
      current_upcoming: this.isUpcoming && this.isCurrentStation,
      passed: this.isPassed,
      passed_split: this.isPassed && this.isSplit,
      upcoming: this.isUpcoming && !this.isCurrentStation,
    }
  }

  public get itemContentClasses() {
    return {
      'item-content': true,
      'item-content_no-top-border':
        this.isFirstSplitStation || this.isFirstStation,
    }
  }

  public get firstLineClasses() {
    return {
      line: true,
      line_hidden: this.isFirstSplitStation || this.isFirstStation,
    }
  }

  public get secondLineClasses() {
    return {
      line: true,
      line_hidden: this.isSplit || this.isLastStation,
    }
  }

  public get isFirstOrLast() {
    return this.isFirstStation || this.isLastStation
  }

  public get pointClasses() {
    return {
      point_intermediate: !this.isFirstOrLast,
      point_big: this.isFirstOrLast,
    }
  }

  public get timeClasses() {
    return {
      time: true,
      'time_am-pm': this.amPm,
    }
  }

  public get trainClasses() {
    return {
      train: true,
      train_current: !this.isUpcoming && this.isCurrentStation,
      train_upcoming:
        !this.isFirstStation && this.isCurrentStation && this.isUpcoming,
      'train__first-station': this.isFirstStation && this.isCurrentStation,
      'train__last-station':
        this.isLastStation && this.isCurrentStation && this.isUpcoming,
    }
  }

  public get strikeClass() {
    return {
      strike: this.isDelay || this.isCancelled,
    }
  }

  public get platform() {
    return this.station.calling_point.platform || '- -'
  }

  public get showPlatform() {
    return !this.isCancelled && this.showPlatforms
  }

  public get stationName(): string {
    return getNameI18n(this.station.display_name, this.language)
  }

  public get isCancelled(): boolean {
    return isCancelled(this.station)
  }

  public get noTimeLabel(): string {
    return this.content.JOURNEY_LIST.NO_TIME
  }

  public get platformLabel(): string {
    return `${this.content.JOURNEY_LIST.PLATFORM_NUM} ${this.platform}`
  }

  public get cancelledLabel(): string {
    return this.content.JOURNEY_LIST.CANCELLED
  }

  public get splittingLabel(): string {
    return this.content.JOURNEY_LIST.SPLITTING
  }

  public get joiningLabel(): string {
    return 'Train joining' // this.content.JOURNEY_LIST.SPLITTING
  }

  public onClick() {
    if (!this.showArrow) return

    const TIPLOC = this.id
    let location = this.skipStationInformation
      ? '/pis/departures?'
      : '/pis/info?'
    location += TIPLOC ? `TIPLOC=${TIPLOC}` : ''
    location += this.skipStationInformation ? '&type=train' : ''
    localStorage.setItem('PIS_ALERT_MESSAGE', this.getAlert)
    store.dispatch('pushRouter', { link: location })
  }

  public setOrigin(): void {
    this.$emit('changeToOrigin')
  }

  public setSplit(): void {
    this.$emit('changeToSplit')
  }
}

import { PIS_CONFIG } from '@/constants'
import { ITiplocMetadata } from '@/constants/interfaces'
import timezones from '@/constants/timezones'
import {
  AirportDeparture,
  CallingPointTimes,
  DisplayCallingPoint,
  Departure,
  GetJourneyStationsResponse,
  Journey,
  JourneyPhase,
  NameI18n,
  Tiploc,
  TrainDeparture,
} from '@gomedia-apis-ts-pis/v1'
import isEmpty from 'lodash/isEmpty'

import moment from 'moment'
import 'moment-timezone/moment-timezone'

moment.tz.load(timezones)

function humanizeDuration(period: number): string {
  const time = period < 0 ? period * -1 : null
  let duration = moment.duration(time)

  if (!duration || duration.toISOString() === 'P0D') return ''

  duration = duration.add(1, 'm')
  const durationsList = [
    { value: duration.days(), unit: 'day' },
    { value: duration.hours(), unit: 'hr' },
    { value: duration.minutes(), unit: 'min' },
  ]

  return durationsList
    .map(({ value, unit }) =>
      value >= 1 ? `${value} ${value > 1 ? `${unit}s` : unit}` : '',
    )
    .filter(Boolean)
    .join(' ')
}

export enum EDepartureType {
  train,
  airport,
}

enum EDepartureTypeToField {
  train_departures,
  airport_departures,
}

enum ETimes {
  scheduledTime = 'scheduled_time',
  actualTime = 'actual_time',
  estimatedTime = 'estimated_time',
}

enum ESubjourneyField {
  join_journey = 'join_journey',
  split_journey = 'split_journey',
}

const isObject = (data) => {
  return data.toString() === '[object Object]'
}

// tests added
export const getTime = (
  item: DisplayCallingPoint,
  isFirstStation: boolean,
  isLastStation: boolean,
  isDeparture?: boolean,
  humanize?: boolean,
): string => {
  const date = getFirstLineTime(
    item,
    isFirstStation,
    isLastStation,
    isDeparture,
  )

  return date && !isObject(date)
    ? formatTimeLable(date, item.display_time_zone, humanize)
    : ''
}

// tests added
export const getDelay = (
  item: DisplayCallingPoint,
  isFirstStation: boolean,
  isLastStation: boolean,
  isDeparture?: boolean,
  humanize?: boolean,
): string => {
  const date = getSecondLineTime(
    item,
    isFirstStation,
    isLastStation,
    isDeparture,
  )

  return date && !isObject(date)
    ? formatTimeLable(date, item.display_time_zone, humanize)
    : ''
}

export const isActiveStation = (item: DisplayCallingPoint): boolean => {
  const phase = getPhase(item)

  return (
    !isCancelled(item) &&
    phase !== JourneyPhase.PASSEDINFERRED &&
    phase !== JourneyPhase.PASSED
  )
}

// tests added
export const isDelay = (
  item: DisplayCallingPoint,
  isFirstStation: boolean,
  isLastStation: boolean,
  isDeparture?: boolean,
): boolean => {
  try {
    const f = getFirstLineTime(item, isFirstStation, isLastStation, isDeparture)
    const s = getSecondLineTime(
      item,
      isFirstStation,
      isLastStation,
      isDeparture,
    )

    if (!f || !s || isObject(f) || isObject(s)) return
    const a = moment(f)
    const b = moment(s)
    return Math.abs(a.diff(b)) >= PIS_CONFIG.MAX_DELAY
  } catch {
    return
  }
}

// tests added
export const formatTimeLable = (
  date: string | Date,
  timezone: string,
  humanize?: boolean,
): string => {
  try {
    if (!date) throw ''
    // Check if timezone is correct, if not - use fallback
    const correctTimezone = moment.tz.zone(timezone)
      ? timezone
      : PIS_CONFIG.FALLBACK_TIMEZONE
    const time = moment(date).tz(correctTimezone)
    return humanize
      ? humanizeDuration(moment().diff(time))
      : time.format('HH:mm')
  } catch {
    return ''
  }
}

export const getDateAsISOString = (date: Date): Date => {
  const dateStringWith = moment(date)
    .tz(PIS_CONFIG.FALLBACK_TIMEZONE)
    .toISOString()
  const d = new Date(dateStringWith.split('.')[0])
  return d
}

// test added
export const isCancelled = (item: DisplayCallingPoint): boolean => {
  try {
    return item.calling_point.cancelled
  } catch (error) {
    return false
  }
}

const getFirstLineTime = (
  item: DisplayCallingPoint,
  isFirstStation: boolean,
  isLastStation: boolean,
  isDeparture?: boolean,
): Date => {
  try {
    return isDeparture
      ? getDepartureTimes(item.calling_point.departure_times).scheduledTime
      : getStationTimes(item, isFirstStation, isLastStation).scheduledTime
  } catch (error) {
    return undefined
  }
}

const getSecondLineTime = (
  item: DisplayCallingPoint,
  isFirstStation: boolean,
  isLastStation: boolean,
  isDeparture?: boolean,
): Date => {
  try {
    return isDeparture
      ? getDepartureTimes(item.calling_point.departure_times).realTime
      : getStationTimes(item, isFirstStation, isLastStation).realTime
  } catch (error) {
    return undefined
  }
}

const getStationTimes = (
  item: DisplayCallingPoint,
  isFirstStation: boolean,
  isLastStation: boolean,
) => {
  const phase: JourneyPhase = getPhase(item)
  const { departure_times, arrival_times } = item.calling_point

  // Specific checks for first and last stations
  if (isFirstStation && (!arrival_times || isEmpty(arrival_times))) {
    return getDepartureTimes(departure_times)
  } else if (isLastStation && (!departure_times || isEmpty(departure_times))) {
    return getArrivalTimes(arrival_times)
  }

  switch (phase) {
    case JourneyPhase.CURRENT: {
      if (!departure_times || isEmpty(departure_times)) {
        return getArrivalTimes(arrival_times)
      }
    }
    // eslint-disable-next-line no-fallthrough
    case JourneyPhase.PASSED:
    case JourneyPhase.PASSEDINFERRED:
      return getDepartureTimes(departure_times)
    case JourneyPhase.UPCOMING: {
      return getArrivalTimes(arrival_times)
    }
  }
}

// tests added
export const getPhase = (item: DisplayCallingPoint): JourneyPhase => {
  try {
    return item.journey_phase
  } catch (error) {
    return
  }
}

// tests added
export const isPhasePassed = (phase: JourneyPhase): boolean => {
  return phase === JourneyPhase.PASSED
}

const getDepartureTimes = (departureTimes: CallingPointTimes) => {
  const scheduledTime: Date = departureTimes[ETimes.scheduledTime]
  const realTime: Date =
    departureTimes[ETimes.actualTime] || departureTimes[ETimes.estimatedTime]

  return {
    scheduledTime,
    realTime,
  }
}

const getArrivalTimes = (arrivalTimes: CallingPointTimes) => {
  const scheduledTime: Date = arrivalTimes[ETimes.scheduledTime]
  const realTime: Date = arrivalTimes[ETimes.estimatedTime]

  return {
    scheduledTime,
    realTime,
  }
}

// tests added
export const getCallingPointId = (
  callingPoint: DisplayCallingPoint,
): string => {
  try {
    return callingPoint.calling_point.id.id
  } catch (err) {
    return
  }
}

// tests added
export const getDepartures = (
  departures: Departure[],
  stationId: string,
  type?: EDepartureType,
): TrainDeparture[] | AirportDeparture[] => {
  try {
    const departureType = EDepartureTypeToField[type]
    const departure = departures.find((item) => item && item[departureType])
    return departure[departureType].departures
  } catch (error) {
    return []
  }
}

const getDeparturesArray = (station: DisplayCallingPoint, property: string) => {
  const departure = station.calling_point.departures.find(
    (departure) => departure && departure[property],
  )

  return departure[property].departures
}

// tests added
export const getDepartureAsDisplayCallingPoint = (
  departure: AirportDeparture | TrainDeparture,
): DisplayCallingPoint => {
  try {
    if (isEmpty(departure)) throw 'no departure'

    return {
      calling_point: {
        departure_times: departure.departure_times,
      },
      display_time_zone: (departure as TrainDeparture).display_time_zone,
    } as DisplayCallingPoint
  } catch (error) {
    return
  }
}

// tests added
export const getNameI18n = (nameI18n: NameI18n, language = 'en'): string => {
  try {
    const keys = Object.keys(nameI18n.values)
    return keys.length > 0
      ? nameI18n.values[language] || nameI18n.values[keys[0]] || ''
      : ''
  } catch (error) {
    return ''
  }
}

export const getVia = (item: TrainDeparture): string => {
  try {
    return item.via_station || ''
  } catch {
    return ''
  }
}

// tests added
export const getOriginCallingPoints = (
  journey: Journey,
): DisplayCallingPoint[] => {
  try {
    return journey.calling_points && journey.calling_points.length
      ? journey.calling_points
      : []
  } catch (error) {
    return []
  }
}

// tests added
export const getDestinationStationName = (journey: Journey): string => {
  try {
    const indexOfLastStation = getOriginCallingPoints(journey).length - 1
    return getNameI18n(journey.calling_points[indexOfLastStation].display_name)
  } catch (error) {
    return ''
  }
}

// tests added
export const getSplitDestinationStationName = (journey: Journey): string => {
  try {
    const indexOfLastStation = getSplittedCallingPoints(journey).length - 1
    const callingPoint = getSplittedCallingPoints(journey)[indexOfLastStation]
    return getNameI18n(callingPoint.display_name)
  } catch (error) {
    return ''
  }
}

// tests added
export const getCallingPointIdOfSubJourney = (
  journey: Journey,
  field: string = ESubjourneyField.split_journey,
): string => {
  try {
    return journey[field].calling_point.id.length
      ? journey[field].calling_point.id
      : undefined
  } catch (error) {
    return
  }
}

// needs to add tests
export const getSubJourneyCallingPoints = (
  journey: Journey,
  field: string = ESubjourneyField.split_journey,
): DisplayCallingPoint[] => {
  try {
    return journey[field].journey.calling_points &&
      journey[field].journey.calling_points.length
      ? journey[field].journey.calling_points
      : []
  } catch (error) {
    return []
  }
}

// tests added
export const getSplittedCallingPoints = (
  journey: Journey,
): DisplayCallingPoint[] => {
  return getSubJourneyCallingPoints(journey)
}

// needs to add tests
export const getJoinCallingPoints = (
  journey: Journey,
): DisplayCallingPoint[] => {
  try {
    return getSubJourneyCallingPoints(
      journey,
      ESubjourneyField.join_journey,
    ).slice(
      getIndexOfCallingPointWithSplitOrJoin(
        journey,
        ESubjourneyField.join_journey,
      ) - 1,
      journey.join_journey.journey.calling_points.length,
    )
  } catch (error) {
    return []
  }
}

// tests added
export const getIndexOfCallingPointWithSplitOrJoin = (
  journey: Journey,
  field: string = ESubjourneyField.split_journey,
): number => {
  try {
    return getOriginCallingPoints(journey).findIndex(
      (station: DisplayCallingPoint) => {
        return (
          getCallingPointId(station) ===
          getCallingPointIdOfSubJourney(journey, field)
        )
      },
    )
  } catch (error) {
    return -1
  }
}

// needs to add tests
export const getIndexOfSplitCallingPoint = (journey: Journey): number => {
  return getIndexOfCallingPointWithSplitOrJoin(journey)
}

// needs to add tests
export const getIndexOfJoinCallingPoint = (journey: Journey): number => {
  return getIndexOfCallingPointWithSplitOrJoin(
    journey,
    ESubjourneyField.join_journey,
  )
}

// tests added
export const isSplitCallingPointJourneyPhaseIsPassed = (
  journey: Journey,
): boolean => {
  try {
    const phase = getOriginCallingPoints(journey).find(
      (station: DisplayCallingPoint) => {
        return (
          getCallingPointId(station) === getCallingPointIdOfSubJourney(journey)
        )
      },
    ).journey_phase

    return isPhasePassed(phase)
  } catch (error) {
    return false
  }
}

// needs to add tests
export const getCallingPoints = (
  journey: Journey,
  showSplittedList: boolean,
): DisplayCallingPoint[] =>
  showSplittedList
    ? [
        ...getOriginCallingPoints(journey).slice(
          0,
          getIndexOfCallingPointWithSplitOrJoin(
            journey,
            ESubjourneyField.split_journey,
          ),
        ),
        ...getSplittedCallingPoints(journey),
      ]
    : [...getOriginCallingPoints(journey), ...getJoinCallingPoints(journey)]

// tests added
export const isAlert = (station: DisplayCallingPoint) => {
  try {
    const text = getNameI18n(station.calling_point.station_alert)
    return text.length > 0
  } catch {
    return false
  }
}

// tests added
export const getAlert = (station: DisplayCallingPoint, language = 'en') => {
  try {
    return getNameI18n(station.calling_point.station_alert, language)
  } catch {
    return ''
  }
}

// tests added
export const isAirport = (station: DisplayCallingPoint): boolean => {
  try {
    return getDeparturesArray(station, 'airport_departures').length > 0
  } catch {
    return false
  }
}

export const isBus = (departure: TrainDeparture): boolean => {
  return departure.platform === 'BUS'
}

export const isTBC = (departure: TrainDeparture): boolean => {
  return departure.platform === 'TBC'
}

// tests added
export const getPlatform = (departure: TrainDeparture): string => {
  try {
    return isBus(departure)
      ? departure.platform
      : isTBC(departure) || !departure.platform
      ? '- -'
      : departure.platform
  } catch {
    return '- -'
  }
}

export const getStationInfo = (
  stations: GetJourneyStationsResponse,
  tiploc: string,
): Tiploc => {
  try {
    return stations.items.find((station) => station.tiploc === tiploc) || null
  } catch {
    return null
  }
}

// tests added
export const getTiplocMetadata = (
  metadata: ITiplocMetadata,
  flavour: string,
): string => {
  try {
    const key = `${flavour}_url`
    return metadata[key] ? metadata[key] : ''
  } catch (error) {
    return ''
  }
}

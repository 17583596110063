import { Flavour } from '@/enums'
import Axios from 'axios'
import { SHARE_MY_TRAIN_CONSTANTS, PIS_CONTENT } from '@/constants'
import {
  IShareMyTrainPisConfig,
  IShareMyTrainPisConstants,
  IPointOfInterest,
  ISMTContent,
} from '@/constants/interfaces'
import { ITypography, BASE_TYPOGRAPHY } from '@/themes/theme'

export class ContentService {
  private getDefaultConstants(flavour: Flavour): IShareMyTrainPisConstants {
    return (
      SHARE_MY_TRAIN_CONSTANTS[flavour] || SHARE_MY_TRAIN_CONSTANTS[Flavour.GV]
    )
  }

  public async getSMTContent(language: string): Promise<ISMTContent> {
    try {
      const { data } = await Axios.get<ISMTContent>(`/content/${language}.json`)
      return data
    } catch (e) {
      return PIS_CONTENT
    }
  }

  public getSMTConfig(flavour: Flavour): IShareMyTrainPisConfig {
    return this.getDefaultConstants(flavour).CONFIG
  }

  public async getPointsOfInterest(): Promise<IPointOfInterest[]> {
    try {
      const { data } = await Axios.get('/api/v2/content?tag=point_of_interest')
      // const { data } = await Axios.get('/poi/list.json')
      return data
    } catch (error) {
      return []
    }
  }

  public async getTypography(flavour: Flavour): Promise<ITypography> {
    try {
      const { data } = await Axios.get(`/typography/typography.${flavour}.json`)
      return data
    } catch (error) {
      return BASE_TYPOGRAPHY
    }
  }
}

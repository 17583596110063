




















import * as tsx from 'vue-tsx-support'
import { Component, Prop } from 'vue-property-decorator'
import { GetVehicleInfoResponse, VehicleUnit } from '@gomedia-apis-ts-pis/v1'
import Spinner from 'vue-simple-spinner'

import { store } from '@/store'
import { ITrainInfoOption, IPISContent } from '@/constants/interfaces'
import { isFirst, isMiddle, isLast } from '@/helpers/coachListHelpers'
import CoachItem from '@/components/coachItem/index.vue'

interface ICoachListProps {
  options?: ITrainInfoOption
}

@Component({
  components: {
    CoachItem,
    Spinner,
  },
})
export default class CoachList extends tsx.Component<ICoachListProps> {
  @Prop() public options: ITrainInfoOption

  public vehicleUnits: VehicleUnit[] = []
  public inProgress = false
  public isError = false

  public get isTrainInfoAvailable(): boolean {
    return Boolean(
      !this.isError && this.vehicleUnits && this.vehicleUnits.length,
    )
  }

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public async requestVehicleUnits(): Promise<void> {
    this.inProgress = true
    try {
      const response: GetVehicleInfoResponse = await store.dispatch(
        'fetchVehicleInfo',
      )
      if (response && response.units) {
        this.vehicleUnits = response.units
      }
    } catch (e) {
      this.isError = true
    } finally {
      this.inProgress = false
    }
  }

  public isFirst(index): boolean {
    return isFirst(index)
  }

  public isMiddle(index): boolean {
    return isMiddle(index, this.vehicleUnits.length)
  }
  public isLast(index): boolean {
    return isLast(index, this.vehicleUnits.length)
  }

  public created(): void {
    this.requestVehicleUnits()
  }
}

import { PIS_CONFIG } from '@/constants'
import { IData, Status } from '@/constants/interfaces'
import { Flavour } from '@/enums'
import {
  Journey,
  GetJourneyResponse,
  TrainPosition,
  ShareMyTrainResponse,
} from '@gomedia-apis-ts-pis/v1'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const merge = require('deepmerge')

export const getLatitude = (trainPosition: TrainPosition): number => {
  try {
    return trainPosition.latitude && trainPosition.latitude !== -1
      ? trainPosition.latitude
      : null
  } catch {
    return null
  }
}

export const getLongitude = (trainPosition: TrainPosition): number => {
  try {
    return trainPosition.longitude && trainPosition.longitude !== -1
      ? trainPosition.longitude
      : null
  } catch {
    return null
  }
}

export const getSpeed = (trainPosition: TrainPosition): number => {
  try {
    const speed = trainPosition.speed
    return speed > 0 ? speed : 0
  } catch {
    return 0
  }
}

export const getCopyJourneyResponse = (
  journeyResponse: IData<GetJourneyResponse>,
): GetJourneyResponse =>
  merge.default(journeyResponse.data, {}) ||
  (JSON.parse(
    localStorage.getItem(PIS_CONFIG.CACHED_JOURNEY_KEY),
  ) as GetJourneyResponse)

export const isInit = (data: IData<any>): boolean => data.status === Status.Init
export const canRenderData = (data: IData<any>): boolean =>
  data.status !== Status.Init && data.status !== Status.FailedInit
export const isRenderError = (data: IData<any>): boolean =>
  data.status === Status.FailedInit

export const getShareUrl = (shareResponse: ShareMyTrainResponse): string => {
  const defaultValue = ''
  try {
    return shareResponse.url || defaultValue
  } catch {
    return defaultValue
  }
}

export const getFlavour = (journeyResponse: GetJourneyResponse): Flavour => {
  try {
    return journeyResponse.journey.toc as Flavour
  } catch {
    return Flavour.GV
  }
}

export const getTrainNumber = (journey: Journey): string => {
  // TODO: replace 'ccjpa_train_number' with dynamic prop if more flavours will require train number
  // now is working only for CPA/SPA
  return journey?.metadata?.ccjpa_train_number || ''
}

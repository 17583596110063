import {
  Journey,
  JourneyPhase,
  DisplayCallingPoint,
} from '@gomedia-apis-ts-pis/v1'
import { isDelay, getTime, getDelay, getPhase, getNameI18n } from './item'
import { IDestinationInfoItem } from '@/constants/interfaces'
import moment from 'moment'

interface UpcomingStationData {
  station: DisplayCallingPoint
  isFirst: boolean
  isLast: boolean
}

const getStationName = (station: DisplayCallingPoint): string => {
  return getNameI18n(station.display_name)
}

// tests added
export const isUpcoming = (station: DisplayCallingPoint): boolean =>
  getPhase(station) === JourneyPhase.UPCOMING

export const getUpcomingStationData = (
  journey: Journey,
): UpcomingStationData => {
  let indexOfFirstUpcoming = journey.calling_points.findIndex((station) =>
    isUpcoming(station),
  )
  const length = journey.calling_points.length
  if (indexOfFirstUpcoming === -1) {
    indexOfFirstUpcoming = length - 1
  }
  const station = journey.calling_points[indexOfFirstUpcoming]
  const isFirst = indexOfFirstUpcoming === 0
  const isLast = indexOfFirstUpcoming === length

  return {
    station,
    isFirst,
    isLast,
  }
}

// tests added
export const getStationTipLoc = (station: DisplayCallingPoint): string => {
  try {
    return station.calling_point.id.id ? station.calling_point.id.id : ''
  } catch (error) {
    return ''
  }
}

// tests added
export const getStationTime = (
  station: DisplayCallingPoint,
  isFirst: boolean,
  isLast: boolean,
  humanize?: boolean,
): string => {
  try {
    return isDelay(station, isFirst, isLast)
      ? getDelay(station, isFirst, isLast, false, humanize)
      : getTime(station, isFirst, isLast, false, humanize)
  } catch (error) {
    return ''
  }
}

// tests added
export const getNextStationInfo = (journey: Journey): IDestinationInfoItem => {
  try {
    const { isFirst, isLast, station } = getUpcomingStationData(journey)

    return {
      name: getStationName(station),
      time: getStationTime(station, isFirst, isLast),
      tiploc: getStationTipLoc(station),
      arriveIn: getStationTime(station, isFirst, isLast, true),
    }
  } catch (error) {
    return {
      name: '',
      time: '',
      tiploc: '',
      arriveIn: '',
    }
  }
}

// tests added
export const getDestinationInfo = (journey: Journey): IDestinationInfoItem => {
  try {
    const indexOfDestination = journey.calling_points.length - 1
    const station = journey.calling_points[indexOfDestination]
    return {
      name: getStationName(station),
      time: getStationTime(station, false, true),
      tiploc: getStationTipLoc(station),
    }
  } catch (error) {
    return {
      name: '',
      time: '',
      tiploc: '',
    }
  }
}

export const formatTimeToAmPm = (time: string, toAmPm: boolean): string => {
  try {
    if (!time) throw ''
    return toAmPm ? moment(time, 'HH:mm').format('hh:mm A') : time
  } catch (error) {
    return ''
  }
}















import * as tsx from 'vue-tsx-support'
import { Component, Prop } from 'vue-property-decorator'
import { store } from '@/store'

import { IPISContent } from '@/constants/interfaces'

interface IJourneySplitterProps {
  originDestinationName: string
  splitDestinationName: string
  showSplittedList: boolean
}

interface IJourneySplitterEvents {
  onChangeToOrigin: Function
  onChangeToSplit: Function
}

@Component
export default class JourneySplitter extends tsx.Component<
  IJourneySplitterProps,
  IJourneySplitterEvents
> {
  @Prop() public originDestinationName: string
  @Prop() public splitDestinationName: string
  @Prop() public showSplittedList: boolean

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get towardsLabel(): string {
    return this.content.JOURNEY_LIST.TOWARDS
  }

  public setOrigin() {
    this.$emit('changeToOrigin')
  }

  public setSplit() {
    this.$emit('changeToSplit')
  }

  public get originActiveClasses() {
    return {
      'journey-splitter-item': true,
      'journey-splitter-active': !this.showSplittedList,
    }
  }

  public get splitActiveClasses() {
    return {
      'journey-splitter-item': true,
      'journey-splitter-active': this.showSplittedList,
    }
  }
}

import { ITheme } from './theme'

const darkNavy = '#060710'
const white = '#ffffff'
const black = '#000000'
const midNavy = '#13283a'
const orange = '#ff981a'
const lightBlue = '#0099d5'
const saffron = '#f5d83c'
const red = '#ae1c0f'
const darkRed = '#941b0e'
const blackArrow = '#333333'
const grey = '#bebdbd'
const lightGrey = '#e4e4e4'
const shareBgColor = '#f5f5f5'

export const TFW_THEME: ITheme = {
  pisShareBarHeight: '54px',
  pisJourneyListBg: white,
  pisContainerBg: white,
  pisStationAlignFlex: 'center',
  pisBorderC: lightGrey,
  pisItemHeight: '8rem',
  pisRouteWidth: '4.5rem',
  pisEmptyListC: black,
  pisSplitSpanBorderColor: lightBlue,
  pisJourneyListSplitterBg: '#05111d',
  pisJourneyListSplitterActiveBg: 'transparent',
  pisJourneyListSplitterBorderC: black,
  pisCancelledC: red,
  footer: {
    bottom: '130px',
    backgroundColor: white,
    padding: '8px 10px',
    logosContainer: {
      padding: '35px 17px',
    },
  },
  alertIcon: {
    backgroundColor: '#FF8C00',
    exclamationMarkColor: midNavy,
  },
  disruptionMessage: {
    color: white,
    fontWeight: 600,
    arrow: {
      color: darkNavy,
    },
  },
  feedbackButton: {
    height: '42px',
    backgroundColor: saffron,
    color: black,
    fontSize: '16px',
    fontWeight: 700,
    borderRadius: '3px',
  },
  feedbackForm: {
    padding: '25px 15px',
    fontSize: '16px',
    alignItems: 'start',
    title: {
      color: white,
      marginBottom: 0,
      fontSize: '22px',
      fontWeight: 'bold',
    },
    textArea: {
      color: '#1e234f',
      borderColor: '#e0e0e0',
      focusedBorderColor: '#00a6e6',
      padding: '15px 10px',
      fontSize: '16px',
      borderRadius: 0,
    },
    button: {
      backgroundColor: saffron,
      borderColor: saffron,
      borderRadius: '3px',
      disabledBackgroundColor: saffron,
      disabledBorderColor: saffron,
      disabledColor: '#8a8b9b',
      color: black,
    },
    before: {
      content: '',
      marginRight: 0,
    },
    form: {
      maxWidth: '100%',
    },
  },
  departureItem: {
    padding: '0 10px',
    details: {
      fontWeight: 'normal',
      width: '3rem',
    },
    name: {
      fontWeight: 500,
    },
    info: {
      fontSize: '1.4rem',
    },
  },
  pisSharedHeaderBg: red,
  shareBodyBg: '',
  pisJourneyListLineBg: red,
  pisJourneyListLineWidth: '4px',
  pisJourneyListPointBg: red,
  pisJourneyListPointDisabledBg: grey,
  pisPrimary: black,
  pisMarginBottom: '12px',
  pisArrowC: blackArrow,
  pisArrowStrokeWidth: '2',
  pisListsBg: shareBgColor,
  pisJourneyListStationC: black,
  pisDelayC: red,
  pisDisabledDelayC: red,
  pisDisabledDelayIconC: orange,
  pisDelayFontWeight: 500,
  pisUpdatedBarBg: midNavy,
  pisUpdatedBarBorderBottomC: lightGrey,
  pisUpdatedBarBorderTopC: lightGrey,
  pisUpdatedBarSharedC: red,
  pisShareC: red,
  pisAirplaneC: white,
  pisTrain: {
    widthHeight: '36px',
    circleC: red,
    circleBg: red,
    color: white,
  },
  destination: {
    item: {
      secondBorderBottom: 'none',
    },
    title: {
      fontSize: {
        desktop: '12px',
        tablet: '12px',
        mobileL: '12px',
        mobileS: '12px',
      },
    },
    button: {
      background: red,
      backgroundHovered: darkRed,
      borderRadius: '5px',
    },
  },
  pisStationInfoAlertTextAlign: 'left',
  pisStationInfoLabelTextAlign: 'left',
  pisStationInfoLabelFontSize: '1.8rem',
  pisStationInfoLabelFontWeight: 500,
  pisStationItemContentBorderWidth: '0',
  pisStationItemContentMarginLeft: '1rem',
  pisStationFacilityItemBorderWidth: '0.1rem',
  pisFacilitiesListWidth: '45rem',
  pisFacilityItemContentPadding: '0',
  pisSharedMainIconC: orange,
  pisDeparturesHeaderC: black,
  pisDeparturesHeaderBg: shareBgColor,
  pisDeparturesHeaderBorderC: lightGrey,
  pisDeparturesHeaderHeight: '3.9rem',
  pisStationInfoIconW: '24px',
  pisStationInfoIconH: '3.9rem',
  pisJourneyListLineDisabledBg: grey,
  pisJourneyListMargin: '0 auto',
  pisDisabledPrimary: black,
  pisJourneyListPointFirstLastSize: '22px',
  pisJourneyListPointFirstLastBorderWidth: '0px',
  pisJourneyListPointFirstLastBg: black,
  pisJourneyListBottomPadding: '145px',
  pisTrainPulseAnimationLightPercent: '0%',
  pisTrainW: '36px',
  pisTrainLeft: '4px',
  pisTrainBottom: '15px',
  pisPlatformC: black,
  pisDisabledPlatformColor: black,
  pisPlatformNameFontSize: '14px',
  pisJourneyListItemTimeFontWeight: 500,
  pisJourneyListItemTimeFontSize: '16px',
  pisJourneyListItemTimeDelay: red,
  itemContent: {
    firstLine: {
      fontSize: '18px',
      fontWeight: 500,
    },
  },
  pisJourneyListInfoFontWeight: 'normal',
  pisJourneyListInfoMarginTop: '2px',
  pisJourneyListPointHeight: '4px',
  shareModal: {
    color: black,
    borderRadius: '0.8rem',
    title: {
      fontWeight: 500,
    },
    container: {
      border: '1px solid #d8d8d8',
    },
    divider: {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      margin: '10px auto',
    },
    message: {
      fontSize: '1.6rem',
      fontWeight: 500,
      padding: '2.7rem 1.5rem 1.8rem 1.5rem',
    },
    button: {
      background: red,
      padding: '1.2rem 0',
      fontSize: '1.6rem',
      color: white,
      cursor: 'pointer',
    },
  },
}

import { ITheme } from '@/themes/theme'

const toKebabCase = (text: string) => {
  return text
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

const getThemeEntries = (theme, prefix = '') => {
  return Object.entries(theme).reduce((acc, [key, value]) => {
    const propName = `${prefix}--${toKebabCase(key)}`
    if (value && typeof value === 'object') {
      const nested = getThemeEntries(value, propName)
      return [...acc, ...nested]
    } else {
      acc.push([propName, value])
    }
    return acc
  }, [])
}

const setDevCustomProperties = (element: HTMLElement) => {
  const shared = process.env.VUE_APP_SHAREMYTRAIN === 'true'
  if (!shared) {
    element.style.setProperty('--pis-header-height', '0px')
  }
}

export const setCustomPropertiesOnElement = (
  element: HTMLElement,
  theme: ITheme,
) => {
  if (!element) {
    console.error('No element has been provided for CSS custom properties')
    return
  }
  const themeEntries = getThemeEntries(theme)
  themeEntries.forEach(([key, value]) => {
    element.style.setProperty(key, value)
  })
  const isDev = process.env.NODE_ENV === 'development'
  if (isDev) {
    setDevCustomProperties(element)
  }
}

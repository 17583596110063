




import * as tsx from 'vue-tsx-support'
import { RadialGauge, RadialGaugeOptions } from 'canvas-gauges'
import { Component, Prop, Watch } from 'vue-property-decorator'

interface ISpeedometerProps {
  speed: number
}

@Component
export default class Speedometer extends tsx.Component<ISpeedometerProps> {
  @Prop() public speed: number
  @Watch('speed') public speedChanged(speed: number) {
    this.gauge.value = speed
    this.gauge.options.valueText = speed.toFixed(0)
    this.gauge.update(this.options)
  }
  private gauge
  private options: RadialGaugeOptions = {
    renderTo: 'speedometer',
    width: 230,
    height: 230,
    units: 'km/h',
    minValue: 0,
    maxValue: 260,
    valueInt: 1,
    valueDec: 0,
    majorTicks: [
      '0',
      '20',
      '40',
      '60',
      '80',
      '100',
      '120',
      '140',
      '160',
      '180',
      '200',
      '220',
      '240',
      '260',
    ],
    minorTicks: 4,
    numbersMargin: 3,
    strokeTicks: false,
    highlights: [],
    colorMajorTicks: '#000',
    colorMinorTicks: '#000',
    colorPlate: '#fff',
    colorUnits: '#000',
    borderShadowWidth: 0,
    borders: false,
    colorNumbers: '#535353',
    colorNeedle: 'rgb(0,0,0)',
    colorNeedleEnd: 'rgb(0,0,0)',
    colorNeedleShadowUp: 'transparent',
    colorNeedleShadowDown: 'transparent',
    needleType: 'arrow',
    needleStart: 15,
    needleEnd: 55,
    needleWidth: 2,
    colorValueText: '#00',
    colorValueTextShadow: 'transparent',
    colorValueBoxRect: 'transparent',
    colorValueBoxRectEnd: 'transparent',
    colorValueBoxBackground: 'transparent',
    colorValueBoxShadow: 'transparent',
    fontValueSize: 42,
    fontUnitsSize: 18,
    needleCircleSize: 4,
    colorNeedleCircleOuter: '#000',
    colorNeedleCircleOuterEnd: '#000',
    colorNeedleCircleInner: '#fff',
    colorNeedleCircleInnerEnd: '#fff',
    animationDuration: 1500,
    animationRule: 'linear',

    fontNumbers: 'SBB',
    fontTitle: 'SBB',
    fontUnits: 'SBB',
    fontValue: 'SBB Light',
  }

  public mounted() {
    this.gauge = new RadialGauge(this.options)
    this.speedChanged(this.speed)
    this.gauge.draw()
    // canvas gauge currently has an issue where it calles context.save() to add new drawings to stack
    // but never calls context.restore() to remove them
    // this leads to memeory blocks (not leak)
    // calling update re draws the canvas changing its context
  }
}








import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DisruptionMessage extends Vue {
  public alert: string

  created() {
    this.alert = localStorage.getItem('PIS_ALERT_MESSAGE')
  }
}

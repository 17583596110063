import {
  Configuration,
  VehiclePisServiceApi,
  PisFeedbackServiceApi,
  PisSubscriber,
  Feedback,
  SendFeedbackResponse,
  GetJourneyResponse,
  GetCurrentRouteResponse,
  GetJourneyStationsResponse,
  GetAllDeparturesResponse,
  TrainPosition,
  ShareMyTrainResponse,
  ApiServiceApi,
  GetVehicleInfoResponse,
} from '@gomedia-apis-ts-pis/v1'

import getDomain from '@/helpers/getDomain'

export class PISService {
  private vehicleApi: VehiclePisServiceApi
  private feedbackApi: PisFeedbackServiceApi
  private api: ApiServiceApi

  constructor() {
    const domain: string = getDomain()
    const config: Configuration = new Configuration({
      basePath: domain,
    })

    this.vehicleApi = new VehiclePisServiceApi(config)
    this.feedbackApi = new PisFeedbackServiceApi(config)
    this.api = new ApiServiceApi(config)
  }

  public async getSubscriber(): Promise<PisSubscriber> {
    return this.vehicleApi.getSubscriber()
  }

  public async getShareUrl(): Promise<ShareMyTrainResponse> {
    return this.vehicleApi.shareMyTrain()
  }

  public async getJourney(subscriberName = ''): Promise<GetJourneyResponse> {
    return this.vehicleApi.getJourney(subscriberName)
  }

  public async getRoute(): Promise<GetCurrentRouteResponse> {
    return this.vehicleApi.getCurrentRoute()
  }

  public async getVehicleInfo(): Promise<GetVehicleInfoResponse> {
    return this.vehicleApi.getVehicleInfo()
  }

  public async getSimpleJourney(): Promise<GetJourneyResponse> {
    return this.vehicleApi.getSimpleJourney()
  }

  public async getAllDepartures(
    callingPointId: string,
  ): Promise<GetAllDeparturesResponse> {
    return this.vehicleApi.getAllDepartures(callingPointId)
  }

  public async getJourneyStations(): Promise<GetJourneyStationsResponse> {
    return this.vehicleApi.getJourneyStations()
  }

  public async getJourneyByToken(token = ''): Promise<GetJourneyResponse> {
    return this.api.getShareMyTrainJourney(token)
  }

  public async getTrainPosition(): Promise<TrainPosition> {
    return this.vehicleApi.getPosition()
  }

  public async sendFeedback(feedback: Feedback): Promise<SendFeedbackResponse> {
    return this.feedbackApi.sendFeedback(feedback)
  }
}

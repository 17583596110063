import { Tiploc } from '@gomedia-apis-ts-pis/v1'
import { IPISContent, IFacility } from '@/constants/interfaces'

import { getNameI18n, getTiplocMetadata } from './item'
import { replacer } from '@/helpers/replacer'
import { Flavour } from '@/enums'

enum FacilityDisplayRequirements {
  ManchesterAiport = 'MNCRIAP',
  ManchesterCity = 'Manchester',
}

type Args = {
  LAT: number
  LNG: number
  ALERT: string
  CITY: string
  STATION: string
  TIPLOC: string
  TIPLOC_METADATA: string
}

const CONDITION: Record<string, (args: Args) => boolean> = {
  IS_MANCHESTER_AIRPORT: (args: Args) =>
    args.TIPLOC === FacilityDisplayRequirements.ManchesterAiport,
  IS_MANCHESTER_CITY: (args: Args) =>
    args.CITY === FacilityDisplayRequirements.ManchesterCity,
  HAS_METADATA: (args: Args) => Boolean(args.TIPLOC_METADATA),
  HAS_ALERT: (args: Args) => args.ALERT?.length > 0,
}

const createFacility = (
  label: string,
  url: string,
  icon: string,
  type: string,
  href = '_self',
  args: Args,
): IFacility => ({
  label,
  icon,
  href,
  url: replacer(url, args),
  isVisible: CONDITION[type] ? CONDITION[type](args) : true,
})

export const generateFacilities = (
  content: IPISContent,
  stationInfo: Tiploc,
  alert: string,
  language: string,
  flavour: Flavour,
): IFacility[] => {
  const args: Args = {
    LAT: stationInfo.latitude,
    LNG: stationInfo.longitude,
    CITY: stationInfo.city,
    ALERT: alert,
    STATION: getNameI18n(stationInfo.name, language),
    TIPLOC: stationInfo.tiploc,
    TIPLOC_METADATA: getTiplocMetadata(stationInfo.metadata, flavour),
  }

  if (content.STATION_FACILITIES?.ITEMS) {
    return content.STATION_FACILITIES.ITEMS.map((f) =>
      createFacility(f.LABEL, f.URL, f.ICON, f.CONDITION_TYPE, f.HREF, args),
    )
  }

  return generateDefaultFacilities(stationInfo, alert, language)
}

export const generateDefaultFacilities = (
  stationInfo: Tiploc,
  alert: string,
  language: string,
): IFacility[] => {
  return [
    {
      label: 'Disruption Message',
      icon: '/portal/images/pis/ic_alert.svg',
      url: '/pis/disruption-message',
      isVisible: alert.length > 0,
    },
    {
      label: 'Rail Departures',
      icon: '/portal/images/pis/ic_train.svg',
      url: `/pis/departures?type=train&TIPLOC=${stationInfo.tiploc}`,
      isVisible: true,
    },
  ]
}

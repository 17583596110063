






import { Flavour } from '@/enums'
import * as tsx from 'vue-tsx-support'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { store } from '@/store'
import FeedbackForm, {
  IFeedbackFormEvents,
} from '@/components/feedbackForm/index.vue'
import { IPISContent } from '@/constants/interfaces'

interface IPisFeedbackProps {
  language: string
  content: IPISContent
  router: any
  analytics: any
}

@Component({ components: { FeedbackForm } })
export default class PisFeedback extends tsx.Component<
  IPisFeedbackProps,
  IFeedbackFormEvents
> {
  @Prop() public language: string
  @Prop() public content: IPISContent
  @Prop() public router: any
  @Prop() public analytics: any
  @Prop() public flavour: Flavour

  @Watch('language') public languageChanged(newVal, oldVal) {
    store.dispatch('changeLanguage', newVal || oldVal)
  }

  @Watch('content') public contentChanged(newVal, oldVal) {
    store.dispatch('changeContent', newVal || oldVal)
  }

  @Watch('router') public routerChanged(newVal, oldVal) {
    store.dispatch('changeRouter', newVal || oldVal)
  }

  mounted() {
    store.dispatch('setThemeByFlavour', {
      flavour: this.flavour,
      element: this.$el,
    })
    if (this.language) {
      store.dispatch('changeLanguage', this.language)
    }
    if (this.content) {
      store.dispatch('changeContent', this.content)
    }
    if (this.router) {
      store.dispatch('changeRouter', this.router)
    }
  }
}








import { Component, Prop } from 'vue-property-decorator'
import { store } from '@/store'
import * as tsx from 'vue-tsx-support'

@Component
export default class PisLink extends tsx.Component<{ link: string }> {
  @Prop({ required: true, type: String }) readonly link: string

  public pushRouter(): void {
    store.dispatch('pushRouter', { link: this.link })
  }
}








import { PIS_CONFIG } from '@/constants'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { store } from '@/store'

@Component
export default class FeedbackButton extends tsx.Component<{
  analytics: any
}> {
  @Prop() public analytics: any

  public get feedbackContent() {
    return store.getters.getContent.FEEDBACK
  }

  public trackButtonClickEvent(): void {
    try {
      if (!this.analytics) {
        throw new Error('analytics is not available')
      }
      this.analytics.eventTrack(
        PIS_CONFIG.ANALYTICS_EVENTS.PIS_LEAVE_FEEDBACK_CLICK,
      )
    } catch (e) {
      console.error(e)
    }
  }

  buttonAction(): void {
    const params = this.feedbackContent.BUTTON_REDIRECT
    this.trackButtonClickEvent()
    if (params && params.LINK) {
      store.dispatch('pushRouter', { link: params.LINK, target: params.TARGET })
      return
    }
    store.dispatch('cacheJourneyData', store.getters.getCopyJourneyResponse)
    store.dispatch('pushRouter', { link: '/pis/feedback' })
  }
}

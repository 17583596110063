export const isFirst = (index: number): boolean => index === 0
export const isMiddle = (index: number, length: number): boolean =>
  index !== 0 && index !== length - 1
export const isLast = (index: number, length: number): boolean =>
  index !== 0 && index === length - 1
export const hasSeats = (seats: string): boolean => {
  try {
    return parseInt(seats || '0', 10) > 0
  } catch {
    return false
  }
}

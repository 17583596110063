




import { Flavour } from '@/enums'
import { store } from '@/store'
import { Vue } from 'vue-property-decorator'
import VueRouter, { RouterOptions } from 'vue-router'
import { EPisType } from '@/constants/interfaces'
import { PIS_CONFIG, PIS_CONTENT } from '@/constants'

Vue.use(VueRouter)

import Pis from '@/containers/pis/index.vue'
import PisFeedback from '@/containers/pisFeedback/index.vue'
import PisSpeedometer from '@/containers/pisSpeedometer'
import PisDestination from '@/containers/pisDestination/index.vue'
import PisNetworkSignal from '@/containers/pisNetworkSignal'
import ShareMyTrainApp from './containers/shareMyTrainApp/index.vue'

import { push } from '@/helpers/locationHelpers'

const flavour = process.env.VUE_APP_THEME
const amPm = false
const platformsDisabled = false
const attributionDisabled = false
const showShare = true
const showVehicleIcons = false
const showStationLabels = true
const showBackToAppButton = false
const analytics = {
  eventTrack(name, data) {
    console.log(`[DEV]: Event - ${name}, data - `, data)
  },
}
const showWeather = flavour === Flavour.CPA || flavour === Flavour.SPA
const hideAlerts = PIS_CONFIG.DEFAULT_HIDE_ALERTS
const showFeedbackHref = PIS_CONFIG.DEFAULT_SHOW_FEEDBACK_HREF
const mapConfig = PIS_CONFIG.DEFAULT_MAP_CONFIG
const router = { push }

const pisProps = {
  router,
  amPm,
  flavour,
  showBackToAppButton,
  hideAlerts,
  platformsDisabled,
  attributionDisabled,
  showShare,
  analytics,
  showFeedbackHref,
  showStationLabels,
  componentType: EPisType.journey,
}
const pisStationInformationProps = {
  router,
  flavour,
  platformsDisabled,
  componentType: EPisType.stationInformation,
}
const pisDisruptionMessageProps = {
  router,
  flavour,
  componentType: EPisType.disruptionMessage,
}
const pisDeparturesProps = {
  router,
  amPm,
  flavour,
  analytics,
  platformsDisabled,
  showVehicleIcons,
  componentType: EPisType.departures,
}
const pisMapProps = {
  router,
  mapConfig,
  flavour,
  platformsDisabled,
  footerDisabled: true,
  componentType: EPisType.map,
}
const pisTrainInfoProps = {
  router,
  flavour,
  platformsDisabled,
  componentType: EPisType.vehicleInformation,
}
const pisDestinationInfoProps = {
  router,
  flavour,
  showWeather,
  amPm,
  showButtons: true,
}
const pisFeedbackProps = { router, flavour, analytics }
const pisSpeedometerProps = { router }
const pisNetworkSignalProps = { content: PIS_CONTENT }

const devRoutes = [
  {
    path: '*',
    component: Pis,
    props: pisProps,
  },
  {
    path: '/pis',
    component: Pis,
    props: pisProps,
  },
  {
    path: '/pis/info',
    component: Pis,
    props: pisStationInformationProps,
  },
  {
    path: '/pis/disruption-message',
    component: Pis,
    props: pisDisruptionMessageProps,
  },
  {
    path: '/pis/departures',
    component: Pis,
    props: pisDeparturesProps,
  },
  {
    path: '/pis/map',
    component: Pis,
    props: pisMapProps,
  },
  {
    path: '/pis/train-info',
    component: Pis,
    props: pisTrainInfoProps,
  },
  {
    path: '/pis/destination-info',
    component: PisDestination,
    props: pisDestinationInfoProps,
  },
  {
    path: '/pis/feedback',
    component: PisFeedback,
    props: pisFeedbackProps,
  },
  {
    path: '/pis/network-signal',
    component: PisNetworkSignal,
    props: pisNetworkSignalProps,
  },
  {
    path: '/pis/speedometer',
    component: PisSpeedometer,
    props: pisSpeedometerProps,
  },
]

const shareMyTrainRoutes = [
  {
    path: '*',
    component: ShareMyTrainApp,
  },
]

const isShareMyTrain = process.env.VUE_APP_SHAREMYTRAIN === 'true'

const shareMyTrainRouter: RouterOptions = {
  routes: shareMyTrainRoutes,
  mode: 'history', // removes # (hashtag) from url
  base: '/',
  fallback: true,
}

const devRouter: RouterOptions = {
  routes: devRoutes,
}

export default {
  router: new VueRouter(isShareMyTrain ? shareMyTrainRouter : devRouter),
  created() {
    if (!isShareMyTrain) {
      store.dispatch('requestTypography', flavour)
    }
  },
}

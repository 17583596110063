import { Flavour } from '@/enums'
import { cssRaw } from '@/helpers/cssRaw'
import { applyTypography } from '@/helpers/typography'
import {
  PisSubscriber,
  Feedback,
  GetJourneyResponse,
  TrainPosition,
  ShareMyTrainResponse,
  GetCurrentRouteResponse,
  GetAllDeparturesResponse,
  GetVehicleInfoResponse,
  GetJourneyStationsResponse,
} from '@gomedia-apis-ts-pis/v1'
import { PISService } from '@/services/pisService'
import types from './types'
import { WeatherService } from '@/services/weatherService'
import { PIS_CONFIG, PIS_CONTENT } from '@/constants'
import { ContentService } from '@/services/contentService'

const pisService = new PISService()
const weatherService = new WeatherService()
const contentService = new ContentService()

export default {
  async fetchSubscriber(): Promise<PisSubscriber> {
    return await pisService.getSubscriber()
  },
  async fetchJourney({ commit }): Promise<GetJourneyResponse> {
    commit(types.REQUEST_JOURNEY)
    try {
      const journey: GetJourneyResponse = await pisService.getSimpleJourney()
      commit(types.RECEIVE_JOURNEY, journey)
      return journey
    } catch (error) {
      commit(types.FAIL_JOURNEY, error)
      return {}
    }
  },
  async fetchRoute(): Promise<GetCurrentRouteResponse> {
    return await pisService.getRoute()
  },
  async fetchJourneyStations({ commit }): Promise<GetJourneyStationsResponse> {
    const journeyStations = await pisService.getJourneyStations()
    commit(types.SET_JOURNEY_STATIONS, journeyStations)
    return journeyStations
  },
  async fetchVehicleInfo(): Promise<GetVehicleInfoResponse> {
    return await pisService.getVehicleInfo()
  },
  async fetchStationDepartures(
    _,
    callingPointId: string,
  ): Promise<GetAllDeparturesResponse> {
    return await pisService.getAllDepartures(callingPointId)
  },
  async fetchJourneyByToken({ commit }, token: string) {
    commit(types.REQUEST_JOURNEY)
    try {
      const journey: GetJourneyResponse = await pisService.getJourneyByToken(
        token,
      )
      commit(types.RECEIVE_JOURNEY, journey)
      return journey
    } catch (error) {
      commit(types.FAIL_JOURNEY, error)
      throw error
    }
  },
  cacheJourneyData({ commit }, cachedJourneyResponse: GetJourneyResponse) {
    commit(types.CLEAR_CACHED_JOURNEY)
    commit(types.CACHE_JOURNEY, cachedJourneyResponse)
  },
  clearCachedJourneyData({ commit }) {
    commit(types.CLEAR_CACHED_JOURNEY)
  },
  async fetchShareUrl({ commit }) {
    commit(types.REQUEST_SHARE_URL)
    try {
      const shareMyTrainResponse: ShareMyTrainResponse = await pisService.getShareUrl()

      commit(types.RECEIVE_SHARE_URL, shareMyTrainResponse)
    } catch (error) {
      commit(types.FAIL_SHARE_URL, error)
    }
  },
  async fetchTrainPosition({ commit }) {
    commit(types.REQUEST_TRAIN_POSITION)
    try {
      const trainPosition: TrainPosition = await pisService.getTrainPosition()
      commit(types.RECEIVE_TRAIN_POSITION, trainPosition)
    } catch (error) {
      commit(types.FAIL_TRAIN_POSITION, error)
    }
  },
  async submitFeedback(_, params: { feedback: Feedback; analytics: any }) {
    if (params.analytics) {
      params.analytics.eventTrack(
        PIS_CONFIG.ANALYTICS_EVENTS.PIS_FEEDBACK,
        params.feedback,
      )
    } else {
      throw new Error('analytics is not available')
    }
  },
  async fetchWeather({ commit }, { tiploc, flavour }) {
    commit(types.REQUEST_WEATHER)
    try {
      const weather: GetJourneyResponse = await weatherService.getWeather(
        tiploc,
        flavour,
      )
      commit(types.RECEIVE_WEATHER, {
        weather,
        tiploc,
      })
    } catch (error) {
      commit(types.FAIL_WEATHER, error)
    }
  },
  setThemeByFlavour(
    { commit },
    { flavour, element }: { flavour: Flavour; element: HTMLElement },
  ) {
    commit(types.SET_PREFERRED_FLAVOUR, flavour)
    commit(types.SET_THEME_BY_FLAVOUR, { flavour, element })
  },
  toggleSplitter({ commit }, payload) {
    commit(types.TOGGLE_SPLITTER, payload)
  },
  changeLanguage({ commit }, payload) {
    commit(types.CHANGE_LANGUAGE, payload)
  },
  changeContent({ commit }, payload) {
    commit(types.CHANGE_CONTENT, payload)
  },
  changeRouter({ commit }, payload) {
    commit(types.CHANGE_ROUTER, payload)
  },
  pushRouter({ commit }, payload) {
    commit(types.PUSH_ROUTER, payload)
  },
  backRouter({ commit }) {
    commit(types.BACK_ROUTER)
  },
  pollingStart(
    { commit },
    payload: { action: string; requestTimeout?: number; token?: string },
  ) {
    commit(types.POLLING_START, payload)
  },
  pollingStop({ commit }, actionName: string) {
    commit(types.POLLING_STOP, actionName)
  },
  getSMTConfig({ commit }, flavour: Flavour) {
    return contentService.getSMTConfig(flavour)
  },
  async requestSMTContent({ dispatch }, language: string) {
    try {
      const content = await contentService.getSMTContent(language)
      dispatch('changeContent', content)
    } catch (e) {
      dispatch('changeContent', PIS_CONTENT)
    }
  },
  async requestTypography(_, flavour: Flavour) {
    const typography = await contentService.getTypography(flavour)
    await applyTypography(typography)
    cssRaw(`body * {font-family: ${typography.globalFont};}`)
  },
  async requestPointsOfInterest() {
    return contentService.getPointsOfInterest()
  },
}

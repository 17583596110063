enum Flavour {
  AVT = 'avt',
  SBB = 'sbb',
  BB = 'bb',
  TPE = 'tpe',
  GH = 'gh',
  ECTL = 'ectl',
  FTEU = 'fteu',
  FBEU = 'fbeu',
  FBUS = 'fbus',
  VY = 'vy',
  TFW = 'tfw',
  GV = 'gv',
  ZA = 'za',
  CPA = 'cpa',
  SPA = 'spa',
  QR = 'qr',
}

export { Flavour }

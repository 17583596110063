export default {
  POLLING_START: 'POLLING_START',
  POLLING_STOP: 'POLLING_STOP',
  REQUEST_JOURNEY: 'REQUEST_JOURNEY',
  RECEIVE_JOURNEY: 'RECEIVE_JOURNEY',
  SET_PREFERRED_FLAVOUR: 'SET_PREFERRED_FLAVOUR',
  SET_THEME_BY_FLAVOUR: 'SET_THEME_BY_FLAVOUR',
  FAIL_JOURNEY: 'FAIL_JOURNEY',
  CACHE_JOURNEY: 'CACHE_JOURNEY',
  CLEAR_CACHED_JOURNEY: 'CLEAR_CACHED_JOURNEY',
  REQUEST_SHARE_URL: 'REQUEST_SHARE_URL',
  RECEIVE_SHARE_URL: 'RECEIVE_SHARE_URL',
  FAIL_SHARE_URL: 'FAIL_SHARE_URL',
  REQUEST_TRAIN_POSITION: 'REQUEST_TRAIN_POSITION',
  RECEIVE_TRAIN_POSITION: 'RECEIVE_TRAIN_POSITION',
  FAIL_TRAIN_POSITION: 'FAIL_TRAIN_POSITION',
  TOGGLE_SPLITTER: 'TOGGLE_SPLITTER',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  CHANGE_CONTENT: 'CHANGE_CONTENT',
  CHANGE_ROUTER: 'CHANGE_ROUTER',
  PUSH_ROUTER: 'PUSH_ROUTER',
  BACK_ROUTER: 'BACK_ROUTER',
  REQUEST_WEATHER: 'REQUEST_WEATHER',
  RECEIVE_WEATHER: 'RECEIVE_WEATHER',
  FAIL_WEATHER: 'FAIL_WEATHER',
  SET_JOURNEY_STATIONS: 'SET_JOURNEY_STATIONS',
}

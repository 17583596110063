import { ITheme } from './theme'

const coalescent = '#131e29'
const coniston = '#95e1bf'
const graphene = '#204555'
const warmGrey = '#d6d1ca'
const spark = '#ff4713'

const headerHeight = '5.8rem'
const updatedBarHeight = '50px'

export const AVT_THEME: ITheme = {
  feedbackButton: {
    height: '44px',
    backgroundColor: spark,
    color: 'black',
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: '3px',
  },
  footer: {
    padding: '20px',
    backgroundColor: '#f1f1f1',
    logosContainer: {
      padding: '0 0 20px',
    },
  },
  departureItem: {
    padding: 0,
    time: {
      fontSize: '16px',
    },
    details: {
      fontWeight: 'normal',
    },
  },
  pisSplitSpanBorderColor: spark,
  pisSplitSpanC: coalescent,
  pisJourneyListSplitterBg: warmGrey,
  pisJourneyListSplitterActiveBg: '#ddd9d4',
  pisLibBodyBg: '#f1f1f1',
  pisJourneyListBottomPadding: '138px',
  pisJourneyListBg: '#f1f1f1',
  pisContainerBg: '#f1f1f1',
  pisHeaderHeight: headerHeight,
  pisBorderC: warmGrey,
  pisEmptyListC: '#1e234f',
  pisJourneyListStationFontWeight: 600,
  pisJourneyListItemTimeFontWeight: 'normal',
  pisJourneyListLineWidth: '6px',
  pisJourneyListPointHeight: '18px',
  pisJourneyListPointWidth: '18px',
  pisJourneyListPointBg: '#f1f1f1',
  pisJourneyListPointDisabledBg: '#f1f1f1',
  pisJourneyListPointFirstLastSize: '24px',
  pisJourneyListPointFirstLastBg: '#f1f1f1',
  pisJourneyListPointCurrentUpcomingTop: '-25%',
  pisJourneyListPointLastCurrentUpcomingTop: '-25%',
  pisTrainW: '38px',
  pisTrainBottom: '12px',
  pisTrainLeft: '1px',
  pisPrimary: coalescent,
  pisCancelledC: '#eb0000',
  pisArrowStrokeWidth: '2.5px',
  pisArrowC: spark,
  pisDeparturesHeaderC: graphene,
  pisJourneyListStationC: coalescent,
  pisDisabledPrimary: coalescent,
  pisStationInfoLabelFontWeight: 'normal',
  pisTrain: {
    color: '#eb0000',
    circleC: '#eb0000',
    circleBg: '#eb0000',
    widthHeight: '38px',
  },
  pisUpdatedBarHeight: updatedBarHeight,
  pisUpdatedBarBg: coniston,
  pisUpdatedBarBorderBottomC: coniston,
  pisEmptyListFontWeight: 400,
  pisJourneyListTimeFontWeight: 'normal',
  pisJourneyListLineHeigth: '3rem',
  pisJourneyListPointCurrentUpcommingTop: '-25%',
  pisJourneyListPointLastCurrentUpcommingTop: '-25%',
  pisJourneyListPointFirstLastWidth: '1.1rem',
  pisDelayC: coalescent,
  pisDelayBg: '#f4ca40',
  pisDelayPadding: '2px 2px 1px',
  pisDelayFontWeight: 600,
  pisJourneyListLineBg: graphene,
  pisJourneyListPointFirstLastBorderWidth: '5px',
  pisJourneyListLineDisabledBg: warmGrey,
  pisStationNameFontSize: '18px',
  pisPlatformNameFontSize: '14px',
  pisPlatformC: '#2e3947',
  stationPadding: '0 0 0.8rem',
  pisJourneyListArrowMargin: '0 12px 0 5px',
  pisJourneyListArrowHeight: '16px',
  pisDeparturesHeaderBg: coniston,
  pisDisabledDelayC: graphene,
  pisDisabledDelayIconC: 'rgb(255, 140, 0)',
  pisJourneyListItemTimeDelay: coalescent,
}

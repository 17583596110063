import { Flavour } from '@/enums'
import {
  IConfig,
  IPISContent,
  IShareMyTrainPisConstants,
  IShareMyTrainPisConstantsMapToFlavour,
} from './interfaces'

export const PIS_CONFIG: IConfig = {
  MAX_DELAY: 60000,
  FALLBACK_TIMEZONE: 'Europe/London',
  CACHED_JOURNEY_KEY: 'CACHED_JOURNEY_KEY',
  DEFAULT_HIDE_ALERTS: false,
  DEFAULT_SHOW_FEEDBACK_HREF: true,
  DEFAULT_MAP_CONFIG: {
    URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    // URL: '/maps/{z}/{x}/{y}.png',
    ZOOM: 10,
    MIN_ZOOM: 4,
    MAX_ZOOM: 13,
    // ZOOM_CYCLE: [5, 8, 13],
    // ZOOM_CYCLE_INTERVAL: 10000,
    DEFAULT_COORDS: {
      LATITUDE: -24.752693,
      LONGITUDE: 150.886939,
    },
    DEFAULT_MAX_BOUNDS: [
      [77.0, -150.0],
      [-55.0, 172.0],
    ],
    MARKER: {
      ICON: 'portal/images/flavours/tfw/ic_train.svg',
    },
    POINTS_OF_INTEREST: {
      ENABLED: false,
      MARKER_WIDTH: 35,
      MARKER_HEIGHT: 50,
      MARKER_ICON:
        'https://cdn.zeplin.io/5ea02ef60480db7de99412e0/assets/9BE7C5C7-166E-4124-AF3B-D029A9AF27D1.svg',
    },
    ROUTE: {
      ENABLED: false,
      COLOR: '#3395ce',
      OPACITY: 1,
      WIDTH: 5,
    },
    MAP_STATION_STOPS: {
      ENABLED: false,
      STATIONS_VISIBILITY_ZOOM_LEVEL: 6,
      TOOLTIPS_VISIBILITY_ZOOM_LEVEL: 8,
    },
    LIGHT_SPEEDOMETER: {
      ENABLED: false,
      ICON:
        'https://cdn.zeplin.io/5ea02ef60480db7de99412e0/assets/6E5BA7B3-F340-4723-9626-68883EB1DF8D.svg',
    },
    NEXT_STATION_INFO_BAR: false,
    SAVE_PREVIOUS_MAP_STATE: true,
    ZOOM_CONTROL_DISABLED: false,
    CURRENT_POSITION_CONTROL_ENABLED: true,
  },
  DEFAULT_SPEED: 0,
  ANALYTICS_EVENTS: {
    PIS_FEEDBACK: 'PIS_FEEDBACK',
    PIS_LEAVE_FEEDBACK_CLICK: 'PIS_LEAVE_FEEDBACK_CLICK',
  },
}

export const PIS_CONTENT: IPISContent = {
  DEPARTURES: {
    TRANSPORT: 'Transport',
    DEPARTURE: 'Departure',
    DEPART: 'Depart.',
    DESTINATION: 'Destination',
    PLATFORM: 'Platform',
    PLAT: 'Plat.',
    TERMINAL: 'Terminal',
    TERM: 'Term.',
    FLIGHT: 'Flight',
    DELAYED: 'Delayed',
    GATE: 'Boarding Gate',
    CANCELLED: 'Cancelled',
    NO_DEPARTURES_AVAILABLE: 'No departures available',
    TRAIN_ICON: '',
    VEHICLE_ICON: {
      IC_1: '',
    },
  },
  STATION_LABELS: {},
  STATION_FACILITIES: {
    NO_INFO: 'Sorry, there is no information about this station.',
    ITEMS: [
      {
        CONDITION_TYPE: 'HAS_ALERT',
        LABEL: 'Disruptions & Alerts',
        URL: '/pis/disruption-message',
        ICON: '/portal/images/pis/ic_alert.svg',
      },
      {
        LABEL: 'Rail departures',
        URL: '/pis/departures?type=train&TIPLOC={TIPLOC}',
        ICON: '/portal/images/pis/ic_train.svg',
      },
      {
        LABEL: 'Bus departures',
        URL: 'https://bustimes.org/map#{LAT},{LNG}',
        ICON: '/portal/images/pis/ic_bus.svg',
        HREF: '_blank',
      },
      {
        CONDITION_TYPE: 'IS_MANCHESTER_AIRPORT',
        LABEL: 'Airport Departures',
        URL: '/pis/departures?type=airport&TIPLOC={TIPLOC}',
        ICON: '/portal/images/pis/ic_train.svg',
      },
    ],
  },
  DATA_LOADER: {
    SERVER_UNAVAILABLE: 'Server is currently unavailable',
  },
  JOURNEY_LIST: {
    NO_JOURNEY_AVAILABLE: 'No journey available',
    CANCELLED: 'Cancelled',
    PLATFORM_NUM: 'Platform',
    NO_TIME: 'Unfortunately, we did not receive time for this station',
    SPLITTING: 'Train splitting',
    TOWARDS: 'Towards',
  },
  FEEDBACK: {
    LABEL: 'Leave Feedback',
    TITLE: 'Passenger Information System Feedback',
    SUBMIT: 'Submit Report',
    LOADING: 'Loading...',
    ISSUE: {
      LABEL:
        'Are there any issues of the Passenger Information System that you would like to report?',
      PLACEHOLDER: 'Write your feedback here',
      ERROR_LABEL: 'Please describe your issue',
    },
    NOTIFICATIONS: {
      SUCCESS: {
        TITLE: 'Success',
        MESSAGE: 'Thank you for the feedback!',
      },
      ERROR: {
        TITLE: 'Error',
      },
      LINK_COPIED_SUCCESS: 'Link copied to clipboard',
    },
  },
  DESTINATION: {
    TRAIN_NAME: '',
    NEXT_STOP: 'Next stop',
    ARRIVAL: 'Arrival',
    DESTINATION_TITLE: 'Destination',
    BUTTONS: {
      INFO: {
        ICON: '',
        TEXT: 'Live Journey',
        WIDTH: 15,
      },
      SHARE: {
        ICON: '',
        TEXT: 'Share Journey',
        WIDTH: 18,
      },
    },
    ECO_CALCULATOR: {
      TITLE: 'Eco Calculator',
      EXPLANATION_TEXT: 'Your savings compared to a car.',
      REDIRECT_LINK: '/',
      ICONS: {
        GREEN_LEAF: '/portal/images/flavours/sbb/eco-calculator/green-leaf.svg',
        CO2: '/portal/images/flavours/sbb/eco-calculator/co-2.svg',
        FUEL: '/portal/images/flavours/sbb/eco-calculator/fuelpump.svg',
        TIME: '/portal/images/flavours/sbb/eco-calculator/clock.svg',
      },
    },
  },
  TRAIN_INFO: {
    COACH: 'Coach',
    INFO_UNAVAILABLE: 'Train info is currently unavailable',
  },
  NETWORK_SIGNAL: {
    '-1': {
      ICON: '',
      BACKGROUND_COLOR: '#db1f26',
      TEXT_COLOR: '#ffffff',
      TEXT: 'Internet signal is unavailable, please try again later ',
    },
    0: {
      ICON: '',
      BACKGROUND_COLOR: 'transparent',
      TEXT_COLOR: '',
      TEXT: '',
    },
    1: {
      ICON: '',
      BACKGROUND_COLOR: '#db1f26',
      TEXT_COLOR: '#ffffff',
      TEXT: 'Internet signal is very weak, perhaps try again later ',
    },
    2: {
      ICON: '',
      BACKGROUND_COLOR: '#696969',
      TEXT_COLOR: '#ffffff',
      TEXT: 'Internet signal is weak, internet might be  slow',
    },
    3: {
      ICON: '',
      BACKGROUND_COLOR: '#696969',
      TEXT_COLOR: '#ffffff',
      TEXT: 'Internet signal is moderate, internet might be slow',
    },
    4: {
      ICON: '',
      BACKGROUND_COLOR: '#008847',
      TEXT_COLOR: '#ffffff',
      TEXT:
        'Internet signal is good; a good time in your journey to get online',
    },
    5: {
      ICON: '',
      BACKGROUND_COLOR: '#007bc2',
      TEXT_COLOR: '#ffffff',
      TEXT:
        'Internet signal is very good; a great time in your journey to get online',
    },
  },
  BACK_TO_APP: {
    BUTTON_TEXT: 'Back to the App',
  },
  UPDATED_BAR: {
    SHARE: 'Share my Train',
    SHARE_BUTTON_TITLE: 'Share my Train',
    SHARE_POPUP: {
      TITLE: 'Share your live journey',
      DESCRIPTION:
        'Choose one of the options below to send your live train info to your loved ones:',
      MESSAGE:
        'Hello, I’m on a TransPennine Express train. Check my live train info at',
      WHATSAPP: 'WhatsApp',
      EMAIL: 'Email',
      COPY: 'Copy',
      CLOSE_ICON: '',
    },
  },
  MAP: {
    ATTRIBUTIONS: [
      '<a href="https://www.openstreetmap.org/copyright" target="_blank" class="top-right-tooltip">© OpenStreetMap contributors</a>',
      '<a href="https://www.maptiler.com/copyright" target="_blank" class="top-right-tooltip">© MapTiler</a>',
    ],
    IFRAME_TITLE: 'Live Map',
  },
}

const basicShareMyTrainConstants: IShareMyTrainPisConstants = {
  CONFIG: {
    PLATFORMS_DISABLED: false,
    AM_PM_TIME: false,
  },
}

const shareMyTrainConstantsForCpaAndSpa: IShareMyTrainPisConstants = {
  CONFIG: {
    PLATFORMS_DISABLED: true,
    AM_PM_TIME: true,
  },
}

const shareMyTrainConstantsForTFW: IShareMyTrainPisConstants = {
  CONFIG: {
    LANGUAGE_SWITCHER: true,
    SHOW_FOOTER: true,
  },
}

export const SHARE_MY_TRAIN_CONSTANTS: IShareMyTrainPisConstantsMapToFlavour = {
  [Flavour.GV]: basicShareMyTrainConstants,
  [Flavour.TFW]: shareMyTrainConstantsForTFW,
  [Flavour.CPA]: shareMyTrainConstantsForCpaAndSpa,
  [Flavour.SPA]: shareMyTrainConstantsForCpaAndSpa,
}













import { Flavour } from '@/enums'
import * as tsx from 'vue-tsx-support'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { store } from '@/store'

import DataLoader, {
  IDataLoaderEvents,
} from '@/components/dataLoader/index.vue'
import { ILoadOption, IPISContent } from '@/constants/interfaces'
import DestinationInfo from '@/components/destinationInfo/index.vue'

interface IPisDestinationProps {
  content: IPISContent
  language: string
  router: any
  flavour: string
  showWeather: boolean
  showButtons: boolean
  amPm?: boolean
}

@Component({ components: { DataLoader } })
export default class PisDestination extends tsx.Component<
  IPisDestinationProps,
  IDataLoaderEvents
> {
  @Prop() public content: IPISContent
  @Prop() public language: string
  @Prop() public router: any
  @Prop() public flavour: Flavour
  @Prop() public showWeather: boolean
  @Prop() public showButtons: boolean
  @Prop() public amPm: boolean

  @Watch('language') public languageChanged(newVal, oldVal) {
    store.dispatch('changeLanguage', newVal || oldVal)
  }

  @Watch('content') public contentChanged(newVal, oldVal) {
    store.dispatch('changeContent', newVal || oldVal)
  }

  @Watch('router') public routerChanged(newVal, oldVal) {
    store.dispatch('changeRouter', newVal || oldVal)
  }

  public get options(): ILoadOption {
    return {
      flavour: this.flavour,
      content: store.getters.getContent,
      language: store.getters.getLanguage,
      actionName: 'fetchJourney',
      requestTimeout: 10000,
      showWeather: this.showWeather,
      amPm: this.amPm,
      showShare: false,
      showButtons: this.showButtons,
    }
  }

  public get component() {
    return DestinationInfo
  }

  public get containerClasses() {
    const isDev = process.env.NODE_ENV === 'development'
    return {
      'container-destination-info-dev': isDev,
    }
  }

  mounted() {
    store.dispatch('setThemeByFlavour', {
      flavour: this.flavour,
      element: this.$el,
    })
    if (this.language) {
      store.dispatch('changeLanguage', this.language)
    }
    if (this.content) {
      store.dispatch('changeContent', this.content)
    }
    if (this.router) {
      store.dispatch('changeRouter', this.router)
    }
  }
}

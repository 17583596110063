export enum Language {
  EN = 'en',
  CY = 'cy',
}

export const LANG_QUERY_NAME = 'lang'

export const LanguageName = {
  [Language.EN]: 'English',
  [Language.CY]: 'Cymraeg',
}

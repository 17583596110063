














































import * as tsx from 'vue-tsx-support'
import { Component, Prop } from 'vue-property-decorator'
import { store } from '@/store'

import { AirportDeparture, TrainDeparture } from '@gomedia-apis-ts-pis/v1'

import { IPISContent, IDeparturesOption } from '@/constants/interfaces'
import {
  isDelay,
  getTime,
  getDelay,
  getNameI18n,
  getDepartureAsDisplayCallingPoint,
  EDepartureType,
  getPlatform,
  getVia,
} from '@/helpers/item'
import { formatTimeToAmPm } from '@/helpers/destination'

interface IDepartureItemProps {
  item: AirportDeparture | TrainDeparture
  isFirstStation: boolean
  isLastStation: boolean
  options: IDeparturesOption
}

@Component
export default class DepartureItem extends tsx.Component<IDepartureItemProps> {
  private isDeparture = true

  @Prop() public item: AirportDeparture | TrainDeparture
  @Prop() public isFirstStation: boolean
  @Prop() public isLastStation: boolean
  @Prop() public options: IDeparturesOption
  @Prop() public showVehicleIcons: boolean

  public get content(): IPISContent {
    return store.getters.getContent
  }
  public get language(): string {
    return store.getters.getLanguage
  }

  public get departureTimeClasses() {
    return {
      'departure-time': true,
      'departure-time_am-pm': this.options.amPm,
    }
  }

  public get departureItemClasses() {
    return {
      'departure-item': true,
      'departure-item_vehicle-icons': this.showVehicleIcons,
    }
  }

  public get departureTimeStrikeClasses() {
    return {
      'departure-time-strike': this.isDelay || this.cancelled,
    }
  }

  public get departureInfoClasses() {
    return {
      'departure-info': true,
      'departure-info_canceled': this.cancelled,
    }
  }

  public get trainIcon() {
    return this.content.DEPARTURES?.TRAIN_ICON
  }

  public get vehicleIcon() {
    const { metadata } = this.itemAsTrainDeparture
    const { VEHICLE_ICON } = this.content.DEPARTURES
    if (!metadata || !VEHICLE_ICON) {
      return ''
    }
    const { sbb_category_short_form, sbb_line } = metadata
    const key = `${sbb_category_short_form}${sbb_line ? '_' + sbb_line : ''}`
    return VEHICLE_ICON[key] || ''
  }

  public get time(): string {
    const time = getTime(
      getDepartureAsDisplayCallingPoint(this.item),
      this.isFirstStation,
      this.isLastStation,
      this.isDeparture,
    )
    return formatTimeToAmPm(time, this.options.amPm)
  }

  public get delay(): string {
    const delay = getDelay(
      getDepartureAsDisplayCallingPoint(this.item),
      this.isFirstStation,
      this.isLastStation,
      this.isDeparture,
    )
    return formatTimeToAmPm(delay, this.options.amPm)
  }

  public get isDelay(): boolean {
    return (
      isDelay(
        getDepartureAsDisplayCallingPoint(this.item),
        this.isFirstStation,
        this.isLastStation,
        this.isDeparture,
      ) && !this.cancelled
    )
  }

  public get isRail(): boolean {
    return this.options.departureType === EDepartureType.train
  }

  public get isAirport(): boolean {
    return this.options.departureType === EDepartureType.airport
  }

  public get name(): string {
    return getNameI18n(this.item.destination_name, this.language)
  }

  public get via(): string {
    return getVia(this.item)
  }

  public get nameLabel(): string {
    return `${this.name} ${this.via}`
  }

  public get platform(): string {
    return this.options.showPlatforms
      ? getPlatform(this.itemAsTrainDeparture)
      : ''
  }

  public get flight(): string {
    return this.itemAsAirportDeparture.flight_number
  }

  public get terminal(): string {
    return this.itemAsAirportDeparture.terminal
  }

  public get gate(): string {
    return this.itemAsAirportDeparture.gate
  }

  public get info(): string {
    switch (this.options.departureType) {
      case EDepartureType.airport:
        return this.cancelled
          ? this.content.DEPARTURES.CANCELLED
          : this.gate
          ? `${this.content.DEPARTURES.GATE} ${this.gate}`
          : ''
      case EDepartureType.train:
        return this.cancelled ? this.content.DEPARTURES.CANCELLED : ''
      default:
        return ''
    }
  }

  public get cancelled(): boolean {
    return this.item.cancelled || false
  }

  public get delayed(): boolean {
    return (this.isAirport && this.isDelay) || false
  }

  public get id(): string {
    return this.isAirport
      ? this.itemAsAirportDeparture.destination_airport_code
      : this.itemAsTrainDeparture.destination_id.id
  }

  private get itemAsTrainDeparture(): TrainDeparture {
    return this.item as TrainDeparture
  }

  private get itemAsAirportDeparture(): AirportDeparture {
    return this.item as AirportDeparture
  }
}

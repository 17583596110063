import { Flavour } from '@/enums'
import {
  GetJourneyResponse,
  Journey,
  ShareMyTrainResponse,
} from '@gomedia-apis-ts-pis/v1'
import {
  IData,
  IConfig,
  IPISContent,
  IDestinationInfoItem,
  IState,
  IShareMyTrainPisConstants,
} from '@/constants/interfaces'
import { getNextStationInfo, getDestinationInfo } from '@/helpers/destination'
import {
  getSpeed,
  getLatitude,
  getLongitude,
  getCopyJourneyResponse,
  getShareUrl,
  getFlavour,
  isInit,
  canRenderData,
  isRenderError,
  getTrainNumber,
} from '@/helpers/gettersLogic'
import { ITheme } from '@/themes/theme'

export default {
  isJourneyInit: (state: IState): boolean => isInit(state.journeyResponse),
  canJourneyRender: (state: IState): boolean =>
    canRenderData(state.journeyResponse),
  isJourneyError: (state: IState): boolean =>
    isRenderError(state.journeyResponse),
  isTrainPositionInit: (state: IState): boolean => isInit(state.trainPosition),
  canTrainPositionRender: (state: IState): boolean =>
    canRenderData(state.trainPosition),
  isTrainPositionError: (state: IState): boolean =>
    isRenderError(state.trainPosition),
  getJourney: (state: IState): Journey => state.journeyResponse.data.journey,
  getShareUrl: (state: IState): string => getShareUrl(state.shareUrl.data),
  getShareUrlData: (state: IState): IData<ShareMyTrainResponse> =>
    state.shareUrl,
  getConfig: (state: IState): IConfig => state.config,
  getContent: (state: IState): IPISContent => state.content,
  getLanguage: (state: IState): string => state.language,
  getCopyJourneyResponse: (state: IState): GetJourneyResponse =>
    getCopyJourneyResponse(state.journeyResponse),
  getWeatherStatus: (state: IState): string => state.weather.status,
  getWeather: (state: IState): any => state.weather.data,
  showSplittedList: (state: IState): boolean => state.showSplittedList,
  getRouter: (state: IState): any => state.router,
  getNextStop: (state: IState): IDestinationInfoItem =>
    getNextStationInfo(state.journeyResponse.data.journey),
  getDestination: (state: IState): IDestinationInfoItem =>
    getDestinationInfo(state.journeyResponse.data.journey),
  getLatitude: (state: IState): number => getLatitude(state.trainPosition.data),
  getLongitude: (state: IState): number =>
    getLongitude(state.trainPosition.data),
  getSpeed: (state: IState): number => getSpeed(state.trainPosition.data),
  getTheme: (state: IState): ITheme => state.theme,
  getFlavour: (state: IState): Flavour =>
    state.preferredFlavour || getFlavour(state.journeyResponse.data),
  trainNumber: (state: IState): string =>
    getTrainNumber(state.journeyResponse.data.journey),
  journeyName: (state: IState): string =>
    state.journeyResponse.data.journey?.name || '',
}

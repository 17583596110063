export const mergeDeep = (target, source) => {
  const finalObject = { ...target }
  const sourceCloned = { ...source }

  for (const key of Object.keys(source)) {
    if (sourceCloned[key] instanceof Object) {
      sourceCloned[key] = {
        ...sourceCloned[key],
        ...mergeDeep(finalObject[key], sourceCloned[key]),
      }
    }
  }

  return {
    ...finalObject,
    ...sourceCloned,
  }
}















import * as tsx from 'vue-tsx-support'
import { Component } from 'vue-property-decorator'
import debounce from 'lodash/debounce'

import ShareIcon from '@/components/svgs/subheader/shareIcon/index.vue'

import { store } from '@/store'

interface ISubHeaderEvents {
  onShare: () => void
}

@Component({ components: { ShareIcon } })
export default class SubHeader extends tsx.Component<{}, ISubHeaderEvents> {
  public share
  public shareAnimation = false

  public get shareLabel(): string {
    return store.getters.getContent.UPDATED_BAR.SHARE
  }

  public get shareButtonTitle(): string {
    return store.getters.getContent.UPDATED_BAR.SHARE_BUTTON_TITLE
  }

  public get shareIconClasses() {
    return {
      'share-icon-svg': true,
      'share-icon-svg_animation': this.shareAnimation,
    }
  }

  private notify(): void {
    this.shareAnimation = true
    setTimeout(() => {
      this.shareAnimation = false
    }, 250)
    this.$emit('share')
  }

  public debouncedNotify = debounce(this.notify, 200)

  private created() {
    this.share = (event: any): void => {
      event.target.blur()
      this.debouncedNotify()
    }
  }
}



















import { IPISContent, INetworkSignal } from '@/constants/interfaces'
import { store } from '@/store'
import { GetVehicleInfoResponse } from '@gomedia-apis-ts-pis/v1'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class NetworkSignal extends Vue {
  public inProgress = false
  public internetLevel = ''

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get networkSignal(): INetworkSignal {
    return this.inProgress
      ? this.content.NETWORK_SIGNAL[0]
      : this.content.NETWORK_SIGNAL[this.internetLevel]
  }

  public async requestInternetLevelInfo(): Promise<void> {
    this.inProgress = true
    try {
      const response: GetVehicleInfoResponse = await store.dispatch(
        'fetchVehicleInfo',
      )
      this.internetLevel = response.internet_level
    } catch (e) {
      this.internetLevel = '-1'
    } finally {
      this.inProgress = false
    }
  }

  public created(): void {
    this.requestInternetLevelInfo()
  }
}

























import FeedbackButton from '@/components/feedbackButton/index.vue'
import BackToTheAppButton from '@/components/backToTheAppButton/index.vue'
import Nre from '@/components/svgs/footer/nre/index.vue'
import LwfNre from '@/components/svgs/footer/lwfNre/index.vue'
import Rdg from '@/components/svgs/footer/rdg/index.vue'
import LwfRdg from '@/components/svgs/footer/lwfRdg/index.vue'
import { VueConstructor } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { Flavour } from '@/enums'

interface IFooterProps {
  showFeedbackHref: boolean
  showAttribution: boolean
  showBackToAppButton: boolean
  analytics: any
  flavour: Flavour
}

export interface IFooterEvents {
  onBackToApp?: void
}

const FLAVOUR_IMAGES_DICTIONARY: { [key: string]: VueConstructor<Vue>[] } = {
  [Flavour.TPE]: [LwfNre, LwfRdg],
  [Flavour.ECTL]: [LwfNre, LwfRdg],
  DEFAULT: [Nre, Rdg],
}

@Component({ components: { BackToTheAppButton, FeedbackButton } })
export default class FooterBar extends tsx.Component<
  IFooterProps,
  IFooterEvents
> {
  @Prop() public showFeedbackHref: boolean
  @Prop() public showAttribution: boolean
  @Prop() public showBackToAppButton: boolean
  @Prop() public flavour: Flavour
  @Prop() public analytics: any

  public get attributionImages(): VueConstructor<Vue>[] {
    return (
      FLAVOUR_IMAGES_DICTIONARY[this.flavour] ||
      FLAVOUR_IMAGES_DICTIONARY.DEFAULT
    )
  }

  public get footerContainerClasses() {
    return {
      'footer-pis': true,
      'footer-pis_smt':
        process.env.NODE_ENV === 'development' ||
        process.env.VUE_APP_SHAREMYTRAIN === 'true',
    }
  }
}

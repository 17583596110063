









import { IPISContent, ISbbTrafimageMapOptions } from '@/constants/interfaces'
import { store } from '@/store'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class SbbTrafimageMap extends tsx.Component<{
  options: ISbbTrafimageMapOptions
}> {
  @Prop() options: ISbbTrafimageMapOptions

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get title() {
    return this.content.MAP?.IFRAME_TITLE
  }
}










import { Flavour } from '@/enums'
import { Language, LanguageName } from '@/enums/language'
import { languageByFlavour } from '@/enums/languageByFlavour'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component({
  model: {
    prop: 'currentLanguage',
    event: 'change-language',
  },
})
export default class ShareMyTrainLogo extends tsx.Component<{}> {
  @Prop({ required: true }) flavour: Flavour
  @Prop({ default: Language.EN }) currentLanguage: Language

  get languageName(): string {
    return LanguageName[this.nextLang]
  }

  get nextLang(): Language {
    const languageList = languageByFlavour[this.flavour]
    const index = languageList.findIndex(
      (lang) => lang === this.currentLanguage,
    )
    if (index === -1) {
      return Language.EN
    }
    return languageList[index + 1] || languageList[0]
  }

  changeLanguage(): void {
    this.$emit('change-language', this.nextLang)
  }
}

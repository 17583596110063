import { IPISContent } from '@/constants/interfaces'
import NetworkSignal from '@/components/networkSignal/index.vue'
import { store } from '@/store'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

interface IComponentProps {
  content: IPISContent
}

@Component
export default class PisNetworkSignal extends tsx.Component<IComponentProps> {
  @Prop({ required: true }) readonly content: IPISContent

  @Watch('content') public contentChanged(newVal, oldVal) {
    store.dispatch('changeContent', newVal || oldVal)
  }

  public mounted(): void {
    if (this.content) {
      store.dispatch('changeContent', this.content)
    }
  }

  public render(h) {
    return <NetworkSignal />
  }
}

import { ITheme } from './theme'

const pisUpdatedBarHeight = '4rem'
const pisHeaderHeight = '5.8rem'

export const SBB_THEME: ITheme = {
  pisUpdatedBarHeight,
  pisHeaderHeight,
  feedbackForm: {
    title: {
      color: 'black',
    },
    textArea: {
      color: 'black',
      focusedBorderColor: '#eb0000',
    },
    button: {
      backgroundColor: '#eb0000',
      disabledBackgroundColor: '#cccccc',
      borderColor: '#eb0000',
      disabledBorderColor: '#cccccc',
    },
  },
  departureItem: {
    grid: {
      columnGap: '1rem',
    },
    name: {
      fontWeight: 'normal',
    },
  },
  pisJourneyListBg: 'white',
  pisContainerBg: 'white',
  pisBorderC: '#e0e0e0',
  pisEmptyListC: '#1e234f',
  pisJourneyListStationFontWeight: 'normal',
  pisJourneyListItemTimeFontWeight: 'normal',
  pisJourneyListLineWidth: '0.2rem',
  pisJourneyListPointWidth: '0.8rem',
  pisJourneyListPointHeight: '0.8rem',
  pisJourneyListPointBg: 'black',
  pisJourneyListPointDisabledBg: '#cccccc',
  pisJourneyListPointFirstLastSize: '1.1rem',
  pisJourneyListPointCurrentUpcomingTop: '-25%',
  pisJourneyListPointLastCurrentUpcomingTop: '-25%',
  pisJourneyListPointFirstLastBg: 'black',
  pisTrainW: '2.8rem',
  pisTrainBottom: '1.9rem',
  pisTrainLeft: '0.6rem',
  pisPrimary: 'black',
  pisCancelledC: '#eb0000',
  pisDisabledCancelledC: '#eb0000',
  pisArrowStrokeWidth: '1',
  pisArrowC: 'black',
  pisDeparturesHeaderC: 'black',
  pisJourneyListStationC: 'black',
  pisDisabledPrimary: 'black',
  pisDisabledDelayC: '#f48120',
  pisDelayC: '#f48120',
  pisStationInfoLabelFontWeight: 'normal',
  pisTrain: {
    color: '#eb0000',
    circleC: '#eb0000',
    circleBg: '#eb0000',
    widthHeight: '2.8rem',
  },
  pisUpdatedBarBg: 'white',
  pisUpdatedBarBorderBottomC: 'white',
  pisEmptyListFontWeight: 400,
  pisJourneyListTimeFontWeight: 'normal',
  pisJourneyListLineHeigth: '3rem',
  pisJourneyListPointCurrentUpcommingTop: '-25%',
  pisJourneyListPointLastCurrentUpcommingTop: '-25%',
  pisJourneyListPointFirstLastWidth: '1.1rem',
}

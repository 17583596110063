
































import Share from '@/components/share/index.vue'
import Subheader from '@/components/subheader/index.vue'

import {
  IGoViewNotification,
  ILoadOption,
  IPISContent,
} from '@/constants/interfaces'
import { MODAL } from '@/enums'
import { store } from '@/store'
import { Journey } from '@gomedia-apis-ts-pis/v1'
import { VueConstructor } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Spinner from 'vue-simple-spinner'
import * as tsx from 'vue-tsx-support'

export interface IDataLoaderEvents {
  onOpenPointDetails?: number
  onShowNotification?: IGoViewNotification
}

interface IDataLoaderProps {
  options: ILoadOption
  component: VueConstructor<Vue>
  hideLoadingLabel?: boolean
}

@Component({ components: { Spinner, Share, Subheader } })
export default class DataLoader extends tsx.Component<
  IDataLoaderProps,
  IDataLoaderEvents
> {
  @Prop() public options: ILoadOption
  @Prop() public component: VueConstructor<Vue>
  @Prop() public hideLoadingLabel: boolean

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get actionName(): string {
    return this.options.actionName
  }

  public get hasPollingAction(): boolean {
    return Boolean(this.actionName)
  }

  public get isNextStationInfoBarEnabled() {
    try {
      return this.options.mapConfig.NEXT_STATION_INFO_BAR
    } catch (error) {
      return false
    }
  }

  public get isInit(): boolean {
    return this.isFetchTrainPosition
      ? store.getters.isTrainPositionInit
      : store.getters.isJourneyInit
  }

  public get canRenderData(): boolean {
    if (!this.hasPollingAction) {
      return true
    }
    return this.isFetchTrainPosition
      ? store.getters.canTrainPositionRender
      : store.getters.canJourneyRender
  }

  public get isRenderError(): boolean {
    return this.isFetchTrainPosition
      ? store.getters.isTrainPositionError
      : store.getters.isJourneyError
  }

  public get list(): Journey {
    return store.getters.getJourney
  }

  public get spinnerMessage() {
    return !this.hideLoadingLabel ? this.content.FEEDBACK.LOADING : undefined
  }

  public get serverUnavailableLabel(): string {
    return this.content.DATA_LOADER.SERVER_UNAVAILABLE
  }

  private get isFetchTrainPosition() {
    return this.actionName === 'fetchTrainPosition'
  }

  private get isFetchJourney() {
    return this.actionName === 'fetchJourney'
  }

  private showModal() {
    this.$modal.show(MODAL.SHARE)
  }

  private mounted(): void {
    if (this.hasPollingAction) {
      store.dispatch('pollingStart', {
        action: this.actionName,
        requestTimeout: this.options.requestTimeout,
        token: this.options.token,
      })
    }
    if (this.isNextStationInfoBarEnabled && this.isFetchTrainPosition) {
      store.dispatch('pollingStart', {
        action: 'fetchJourney',
        requestTimeout: 10000,
      })
    }
  }

  private beforeDestroy(): void {
    if (this.hasPollingAction) {
      store.dispatch('pollingStop', this.actionName)
    }
    if (this.isNextStationInfoBarEnabled && this.isFetchTrainPosition) {
      store.dispatch('pollingStop', 'fetchJourney')
    }
  }
}































































import NoJourneyAvailable from '@/components/noJourneyAvailable/index.vue'
import SBBInfo from '@/components/svgs/destinationInfo/SBBInfo/index.vue'
import ShareIcon from '@/components/svgs/subheader/shareIcon/index.vue'
import WeatherLoader from '@/components/weatherLoader'
import PisLink from '@/components/pisLink/index.vue'
import EcoCalculator from './ecoCalculator/index.vue'
import {
  IDestinationInfoItem,
  IDestinationOption,
} from '@/constants/interfaces'
import { MODAL } from '@/enums'
import { formatTimeToAmPm } from '@/helpers/destination'
import { isSbb } from '@/helpers/flavours'
import { isNoJourneyAvailable } from '@/helpers/noJourneyAvailable'
import { store } from '@/store'
import { Journey } from '@gomedia-apis-ts-pis/v1'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

interface IDestinationInfoProps {
  data: Journey
  options: IDestinationOption
}

interface IDestinationItem {
  title: string
  stationName: string
  arrival: string
  weatherTiploc: string
}

@Component({
  components: {
    NoJourneyAvailable,
    SBBInfo,
    ShareIcon,
    WeatherLoader,
    PisLink,
    EcoCalculator,
  },
})
export default class DestinationInfo extends tsx.Component<IDestinationInfoProps> {
  @Prop() public data: Journey
  @Prop() public options: IDestinationOption

  public get isSbb(): boolean {
    return isSbb(this.options.flavour)
  }

  public get journeyIcon(): string {
    return this.options.content.DESTINATION.BUTTONS.INFO.ICON
  }

  public get shareIcon(): string {
    return this.options.content.DESTINATION.BUTTONS.SHARE.ICON
  }

  public get journeyIconWidth(): number {
    return this.options.content.DESTINATION.BUTTONS.INFO.WIDTH
  }

  public get shareIconWidth(): number {
    return this.options.content.DESTINATION.BUTTONS.SHARE.WIDTH
  }

  public get showButtons(): boolean {
    return this.options.showButtons
  }

  public get nextStop(): IDestinationInfoItem {
    return store.getters.getNextStop
  }

  public get destination(): IDestinationInfoItem {
    return store.getters.getDestination
  }

  public get isTrainNumberExists(): boolean {
    return Boolean(store.getters.trainNumber)
  }

  public get trainInfo(): string {
    return `${store.getters.trainNumber} ${this.options.content.DESTINATION.TRAIN_NAME}`
  }

  public get nextStopTime(): string {
    return formatTimeToAmPm(store.getters.getNextStop.time, this.options.amPm)
  }

  public get destinationTime(): string {
    return formatTimeToAmPm(this.destination.time, this.options.amPm)
  }

  public get isEmpty(): boolean {
    return isNoJourneyAvailable(this.data && this.data.calling_points)
  }

  public get destinations(): IDestinationItem[] {
    return [
      {
        title: this.options.content.DESTINATION.NEXT_STOP,
        stationName: this.nextStop.name,
        arrival: this.nextStopTime,
        weatherTiploc: this.nextStop.tiploc,
      },
      {
        title: this.options.content.DESTINATION.DESTINATION_TITLE,
        stationName: this.destination.name,
        arrival: this.destinationTime,
        weatherTiploc: this.destination.tiploc,
      },
    ]
  }

  public showInfo() {
    store.dispatch('pushRouter', { link: '/pis' })
  }

  public showShare() {
    this.$modal.show(MODAL.SHARE)
  }
}



































import { Flavour } from '@/enums'
import { Language } from '@/enums/language'
import { getFlavour } from '@/helpers/gettersLogic'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import Spinner from 'vue-simple-spinner'

import {
  JourneySplitterEnum,
  IJourneyOption,
  IShareMyTrainPisConfig,
  IPISContent,
} from '@/constants/interfaces'
import {
  getLanguageQuery,
  getShareMyTrainToken,
  removeAllQueries,
} from '@/helpers/locationHelpers'
import { store } from '@/store'
import { Journey } from '@gomedia-apis-ts-pis/v1'
import ShareMyTrainLogo from '@/components/shareMyTrainLogo/index.vue'
import JourneyList from '@/components/journeyList/index.vue'
import FooterBar from '@/components/footerBar/index.vue'
import LanguageSwitcher from '@/components/languageSwitcher/index.vue'

const POLLING_TIMEOUT = 10000

@Component({
  components: {
    ShareMyTrainLogo,
    JourneyList,
    Spinner,
    FooterBar,
    LanguageSwitcher,
  },
})
export default class ShareMyTrainApp extends tsx.Component<{}> {
  currentLanguage = Language.EN
  config: IShareMyTrainPisConfig = {}
  isLoading = false
  isError = false

  public get token(): string {
    return getShareMyTrainToken()
  }

  public get isToken(): boolean {
    return this.token && this.token.length > 0
  }

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get journey(): Journey {
    return store.getters.getJourney
  }

  public get flavour(): Flavour {
    return store.getters.getFlavour
  }

  public get showFooter(): boolean {
    return this.config.SHOW_FOOTER
  }

  public get showLanguageSwitcher(): boolean {
    return this.config.LANGUAGE_SWITCHER
  }

  public get packageVersion(): string {
    return store.state.packageVersion
  }

  public get options(): IJourneyOption {
    return {
      showArrow: false,
      showPlatforms: !this.config.PLATFORMS_DISABLED,
      hideAlerts: true,
      skipStationInformation: true,
      amPm: this.config.AM_PM_TIME,
      flavour: this.flavour,
      setOrigin: () =>
        store.dispatch('toggleSplitter', JourneySplitterEnum.Origin),
      setSplit: () =>
        store.dispatch('toggleSplitter', JourneySplitterEnum.Split),
      showSplittedList: store.getters.showSplittedList,
    }
  }

  changeLanguage(lang: string): void {
    store.dispatch('changeLanguage', lang)
    store.dispatch('requestSMTContent', lang)
  }

  startPollingAction() {
    store.dispatch('pollingStart', {
      action: 'fetchJourneyByToken',
      token: this.token,
      requestTimeout: POLLING_TIMEOUT,
    })
  }

  async init() {
    this.isLoading = true
    try {
      const journey = await store.dispatch('fetchJourneyByToken', this.token)
      const flavour = getFlavour(journey)
      store.dispatch('setThemeByFlavour', { flavour, element: document.body })
      const [config] = await Promise.all([
        store.dispatch('getSMTConfig', flavour),
        store.dispatch('requestTypography', flavour),
        store.dispatch('requestSMTContent', this.currentLanguage),
      ])
      this.config = config

      setTimeout(this.startPollingAction, POLLING_TIMEOUT)
    } catch (e) {
      this.isError = true
    } finally {
      this.isLoading = false
    }
  }

  created(): void {
    this.currentLanguage = getLanguageQuery() || store.getters.getLanguage
    store.dispatch('changeLanguage', this.currentLanguage)
    removeAllQueries()
    if (!this.isToken) {
      this.isError = true
      return
    }

    this.init()
  }

  beforeDestroy(): void {
    if (this.token) {
      store.dispatch('pollingStop', this.options.actionName)
    }
  }
}
































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Train extends Vue {}

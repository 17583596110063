











import { store } from '@/store'
import * as tsx from 'vue-tsx-support'
import Component from 'vue-class-component'

@Component
export default class NextStationInfoBar extends tsx.Component<{}> {
  get nextStopName(): string {
    return store.getters.getNextStop.name || '-'
  }

  get nextStopArriveIn(): string {
    return store.getters.getNextStop.arriveIn || '-'
  }
}

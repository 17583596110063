







































import BicycleSpace from '@/components/svgs/coachItem/bicycleSpace/index.vue'
import BusinessZone from '@/components/svgs/coachItem/businessZone/index.vue'
import Coach from '@/components/svgs/coachItem/coach'
import CoachHead from '@/components/svgs/coachItem/coachHead'
import CoachTail from '@/components/svgs/coachItem/coachTail'
import Emergency from '@/components/svgs/coachItem/emergency/index.vue'
import Family from '@/components/svgs/coachItem/family/index.vue'
import FirstClass from '@/components/svgs/coachItem/firstClass/index.vue'
import Restaurant from '@/components/svgs/coachItem/restaurant/index.vue'
import SecondClass from '@/components/svgs/coachItem/secondClass/index.vue'
import WheelchairSpace from '@/components/svgs/coachItem/wheelchairSpace/index.vue'
import WheelchairToilet from '@/components/svgs/coachItem/wheelchairToilet/index.vue'
import Arrow from '@/components/svgs/common/arrow/index.vue'
import { IPISContent } from '@/constants/interfaces'
import { hasSeats } from '@/helpers/coachListHelpers'
import { isCpa, isSbb, isSpa } from '@/helpers/flavours'

import { store } from '@/store'
import { VehicleUnit } from '@gomedia-apis-ts-pis/v1'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

interface ICoachItemProps {
  coach: any
  showArrow?: boolean
  isFirst: boolean
  isMiddle: boolean
  isLast: boolean
  flavour: string
}

@Component({
  components: {
    BicycleSpace,
    BusinessZone,
    Coach,
    CoachHead,
    CoachTail,
    Emergency,
    Family,
    FirstClass,
    Restaurant,
    SecondClass,
    WheelchairSpace,
    WheelchairToilet,
    Arrow,
  },
})
export default class CoachItem extends tsx.Component<ICoachItemProps> {
  @Prop() public coach: VehicleUnit
  @Prop({ default: false }) public showArrow: boolean // if true -> show arrow to navigate to the another template
  @Prop() public isFirst: boolean
  @Prop() public isMiddle: boolean
  @Prop() public isLast: boolean
  @Prop() public flavour: string

  public get content(): IPISContent {
    return store.getters.getContent
  }

  public get position(): string {
    return `${this.content.TRAIN_INFO.COACH} ${this.coach.position || ''}`
  }

  public get isFirstClass(): boolean {
    return hasSeats(this.coach.seats_first_class)
  }

  public get isSecondClass(): boolean {
    return hasSeats(this.coach.seats_second_class)
  }

  public get isSbb(): boolean {
    return isSbb(this.flavour)
  }

  public get isSpa(): boolean {
    return isSpa(this.flavour)
  }

  public get isCpa(): boolean {
    return isCpa(this.flavour)
  }
}
